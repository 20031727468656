<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container"
  [attr.data-cy-specific-window-name]="name">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'" />
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="p-col-12">
      <label [hidden]="title && title.length === 0" class="specific-title">
        <strong>{{ title }}</strong>
      </label>
      <div class="fields p-grid">
        <div class="p-col-4 p-md-4 p-lg-4">
          <ng-template iuDynamicContainer></ng-template>
        </div>
        <div class="p-col-8 p-md-8 p-lg-8 p-grid">
          <div class="p-col-12 p-md-12 p-lg-12 p-grid">
            <ng-container *ngIf="selectedLocation$ | async as selectedLocation">
              <div class="p-col-6 p-md-6 p-lg-6 card-container">
                <ng-container *ngFor="let oldColumn of oldColumns">
                  <div
                    class="card"
                    draggable="true"
                    (dragstart)="onDragStart($event, oldColumn)"
                    (dragend)="onDragEnd($event)">
                    <iu-input-text-ui
                      [isStandalone]="true"
                      [isReadOnly]="true"
                      [fieldValue]="selectedLocation?.[oldColumn.columnName]"
                      [label]="oldColumn.label | translate" />
                  </div>
                </ng-container>
              </div>
              <div class="p-col-6 p-md-6 p-lg-6 card-container">
                <ng-container *ngFor="let newColumn of newColumns">
                  <div class="card" (drop)="onDrop($event, newColumn)">
                    <iu-input-text-ui
                      [isStandalone]="true"
                      [isReadOnly]="false"
                      [fieldValue]="selectedLocation?.[newColumn.columnName]"
                      (fieldValueChange)="updateNLLocation(newColumn, $event)"
                      [label]="newColumn.label | translate" />
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div class="p-col-12 p-md-12 p-lg-12 p-grid" style="justify-content: end">
            <iu-button-ui
              class="p-col-12 p-md-3 p-lg-2"
              (click)="saveNLLocation($event)"
              label="{{ 'generic.save' | translate }}"
              data-cy="location-migration-save" />
          </div>
        </div>
      </div>
    </div>
  </p-scrollPanel>
</div>
