import { NgClass, NgIf, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PoService } from '@compiere-ws/services/po/po.service';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsDataField } from '@iupics-manager/models/iupics-data';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { isNil } from 'lodash';
import { TooltipModule } from 'primeng/tooltip';
import Quill from 'quill';

@Component({
  selector: 'iu-input-text-html-ui',
  templateUrl: './input-text-html-ui.component.html',
  styleUrls: ['./input-text-html-ui.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [TooltipModule, NgIf, NgClass, NgStyle, FormsModule, TextLimitPipe],
})
export default class InputTextHtmlUiComponent
  extends AbstractDataContainer
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('quillHeader', { read: ElementRef, static: true })
  header: ElementRef<HTMLDivElement>;
  @ViewChild('quillEditor', { read: ElementRef, static: true })
  editor: ElementRef<HTMLDivElement>;
  @Input() data: IupicsDataField;

  @Input()
  columnName: string;

  quill: Quill;

  displayMobileMenu = false;
  isMobile = false;

  dataContainers: AbstractDataContainerCallout;

  private __fieldValue: any;
  set fieldValue(value: any) {
    this.__fieldValue = value;
    this.updateQuillContent();
  }

  get fieldValue(): any {
    return this.__fieldValue;
  }

  private blurListener: () => void = () => {};

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public uiCreatorService: UICreatorService,
    public cmService: ContextMenuService,
    public renderer: Renderer2,
    protected po: PoService,
    protected cacheService: CacheManagerService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po, cacheService);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }

    let Font = Quill.import('formats/font');
    Font.whitelist = ['serif', 'monospace', 'calibri', 'times-new-roman', 'arial'];
    Quill.register(Font, true);

    this.quill = new Quill(this.editor.nativeElement, {
      modules: {
        toolbar: this.header.nativeElement,
      },
      theme: 'snow',
    });
    this.updateQuillContent();

    this.blurListener = this.renderer.listen(this.quill.root, 'blur', (e: FocusEvent) => {
      if (
        isNil(e.relatedTarget) ||
        (!(e.relatedTarget as HTMLElement)?.hasAttribute('data-quill-action') &&
          !(e.relatedTarget as HTMLElement)?.classList.contains('ql-picker-label'))
      ) {
        const newContent = this.quill.root.innerHTML;
        if (newContent !== this.fieldValue) {
          this.dataChange(newContent);
        }
      }
    });

    this.quill.update();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.blurListener();
  }

  updateQuillContent() {
    if (this.quill?.root) {
      this.quill.root.innerHTML = this.fieldValue || '';
    }
  }
}
