import { ComponentRef, Directive, EventEmitter, Input, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereDataFieldType, CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import ProcessUiComponent from '@iupics-components/specific/window/process-ui/process-ui.component';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { IupicsData, IupicsZoomInfo, IupicsZoomTarget } from '@iupics-manager/models/iupics-data';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { Subject, Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { DynamicComponent } from './dynamic-component';
import { OverridedCSS } from './overrided-css';
@Directive()
export abstract class AbstractDynamicComponent implements OnDestroy {
  @Input() data: IupicsData;
  protected _fieldValue: any;
  @Input()
  set fieldValue(value: any) {
    this._fieldValue = value;
  }

  get fieldValue() {
    return this._fieldValue;
  }
  @Input() gridPaginator: boolean;
  @Input() gridTabFilter: string[];
  @Input() gridTabValidator: string;
  @Input() cssClass: string;
  @Input() zoomInfo: IupicsZoomInfo;
  @Input() parentStore: any;
  @Input() zoomTarget: IupicsZoomTarget;
  zoomTargetData: IupicsData[];
  initRequest?: CompiereDataGridRequestJSON;
  cssWidth: string;
  additionalInfoWidth = '2em';
  additionalInfoWidthExpanded: number;
  componentEmitter: EventEmitter<any>;
  updateDataEmitter: EventEmitter<any>;
  subscriptions: Subscription[] = [];
  isReadOnly: boolean;
  isDeleteable: boolean;
  isInsertRecord: boolean;
  // enfants qui doivent encore être construit
  children: DynamicComponent[] = [];
  @Input() tabId: number;
  @Input() container: any;
  @Input() DOMParentComponent: any;
  DOMChildrenComponent: AbstractDynamicComponent[];
  DOMComponent: ComponentRef<any>;

  notifierLinkedComponent: Subject<any>;

  @ViewChild(DynamicContainerDirective, { read: ViewContainerRef, static: true }) vcr: ViewContainerRef;

  parentTab: EditTabUiComponent;
  parentProcess: ProcessUiComponent;
  windowType: IupicsMenuType;
  fieldType: CompiereDataFieldType = CompiereDataFieldType.FIELD;
  uuid = uuid();
  @Input() overridedCSS: OverridedCSS;
  @Input() isInUniversalFilter = false;

  protected componentRefs: ComponentRef<any>[] = [];

  constructor() {
    this.notifierLinkedComponent = new Subject<any>();
    this.componentEmitter = new EventEmitter<any>();
    this.updateDataEmitter = new EventEmitter<any>();
    this.DOMChildrenComponent = [];
  }

  abstract onChildUpdate(event: IupicsEvent): void;
  abstract onSiblingUpdate(event: IupicsEvent): void;
  abstract onRemoveComponent(event: IupicsEvent): void;

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub?.unsubscribe());
    this.componentRefs.forEach((cr) => cr?.destroy());
  }
  /**
   * @description change url to fit the state of the screen
   */
  notifyUrlChange(Data_UUID?: string) {}

  deleteLinkedEditView() {}

  addSubscribeOnLinkedComponent(element, componentRef) {
    this.subscriptions.push(
      (<AbstractDynamicComponent>componentRef.instance).notifierLinkedComponent.subscribe((event) => {
        element.onSiblingUpdate(event);
      }),
      element.notifierLinkedComponent.subscribe((event) => {
        (<AbstractDynamicComponent>componentRef.instance).onSiblingUpdate(event);
      })
    );
  }
  getWindowContext() {
    return {};
  }
}
