import { registerLocaleData } from '@angular/common';
import localeEnglish from '@angular/common/locales/en';
import localeFrench from '@angular/common/locales/fr';
import localeDutch from '@angular/common/locales/nl';
import { Injectable, inject } from '@angular/core';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { CompiereLoginService } from '@compiere-ws/services/compiere-login/compiere-login.service';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { AvatarService } from '@iupics-components/standard/user/avatar-ui/avatar-service/avatar.service';
import { AppConfig } from '@iupics-config/app.config';
import { RoleUI, UserAccount } from '@iupics-manager/models/user-account';
import { UserPreference } from '@web-desktop/models/user-preference';
import { environment } from 'environments/environment';
import jwt_decode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import * as moment from 'moment';
import { Observable, Subject, from, switchMap, zip } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class SecurityManagerService {
  #loginService = inject(CompiereLoginService);
  #avatarService = inject(AvatarService);
  #appConfig = inject(AppConfig);
  protected readonly keycloak = inject(KeycloakService);

  private iupicsDefaultLanguage: CompiereLanguage;
  private iupicsUserAccount: UserAccount;
  private iupicsUserContext: any;

  getAccessToken(): Observable<string> {
    return from(this.keycloak.getToken());
  }

  initContext(profile: KeycloakProfile): Observable<UserAccount> {
    return this.getAccessToken().pipe(
      switchMap((access_token) => {
        const decodedToken = jwt_decode(access_token);

        if (!this.iupicsDefaultLanguage) {
          this.iupicsDefaultLanguage = environment.default_language;
          if (decodedToken['session']?.['webSession']) {
            this.iupicsDefaultLanguage = decodedToken['session']['webSession'];
          }
        }
        return new Observable<UserAccount>((observer) => {
          return from(this.#appConfig.loadConfig(access_token)).subscribe((res) => {
            this.#loginService.loadUrl();
            this.#loginService.getCtx().subscribe({
              next: (ctxJSON) => {
                this.#loginService.getLoginInfo(access_token).subscribe((loginInfo) => {
                  const currentAD_Org_ID = ctxJSON['#AD_Org_ID'];
                  if (loginInfo['org_id'] !== null && loginInfo['org_id'] !== undefined) {
                    ctxJSON['#AD_Org_ID'] = parseInt(loginInfo['org_id'], 10);
                  }
                  this.iupicsUserContext = ctxJSON;
                  // date courante local setté
                  this.setIupicsUserContextDate(moment().toDate());
                  if (currentAD_Org_ID !== ctxJSON['#AD_Org_ID']) {
                    this.updateRemoteCtx();
                  }
                  this.iupicsUserAccount = {
                    id: parseInt(loginInfo.user_id, 0),
                    fullname: profile.firstName + ' ' + profile.lastName,
                    login: profile.username,
                    session_id: parseInt(loginInfo.session_id, 0),
                    email: profile.email,
                    roles: loginInfo.roles,
                    avatarInfos: this.#avatarService.getAvatarInfos({
                      id: parseInt(loginInfo.user_id, 0),
                      fullname: loginInfo.name,
                    }),
                    default_language: this.iupicsDefaultLanguage,
                    current_role: loginInfo.roles[0],
                    ad_client: loginInfo.ad_client_id,
                  };
                  this.iupicsUserAccount = Object.assign(this.iupicsUserAccount, loginInfo);

                  this.iupicsUserAccount.roles.forEach((role) => {
                    if (parseInt(ctxJSON['#AD_Role_ID'], 0) === role.role_id) {
                      role.isSelected = true;
                      this.iupicsUserAccount.current_role = role;
                    } else {
                      role.isSelected = false;
                    }
                  });
                  observer.next(this.iupicsUserAccount);
                  observer.complete();
                  return { unsubscribe() {} };
                });
              },
              error: (err) => {
                observer.error(err?.error?.message ?? err?.message ?? err);
                observer.complete();
                return { unsubscribe() {} };
              },
            });
          });
        });
      })
    );
  }

  changeRole(newRole: RoleUI): Observable<UserAccount> {
    this.iupicsUserContext['#AD_Role_ID'] = newRole.role_id;
    return this.#loginService.updateCtx(this.iupicsUserContext).pipe(
      map((ctx) => {
        this.iupicsUserContext = ctx;
        this.iupicsUserAccount.current_role = newRole;
        return this.iupicsUserAccount;
      })
    );
  }

  changeLanguage(newLanguage: CompiereLanguage): Observable<UserAccount> {
    return this.#loginService.changeLanguage(newLanguage).pipe(
      map((status) => {
        this.iupicsUserAccount.default_language = newLanguage;
        return this.iupicsUserAccount;
      })
    );
  }

  getCtx(): Observable<any> {
    return zip(
      this.#loginService.getCtx(),
      this.getAccessToken().pipe(switchMap((access_token) => this.#loginService.getLoginInfo(access_token)))
    ).pipe(
      map(([ctxJson, loginInfo]) => {
        const currentAD_Org_ID = ctxJson['#AD_Org_ID'];
        if (loginInfo['org_id'] !== null && loginInfo['org_id'] !== undefined) {
          ctxJson['#AD_Org_ID'] = parseInt(loginInfo['org_id'], 10);
        }
        this.iupicsUserContext = ctxJson;
        if (currentAD_Org_ID !== ctxJson['#AD_Org_ID']) {
          this.updateRemoteCtx();
        }
        this.iupicsUserAccount.roles = loginInfo.roles;
        this.iupicsUserAccount.roles.forEach((role) => {
          if (parseInt(ctxJson['#AD_Role_ID'], 0) === role.role_id) {
            role.isSelected = true;
            this.iupicsUserAccount.current_role = role;
          } else {
            role.isSelected = false;
          }
        });

        return this.iupicsUserAccount;
      })
    );
  }

  getAllUsers(): Observable<any> {
    return this.#loginService.getAllUsers();
  }

  getIupicsUserAccount(): UserAccount {
    return this.iupicsUserAccount;
  }

  getIupicsUserContext(): any {
    return this.iupicsUserContext;
  }

  getIupicsDefaultLanguage(): CompiereLanguage {
    return this.iupicsDefaultLanguage;
  }

  setIupicsDefaultLanguage(language: CompiereLanguage) {
    this.iupicsDefaultLanguage = language;
    switch (language.iso_code.replace('_', '-')) {
      case 'fr-FR': {
        registerLocaleData(localeFrench);
        break;
      }
      case 'nl-NL': {
        registerLocaleData(localeDutch);
        break;
      }
      case 'en-US': {
        registerLocaleData(localeEnglish);
        break;
      }
      default: {
        registerLocaleData(localeFrench);
        break;
      }
    }
  }

  setIupicsUserContextDate(date: Date) {
    this.iupicsUserContext['#Date'] = date.valueOf();
  }
  setIupicsUserContext(ctx: any) {
    this.iupicsUserContext = ctx;
  }

  updateRemoteCtx(): Observable<any> {
    const obs = new Subject();
    this.#loginService.updateCtx(this.iupicsUserContext).subscribe((ctx) => {
      this.iupicsUserContext = ctx;
      obs.next(this.iupicsUserContext);
      obs.complete();
    });

    return obs.asObservable();
  }

  handleError() {}

  getPref(): Observable<any> {
    return this.#loginService.getPref();
  }

  savePref(userPref: UserPreference): Observable<any> {
    return this.#loginService.savePref(userPref);
  }

  resetCache(): Observable<any> {
    return this.#loginService.resetCache();
  }

  getConfig(): AppConfig {
    return this.#appConfig;
  }

  getOrganisations(): Observable<IAutocomplete[]> {
    return this.#loginService.getOrganisations();
  }

  getWarehouses(): Observable<IAutocomplete[]> {
    return this.#loginService.getWarehouses();
  }
}
