<div
  #mainDiv
  id="chips"
  [ngClass]="['p-chips ui-widget', styleClass || '']"
  [ngStyle]="style"
  (click)="onWrapperClick()">
  <ul
    #wrapper
    data-cy="chips-list"
    [ngClass]="[
      'p-inputtext ui-state-default ui-corner-all',
      focused ? 'ui-state-focus' : '',
      disabled ? 'p-disabled' : ''
    ]"
    [ngStyle]="
      this.shouldBeSmall && this.focused && inputtext.value.trim() !== ''
        ? { display: 'none' }
        : {
            'max-width.px': maxWidthChipsContainer,
            display: 'flex',
            overflow: hasScroll ? 'hidden' : 'inherit',
            'flex-wrap': hasScroll ? 'nowrap' : 'wrap',
            gap: '5px'
          }
    "
    (wheel)="handleWheel($event)">
    <li
      #token
      *ngFor="let item of value; let i = index"
      class="p-chips-token p-highlight ui-corner-all"
      (click)="onItemClick($event, item)"
      data-cy="chip">
      <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
      <span *ngIf="!itemTemplate" class="p-chips-token-label">{{ field ? resolveFieldData(item, field) : item }}</span>
      <span *ngIf="!disabled" class="p-chips-token-icon pi pi-times" (click)="removeItem($event, i)"></span>
    </li>
    <li class="p-chips-input-token" style="max-width: 50%">
      <input
        #inputtext
        type="text"
        [ngStyle]="this.focused && (!this.shouldBeSmall || inputtext.value.trim() !== '') ? { width: '100%' } : {}"
        [attr.id]="inputId"
        [attr.placeholder]="value && value.length ? null : placeholder"
        [attr.tabindex]="tabindex"
        (keydown)="onKeydown($event)"
        (input)="onInput()"
        (paste)="onPaste($event)"
        [attr.aria-labelledby]="ariaLabelledBy"
        (focus)="!disabled ? onInputFocus($event) : null"
        [tabindex]="disabled ? '-1' : null"
        (blur)="onInputBlur($event)"
        [readonly]="disabled || preventInput"
        [ngStyle]="inputStyle"
        [class]="inputStyleClass"
        [ngClass]="['prime-chips-input', value?.length ? 'prime-chips-input-small' : '', inputStyleClass || '']"
        autocomplete="no"
        data-cy="input-chips"
        [attr.data-cy-columnName]="columnName" />
    </li>
  </ul>
</div>
