export type ModuleConfig = {
  enable?: 'Y' | 'N';
  [config: string]: any;
};

export const modulesConfig: { [moduleName: string]: ModuleConfig } = {};

export function isModuleEnable(moduleName: string): boolean {
  return modulesConfig[moduleName].enable === 'Y';
}
