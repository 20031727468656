<span
  [style.display]="displayCss"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipPosition="top"
  tooltipZIndex="2100"
  [showDelay]="500"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null">
  <label *ngIf="this.isLabelDisplay" [ngClass]="{ 'iu-field-only-label': data?.isFieldOnly }">{{
    label | textLimit : this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7
  }}</label>
  <input
    #input
    [(ngModel)]="fieldValue ? fieldValue.displayValue : fieldValue"
    [readonly]="isReadOnly ? 'disabled' : null"
    [ngClass]="[mandatoryCss, 'input-field p-inputtext']"
    [value]="suggestionSelected ? suggestionSelected.displayValue : ''"
    type="text"
    (focus)="!isReadOnly ? showSuggestions() : null"
    [tabindex]="isReadOnly ? '-1' : null"
    (contextmenu)="onContextMenu($event)"
    (press)="onContextMenu($any($event))"
    readonly="true"
    autocomplete="no" />
  <i class="icon fas fa-list" (click)="changeDisplayPanel()" aria-hidden="true" type="button"></i>
</span>

<div *ngIf="displayPanel" [style.width.px]="elementRef.nativeElement.firstElementChild.clientWidth" class="panel">
  <ng-template iuDynamicContainer></ng-template>
  <div style="float: right">
    <button pButton class="btnPanel" type="button" label="Annuler" (click)="changeDisplayPanel()"></button>
    <button pButton class="btnPanel" type="button" label="Valider" (click)="validAttributes()"></button>
  </div>
</div>

<div
  *ngIf="displaySuggestion && suggestions.length > 0"
  style="position: relative; top: 50px; width: calc(50% - 10px); left: 5px">
  <div
    class="p-autocomplete-panel ui-widget-content ui-corner-all ui-shadow"
    style="max-height: 240px; left: 0; top: 0; width: 100%">
    <ul class="p-autocomplete-items p-autocomplete-list ui-widget-content ui-widget ui-corner-all ui-helper-reset">
      <li
        (click)="selectItem(option)"
        *ngFor="let option of suggestions"
        [ngClass]="['p-autocomplete-list-item ui-corner-all', suggestionSelected === option ? 'p-highlight' : '']">
        <span>{{ option.displayValue }}</span>
      </li>
    </ul>
  </div>
</div>
