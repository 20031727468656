import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { DynamicContainerDirective } from '../../../../iupics-util/directives/dynamic-container.directive';
import ButtonUiComponent from '../../../standard/fields/button-ui/button-ui.component';
import GridTabInfinityScrollUiComponent from '../../../standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import ModalUiComponent from '../../../standard/layouts/modal-ui/modal-ui.component';
import UniversalFilterUiComponent from '../../../standard/menu/universal-filter-ui/universal-filter-ui.component';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-material-transaction',
  templateUrl: './material-transaction.component.html',
  styleUrls: ['./material-transaction.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    NgClass,
    ButtonUiComponent,
    SidebarModule,
    ModalUiComponent,
    UniversalFilterUiComponent,
    GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export default class MaterialTransactionComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('vcrHidden', { read: ViewContainerRef, static: true })
  vcrHidden: ViewContainerRef;
  constructor(
    protected windowFactory: WindowFactoryService,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected progressService: ProcessInProgressService,
    protected translateService: TranslateService,
    protected messageManager: MessageManagerService
  ) {
    super(
      windowFactory,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
