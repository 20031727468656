import { NgClass, NgFor, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  SecurityContext,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import PrimeOverlayComponent from '@iupics-components/overrided/prime-overlay/prime-overlay.component';
import { XlsxParser } from '@iupics-manager/managers/xlsx-parser/xlsx-parser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as mime from 'mime';
import { OverlayPanel } from 'primeng/overlaypanel';
import ModalUiComponent from '../layouts/modal-ui/modal-ui.component';
import PdfViewerComponent from '../pdf-viewer/pdf-viewer.component';
import XmlViewerComponent from '../xml-viewer/xml-viewer.component';
@Component({
  selector: 'iu-preview-doc',
  templateUrl: './preview-doc.component.html',
  styleUrls: ['./preview-doc.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    NgIf,
    forwardRef(() => ModalUiComponent),
    NgStyle,
    NgClass,
    NgFor,
    TranslateModule,
    NgTemplateOutlet,
    PrimeOverlayComponent,
    PdfViewerComponent,
    XmlViewerComponent,
  ],
})
export default class PreviewDocComponent implements OnInit, AfterViewInit {
  @Input()
  modal = false;
  @Input()
  urlFile;
  @Input()
  fileName;
  isPreviewDisplayed = false;
  isImagePreview = false;
  isDocPreview = false;
  blob: Blob;
  @Input()
  url: SafeUrl;
  dataUrl: any;
  extension: string;
  @Input()
  modalStyle = null;
  @Input()
  imgStyle = null;
  mediaType = 'application/pdf';
  showError = false;
  @Input()
  fromDocServer = true;
  @Input()
  appendTo = 'body';
  hasPreviewError = false;
  @ViewChild('opRef', { static: false })
  opRef: OverlayPanel;
  @Input()
  overlay = false;
  @Input()
  parentComponent: any;
  text;
  csvContent;
  innerHtmlContent;
  @ViewChild('modalRef', { static: false })
  modalRef: ModalUiComponent;
  overridePreviewPanelStyle: string[][];
  constructor(
    private docServerService: DocServerService,
    private sanitized: DomSanitizer,
    private translateService: TranslateService,
    private xlsxParser: XlsxParser
  ) {}

  ngOnInit(): void {
    let builedStyle = {
      'iu-modal-body': { 'align-items': 'center', height: document.documentElement.clientHeight / 1.5 + 'px' },
    };
    this.modalStyle = builedStyle;
  }

  ngAfterViewInit(): void {
    if (this.fileName && this.urlFile) {
      this.onPreview(null, this.fileName, this.urlFile);
    }
  }

  onPreview(event, fileName, url) {
    this.innerHtmlContent = null;
    this.hasPreviewError = false;
    this.isPreviewDisplayed = false;
    this.isDocPreview = false;
    this.isImagePreview = false;
    this.dataUrl = null;
    this.fileName = fileName;
    this.extension = fileName ? (fileName.split('.').length > 1 ? fileName.split('.').pop() : null) : null;
    if (this.extension) {
      const urlPreview = url?.replace && this.fromDocServer ? this.docServerService.getUrlPreview(url) : url;
      const mimeType = mime.getType(this.extension.toLowerCase());
      if (mimeType && mimeType.includes('image')) {
        this.isPreviewDisplayed = true;
        this.isImagePreview = true;
        this.url = urlPreview;
      } else if (this.getMediaType(this.fileName)) {
        this.mediaType = this.getMediaType(this.fileName);
        this.isPreviewDisplayed = true;
        this.isDocPreview = true;
        this.url = urlPreview as string;
      } else {
        this.fileName = null;
        this.url = null;
      }
      this.preview(this.url);
    } else {
      this.isPreviewDisplayed = true;
      this.showError = true;
    }
    if (this.overlay) {
      this.alignPreviewFile(event);
      this.opRef.show(event);
    }
  }

  getMediaType(fileName: string) {
    const name = fileName.toLowerCase();
    const ext = name.split('.').pop();
    return mime.getType(ext);
  }

  // Duplicate du pipe docserverurl
  preview(src: any) {
    this.docServerService.getDocument(src).subscribe((finalResponse) => {
      this.hasPreviewError = false;
      if (
        finalResponse == null ||
        finalResponse.text.includes('Could not find document:') ||
        finalResponse.response.body.size === 0
      ) {
        this.hasPreviewError = true;
        this.dataUrl = null;
      } else {
        if (['txt', 'sh', 'csv', 'xlsx'].includes(this.extension)) {
          const textReader = new FileReader();

          textReader.onloadend = () => {
            if (typeof textReader.result === 'string') {
              const fileContent = textReader.result;
              // Generate preview based on file type
              switch (this.extension) {
                case 'xlsx':
                  this.previewXlsx(fileContent);
                  break;
                case 'csv':
                  this.previewCsv(fileContent);
                  break;
                case 'txt':
                  this.previewText(fileContent);
                  break;
                case 'sh':
                  this.previewText(fileContent);
                  break;
                default:
                  this.innerHtmlContent = this.sanitized.sanitize(
                    SecurityContext.HTML,
                    this.translateService.instant('previewDoc.noPreview')
                  );
              }
            }
            if (this?.parentComponent?.scrollTo) {
              setTimeout(() => {
                this.parentComponent.scrollTo();
              }, 10);
            }
          };

          textReader.readAsBinaryString(finalResponse.response.body);
        } else {
          //img, pdf, xml
          const reader = new FileReader();
          reader.onloadend = (evt) => {
            let base64 = evt.target.result as string;
            let fileName = this.fileName;
            let ext = this.extension;
            let mimeType = 'image/png';
            if (ext) {
              ext = ext.toLocaleLowerCase();
              mimeType = mime.getType(ext);
            }
            if (fileName && base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)) {
              const mimeTypeToReplace = base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
              this.dataUrl = base64.replace(mimeTypeToReplace, mimeType);
            } else {
              if (!mimeType.includes('image')) {
                this.dataUrl = null;
              } else {
                this.dataUrl = this.sanitized.bypassSecurityTrustUrl('assets/imageError.webp');
              }
            }
            if (this?.parentComponent?.scrollTo) {
              setTimeout(() => {
                this.parentComponent.scrollTo();
              }, 10);
            }
          };
          reader.readAsDataURL(finalResponse.response.body as Blob);
        }

        this.blob = finalResponse.response.body;
      }
    });
  }

  async previewXlsx(fileContent: string) {
    const workbook = await this.xlsxParser.readExcel(fileContent, { type: 'binary' });
    let html = '';
    // Extract the content from each sheet
    workbook.eachSheet((sheet, sheetId) => {
      html += `<h3>${sheet.name}</h3>`;
      const sheetData = [];
      let columnData = {};
      sheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        const rowData = {};
        if (rowNumber == 1) {
          row.eachCell((cell, colNumber) => {
            columnData[colNumber] = cell.value;
          });
        } else {
          row.eachCell((cell, colNumber) => {
            rowData[`${columnData[colNumber]}`] = cell.value;
          });
          sheetData.push(rowData);
        }
      });
      html += this.generateTableFromJson(sheetData);
    });
    this.innerHtmlContent = this.sanitized.sanitize(SecurityContext.HTML, html);
  }
  generateTableFromJson(lines: any[]) {
    let html = '<table>';

    // Add table header
    html += '<thead><tr>';
    const headers = Object.keys(lines[0]);

    headers.forEach((header) => {
      html += `<th >${header}</th>`;
    });
    html += '</tr></thead>';

    // Add table body
    html += '<tbody>';
    for (let i = 0; i < lines.length; i++) {
      const values = Object.keys(lines[i]);
      html += `<tr">`;
      values.forEach((value) => {
        html += `<td>${lines[i][value]}</td>`;
      });
      html += '</tr>';
    }
    html += '</tbody>';

    html += '</table>';
    return html;
  }
  previewCsv(fileContent: string): void {
    this.innerHtmlContent = this.sanitized.sanitize(SecurityContext.HTML, this.generateTable(fileContent));
  }

  generateTable(fileContent: string, delimiter = ';') {
    const lines = fileContent.split('\n');
    let html = '<table>';

    // Add table header
    html += '<thead><tr>';
    const headers = lines[0].split(delimiter);
    if (headers.length != 1 || headers[0] != '') {
      headers.forEach((header) => {
        html += `<th>${header}</th>`;
      });
      html += '</tr></thead>';
    }
    // Add table body
    html += '<tbody>';
    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split(delimiter);
      if (i == 1 && values.length != headers.length) {
        if (delimiter === ',') return '';
        return this.generateTable(fileContent, ',');
      }
      if (values.length != 1 || values[0] != '') {
        html += `<tr> `;
        values.forEach((value) => {
          html += `<td>${value}</td>`;
        });
        html += '</tr>';
      }
    }
    html += '</tbody>';

    html += '</table>';
    return html;
  }

  previewText(fileContent: string): void {
    this.innerHtmlContent = this.sanitized.sanitize(SecurityContext.HTML, `<pre>${fileContent}</pre>`);
  }

  setParam(base64: string, options = {}) {
    const defaultOptions = { toolbar: 0, scrollbar: 0, navpanes: 0, zoom: '80, 0, -20' };
    const { toolbar, scrollbar, navpanes, zoom } = { ...defaultOptions, ...options };
    return `${base64}#toolbar=${toolbar}&navpanes=${navpanes}&scrollbar=${scrollbar}&zoom=${zoom};`;
  }

  alignPreviewFile({ x, y, target }: MouseEvent) {
    /* Preview size */
    const pWidth = 40;
    const pHeight = 48;
    /* Alignement offset */
    const offset = 10;
    /* viewport width */
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    /* Target position */
    const targetBCR = (target as HTMLElement).getBoundingClientRect();
    const left = `${Math.floor(vw / 2) >= x ? targetBCR.left : targetBCR.right - Math.floor(vw * (pWidth / 100))}px`;
    const top = `${
      Math.floor(vh / 2) >= y ? targetBCR.bottom : targetBCR.top - Math.floor(vh * (pHeight / 100)) - offset
    }px`;
    /* Override prime overlay panel style  */
    this.overridePreviewPanelStyle = [
      ['position', 'absolute'],
      ['width', `${pWidth}%`],
      ['height', `${pHeight}%`],
      ['top', top],
      ['right', 'auto'],
      ['bottom', 'auto'],
      ['left', left],
    ];
  }
  handleAlign(context: PrimeOverlayComponent) {
    if (context?.overlayVisible) {
      this.overridePreviewPanelStyle.forEach(([key, value]) => (context.container.style[key] = value));
    }
  }
}
