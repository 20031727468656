<iu-modal-ui
  *ngIf="isPreviewDisplayed && modal"
  #modalRef
  (closeModalEmitter)="isPreviewDisplayed = false; showError = false"
  [hasFooter]="true"
  [appendTo]="appendTo"
  [angularStyle]="modalStyle">
  <ng-container
    *ngTemplateOutlet="
      mainTemplate;
      context: {
        dataUrl: dataUrl,
        innerHtmlContent: innerHtmlContent,
        isImagePreview: isImagePreview,
        modal: modal,
        imgStyle: imgStyle,
        src: src,
        fileName: fileName,
        showError: showError,
        isDocPreview: isDocPreview,
        mediaType: mediaType
      }
    "></ng-container>
  <ng-content></ng-content>
</iu-modal-ui>
<iu-prime-overlay *ngIf="overlay" #opRef [appendTo]="appendTo" [handleAlign]="handleAlign.bind(this)">
  <div class="usearch-file-preview">
    <ng-container
      *ngTemplateOutlet="
        mainTemplate;
        context: {
          dataUrl: dataUrl,
          innerHtmlContent: innerHtmlContent,
          isImagePreview: isImagePreview,
          modal: modal,
          imgStyle: imgStyle,
          src: src,
          fileName: fileName,
          showError: showError,
          isDocPreview: isDocPreview,
          mediaType: mediaType
        }
      "></ng-container>
  </div>
</iu-prime-overlay>
<ng-container *ngIf="isPreviewDisplayed && !modal && !overlay">
  <ng-container
    *ngTemplateOutlet="
      mainTemplate;
      context: {
        dataUrl: dataUrl,
        innerHtmlContent: innerHtmlContent,
        isImagePreview: isImagePreview,
        modal: modal,
        imgStyle: imgStyle,
        src: src,
        fileName: fileName,
        showError: showError,
        isDocPreview: isDocPreview,
        mediaType: mediaType
      }
    "></ng-container>
</ng-container>
<ng-template
  #mainTemplate
  let-dataUrl="dataUrl"
  let-innerHtmlContent="innerHtmlContent"
  let-isImagePreview="isImagePreview"
  let-modal="modal"
  let-imgStyle="imgStyle"
  let-src="src"
  let-fileName="fileName"
  let-showError="showError"
  let-isDocPreview="isDocPreview"
  let-mediaType="mediaType">
  <p *ngIf="hasPreviewError">
    {{ 'previewDoc.noPreview' | translate }}
  </p>
  <ng-container *ngIf="dataUrl||innerHtmlContent">
    <ng-container *ngIf="isImagePreview">
      <img
        class="previewImg"
        [data-cy]="modal ? 'modal-preview-data' : 'preview-data'"
        [ngStyle]="imgStyle"
        [src]="dataUrl"
        [alt]="fileName" />
    </ng-container>
    <iu-pdf-viewer *ngIf="extension === 'pdf'" [dataUrl]="dataUrl"></iu-pdf-viewer>
    <iu-xml-viewer *ngIf="extension === 'xml'" [blob]="blob"></iu-xml-viewer>
    <div
      *ngIf="['sh', 'txt', 'csv', 'xlsx'].includes(extension) && innerHtmlContent"
      class="other-viewer"
      [innerHTML]="innerHtmlContent"></div>
    <ng-template *ngIf="showError">
      {{ 'previewDoc.noExtension' | translate }}
    </ng-template>
    <ng-template #docError>
      <p *ngIf="isDocPreview">
        {{ 'previewDoc.noPreview' | translate }}
        <a target="_blank" [href]="dataUrl" [download]="fileName"> {{ 'previewDoc.downloadIt' | translate }}</a>
      </p>
    </ng-template>
  </ng-container>
</ng-template>
