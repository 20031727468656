import { NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CompiereDataGridFilterModel, CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import GridTabInfinityScrollUiComponent from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { ColumnState, SortModelItem } from 'ag-grid-community';
import { AbstractWidgetComponent } from '../../abstract-widget.component';

@Component({
  selector: 'iu-grid-widget',
  templateUrl: './grid-widget.component.html',
  styleUrls: ['./grid-widget.component.scss'],
  standalone: true,
  imports: [NgIf, GridTabInfinityScrollUiComponent],
})
export default class GridWidgetComponent extends AbstractWidgetComponent implements OnInit {
  @ViewChild(GridTabInfinityScrollUiComponent, { static: true })
  gridTabInfinityScroll: GridTabInfinityScrollUiComponent;

  data: any;
  gridTabFilter: any;
  tabId;
  isTabTopLevel;
  gridPaginator;
  container;
  DOMParentComponent;
  editView;
  filterModel: { [key: string]: CompiereDataGridFilterModel };
  sortModel: SortModelItem[];
  gridState: ColumnState[];
  @Output()
  openTabEmitter: EventEmitter<any> = new EventEmitter();
  constructor(
    private uiCreatorService: UICreatorService,
    private cd: ChangeDetectorRef
  ) {
    super(uiCreatorService);
  }
  onRowSelectedOnGridTab(rowSelected) {
    if (!this.isPreview) {
      const dataUUID = rowSelected?.data[0]?.Data_UUID;
      if (dataUUID) {
        const record_id = dataUUID.split(',')[1];
        this.zoomAcross(record_id, this.data.tableName, null);
      }
    }
  }
  ngOnInit() {}
  updateWidget() {
    this.data = null;
    this.cd.detectChanges();
    this.initGridData();
  }
  flatten(children) {
    return children
      .map((child) => {
        if (child.children.length > 0) {
          return [...this.flatten(child.children)];
        } else return [child];
      })
      .flat();
  }
  onColumnStateChanged(columnState: ColumnState[]) {
    this.widget.gridState = JSON.stringify(columnState);
    this.widgetChanged.emit(this.widget);
  }
  initGridData() {
    if (this.widget?.windowId && this.widget?.tabId) {
      const observ = this.uiCreatorService.getWindow(this.widget.windowId);
      this.subscriptions.push(
        observ.subscribe((tabUI) => {
          const children = this.flatten(tabUI.editTabs[0].children);
          let gridData = tabUI.firstTab.gridView;
          if (gridData.tabId !== this.widget.tabId) {
            gridData = children.find((child) => child.tabId === this.widget.tabId);
          }
          if (gridData) {
            this.tabId = gridData.tabId;
            this.gridPaginator = gridData.gridPaginator;
            this.gridTabFilter = gridData.gridTabFilter;
            this.isTabTopLevel = gridData.data.tabLevel === 0 ? true : false;
            this.container = this;
            this.editView = tabUI.firstTab.editView;
            if (this.widget.gridRequest) {
              const request: CompiereDataGridRequestJSON = JSON.parse(this.widget.gridRequest);
              if (request.filterModel) {
                this.filterModel = request.filterModel;
              }
              if (request.sortModel) {
                this.sortModel = request.sortModel;
              }
            }
            if (this.widget.gridState) {
              const gridState: ColumnState[] = JSON.parse(this.widget.gridState);
              this.gridState = gridState;
            }
            this.data = gridData?.data;
          }
        })
      );
    }
  }

  onChildUpdate(event): void {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}
}
