import { CompiereMenu } from './compiere-menu-json';

export interface ColumnCompiereJson {
  label: string;
  restriction?: any;
  columnType: string;
}

export interface DataTableCompiereJson {
  rows: any[];
  columns: ColumnCompiereJson[];
  AD_Window_ID: number;
  WindowName: string;
  tableName: string;
  baseTableName: string;
  baseKeyColumnName: string;
  whereClause: string;
  parameterRestrictions: any[];
}

export interface DimensionCompiereJson {
  columnType: string;
  Categories: any[];
  columnName: string;
  name: string;
  groupBy: boolean;
  ad_Reference_ID: number;
  ad_Column_ID: number;
  ad_Reference_Value_ID: number;
}

export interface DashboardFormatVOCompiereJson {
  name: string;
  description: string;
  isActive?: any;
  dashboardType: string;
  style?: any;
  form?: any;
  dataPoint: string;
  dataLabel: string;
  width: number;
  height: number;
  region: string;
  dimensions: DimensionCompiereJson[];
  ad_Client_ID: number;
  ad_ReportView_ID: number;
  ad_DashboardFormat_ID: number;
}

export interface WidgetFavoriteCompiereJson {
  menu: CompiereMenu;
  widgetData: WidgetDataCompiereJson;
}

export interface WidgetDataCompiereJson {
  angularClass?: string;
  labels: any[];
  dataSet: any[];
  error?: any;
  dataTable: DataTableCompiereJson;
  ad_RoleWidget_ID: number;
  ad_Menu_ID: number;
  dashboardFormatVO: DashboardFormatVOCompiereJson;
}

export interface WidgetCompiereJson {
  angularClass: string;
  ad_DashboardFormat_ID: number;
  ad_Menu_ID: number;
  name: string;
  description: string;
}
export interface WidgetOptions {
  defaultView: WidgetViewType;
}
export enum WidgetViewType {
  COUNT_LIST = 'CLW',
  GEN_WIDGET = 'GEN',
  DOUGHNUT_CHART = 'DNC',
  POLAR_CHART = 'PLC',
  RADAR_CHART = 'RDC',
  SCATTER_CHART = 'SCC',
  BUBBLE_CHART = 'BUC',
  PIE_CHART = 'PIC',
  LINE_CHART = 'LIC',
  COLUMN_CHART = 'CLC',
  BAR_CHART = 'BRC',
  GRID_VIEW_WIDGET = 'GVW',
}

export enum CountListViewType {
  COUNT_VIEW = 'CVT',
  LIST_VIEW = 'LVT',
}
