import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { DragHandleDirective } from '@iupics-components/directives/drag-handle.directive';
import { DraggableDirective } from '@iupics-components/directives/draggable.directive';
import { Global } from '@iupics-manager/models/global-var';
import { debounce } from 'lodash';
import { ButtonModule } from 'primeng/button';
import { ZIndexUtils } from 'primeng/utils';
import { TabMenuItem } from '../tab-menu/tab-menu-item';
@Component({
  selector: 'iu-draggable-window',
  templateUrl: './draggable-window.component.html',
  styleUrls: ['./draggable-window.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [DraggableDirective, DragHandleDirective, ButtonModule, NgIf],
})
export default class DraggableWindowComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() title: string = '';
  @Input() iconClass: string = '';
  @Input()
  right: string = '0';
  @Input()
  left: string = '0';
  @Input()
  top: string = '0';
  @Input()
  height: string = '55em';
  @Input()
  width: string = '40em';
  @ViewChild('content', { read: ElementRef, static: true }) contentRef: ElementRef<HTMLDivElement>;
  @ViewChild('window', { read: ElementRef, static: true }) windowRef: ElementRef<HTMLDivElement>;
  @ViewChild('contentVcr', { read: ViewContainerRef, static: true }) contentVcr: ViewContainerRef;
  contentComponent: any;
  isDragging = false;
  initialX = 0;
  initialY = 0;
  expanded = false;
  tabMenu: TabMenuItem;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.onResize = debounce(this.onResize, 500);
  }

  ngOnInit(): void {
    ZIndexUtils.set('draggable-window', this.el.nativeElement, 500);

    const props: any = {};
    if (this.left === '0' && this.right !== '0') {
      props.right = `${this.left}`;
    } else if (this.left !== '0' && this.right === '0') {
      props.left = `${this.left}`;
    } else {
      props.left = 0;
    }
    props.top = `${this.top}`;
    props.height = `${this.height}`;
    props.width = `${this.width}`;
    props.position = 'absolute';
    this.changeStyle(props);
  }

  public getViewport(): any {
    const win = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName('body')[0];
    const w = win.innerWidth || e.clientWidth || g.clientWidth;
    const h = win.innerHeight || e.clientHeight || g.clientHeight;

    return { width: w, height: h };
  }
  onClose(obj?: { originalEvent: Event; item: TabMenuItem }): void {
    if (obj?.item) {
      Global.workspace.removeWindow(obj.item.id);
    }
    this.el.nativeElement.remove();
  }
  onExpand(): void {
    this.top = this.windowRef.nativeElement.style.top;
    this.left = this.windowRef.nativeElement.style.left;
    this.height = this.windowRef.nativeElement.style.height;
    this.width = this.windowRef.nativeElement.style.width;
    const vp = this.getViewport();
    this.changeStyle({ left: `0px`, top: `0px`, height: `${vp.height}px`, width: `${vp.width}px` });
    this.expanded = true;
  }
  changeStyle(props: any) {
    Object.keys(props).forEach((k) => {
      this.renderer.setStyle(this.el.nativeElement, k, props[k]);
      if (k !== 'position') this.renderer.setStyle(this.windowRef.nativeElement, k, props[k]);
    });
  }
  onMinimize(): void {
    this.changeStyle({
      left: `${this.left}`,
      top: `${this.top}`,
      height: `${this.height}`,
      width: `${this.width}`,
    });
    this.expanded = false;
  }
  onHide() {
    this.changeStyle({ display: `none` });
    this.tabMenu = Global.workspace.addWindow({
      icon: this.iconClass,
      label: this.title,
      command: this.onShow.bind(this),
      onClose: this.onClose.bind(this),
    });
  }
  onShow(obj?: { originalEvent: Event; item: TabMenuItem }) {
    if (obj?.item) {
      Global.workspace.removeWindow(parseInt(obj.item.id));
    }
    this.changeStyle({ display: null });
  }
  onDragStart(event: Event): void {
    const coords = this.getEventCoordinates(event);
    this.isDragging = true;
    this.initialX = coords.clientX - this.windowRef.nativeElement.getBoundingClientRect().left;
    this.initialY = coords.clientY - this.windowRef.nativeElement.getBoundingClientRect().top;
  }
  onDragEnd(event: Event): void {
    if (this.isDragging) {
      const coords = this.getEventCoordinates(event);
      const offsetX = coords.clientX - this.windowRef.nativeElement.offsetLeft;
      const offsetY = coords.clientY - this.windowRef.nativeElement.offsetTop;
      this.changeStyle({
        right: null,
        left: `${coords.clientX - offsetX}px`,
        top: `${coords.clientY - offsetY}px`,
      });
      this.isDragging = false;
    }
  }
  private observeElementResize() {
    const element = this.windowRef.nativeElement;

    const resizeObserver = new ResizeObserver(() => {
      this.onResize();
    });

    resizeObserver.observe(element);
  }
  onResize() {
    this.changeStyle({
      width: this.windowRef.nativeElement.style.width,
      height: this.windowRef.nativeElement.style.height,
    });
  }
  ngAfterViewInit() {
    this.observeElementResize();
  }
  ngOnDestroy(): void {
    if (this.windowRef.nativeElement) {
      ZIndexUtils.clear(this.windowRef.nativeElement);
    }
    if (this.tabMenu) {
      Global.workspace.removeWindow(this.tabMenu.id);
    }
  }
  private getEventCoordinates(event: Event): { clientX: number; clientY: number } {
    if (event instanceof TouchEvent) {
      return { clientX: event.changedTouches[0].clientX, clientY: event.changedTouches[0].clientY };
    } else if (event instanceof MouseEvent) {
      return { clientX: event.clientX, clientY: event.clientY };
    } else {
      return { clientX: 0, clientY: 0 };
    }
  }
}
