import { Injectable } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { WidgetCenterService } from '../widget-center/widget-center.service';

@Injectable({
  providedIn: 'root',
})
export class GridPreferencesService {
  private gridPreferencesMap = new Map<String, Map<String, GridPreference>>();
  private urlUserGridPreference: string;
  constructor(
    private http: ApiService,
    private config: AppConfig,
    private messageManager: MessageManagerService,
    private translator: TranslateService,
    private widgetService: WidgetCenterService,
    private dashboarService: DashboardService
  ) {}

  private resetCachingData() {
    this.widgetService.resetCachingData();
    this.dashboarService.resetCachingData();
  }
  findGridPreferenceById(id: number): GridPreference | undefined {
    for (const [key, gridPreferenceMap] of this.gridPreferencesMap) {
      for (const [name, gridPreference] of gridPreferenceMap) {
        if (gridPreference.userGridPreferenceID === id) {
          return gridPreference;
        }
      }
    }
    return undefined; // Return undefined if not found
  }
  public getGridPreferenceFromMap(gridPreference: GridPreference) {
    const key = this.generateKeyWithGridPreference(gridPreference);
    if (this.gridPreferencesMap.has(key)) {
      if (!this.gridPreferencesMap.get(key).has(gridPreference.name)) {
        this.gridPreferencesMap.get(key).forEach((gp) => {
          if (gp.isDefault) {
            gridPreference = gp;
            return;
          }
        });
        return gridPreference;
      } else {
        return this.gridPreferencesMap.get(key).get(gridPreference.name);
      }
    } else {
      return null;
    }
  }
  public setGridPreferenceFromMap(gridPreference: GridPreference) {
    const key = this.generateKeyWithGridPreference(gridPreference);
    if (!this.gridPreferencesMap.has(key)) {
      this.gridPreferencesMap.set(key, new Map<String, GridPreference>());
    }
    this.gridPreferencesMap.get(key).set(gridPreference.name, gridPreference);
  }
  public saveGridPreference(gridPreference: GridPreference): Observable<GridPreference> {
    this.urlUserGridPreference = this.config.getBackendResource('userGridPreference');
    if (gridPreference && gridPreference.userGridPreferenceID > 0) {
      return this.http.put<GridPreference>(`${this.urlUserGridPreference}`, gridPreference).pipe(
        map((response) => {
          if (response && response.userGridPreferenceID > 0) {
            this.setGridPreferenceFromMap(response);
            return response;
          } else {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translator.instant('generic.error'),
                this.translator.instant('gridpanel.preferences.save_error'),
                'error'
              )
            );
            this.resetCachingData();
            return gridPreference;
          }
        })
      );
    } else {
      return this.http.post<GridPreference>(`${this.urlUserGridPreference}`, gridPreference).pipe(
        map((response) => {
          if (response && response.userGridPreferenceID > 0) {
            this.setGridPreferenceFromMap(response);
            return response;
          } else {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translator.instant('generic.error'),
                this.translator.instant('gridpanel.preferences.save_error'),
                'error'
              )
            );
            this.resetCachingData();
            return gridPreference;
          }
        })
      );
    }
  }

  public deleteGridPreference(gridPreference: GridPreference): Observable<boolean> {
    this.urlUserGridPreference = this.config.getBackendResource('userGridPreference');
    const key = this.generateKeyWithGridPreference(gridPreference);
    return this.http.delete<boolean>(`${this.urlUserGridPreference}/${gridPreference.userGridPreferenceID}`).pipe(
      map((removed) => {
        if (removed) {
          if (this.gridPreferencesMap.has(key)) {
            this.gridPreferencesMap.get(key).delete(gridPreference.name);
          }
          gridPreference.userGridPreferenceID = -1;
          this.resetCachingData();
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translator.instant('generic.error'),
              this.translator.instant('gridpanel.preferences.delete_error'),
              'error'
            )
          );
        }
        return removed;
      }),
      catchError(() => {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translator.instant('generic.error'),
            this.translator.instant('gridpanel.preferences.delete_error'),
            'error'
          )
        );
        return of(false);
      })
    );
  }

  public getGridPreferences(preference: GridPreference): Observable<GridPreference[]> {
    this.urlUserGridPreference = this.config.getBackendResource('userGridPreference');
    const key = this.generateKeyWithGridPreference(preference);
    if (this.gridPreferencesMap.has(key)) {
      return of(Array.from(this.gridPreferencesMap.get(key).values()));
    } else {
      return this.http
        .get<GridPreference[]>(
          `${this.urlUserGridPreference}?AD_Table_ID=${preference.tableID}&AD_FormDetail_ID=${
            preference.formDetailID
          }&AD_Tab_ID=${preference.tabID}${
            preference.name && preference.name.length > 0 ? '&name=' + preference.name : ''
          }`
        )
        .pipe(
          map((gridPreferencesMap) => {
            if (gridPreferencesMap) {
              const response = [];
              if (gridPreferencesMap.length == 0) {
                this.gridPreferencesMap.set(
                  this.generateKeyWithGridPreference(preference),
                  new Map<String, GridPreference>()
                );
              }
              for (let i = 0; i < gridPreferencesMap.length; i++) {
                const gridPreference = gridPreferencesMap[i];
                if (gridPreference.name) {
                  this.setGridPreferenceFromMap(gridPreference);
                  response.push(gridPreference);
                }
              }
              return response;
            } else {
              return [];
            }
          }),
          catchError(() => {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translator.instant('generic.error'),
                this.translator.instant('gridpanel.preferences.get_error')
              )
            );
            return of([]);
          })
        );
    }
  }

  public getGridPreference(preference: GridPreference) {
    this.urlUserGridPreference = this.config.getBackendResource('userGridPreference');
    if (this.getGridPreferenceFromMap(preference)) {
      return of(this.getGridPreferenceFromMap(preference));
    } else {
      return this.getGridPreferences(preference).pipe(
        map((gridPreferences) => {
          if (!preference.name || preference.name.trim().length === 0) {
            const gpFound = gridPreferences.find((gp) => gp.isDefault);
            if (gpFound) {
              return gpFound;
            }
          }
          return preference;
        }),
        catchError(() => {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translator.instant('generic.error'),
              this.translator.instant('gridpanel.preferences.get_error')
            )
          );
          return of(preference);
        })
      );
    }
  }
  generateKeyWithGridPreference(gridPreference: GridPreference) {
    let key = gridPreference.tableID > 0 ? gridPreference.tableID + '' : 'X';
    key += gridPreference.formDetailID > 0 ? gridPreference.formDetailID + '' : 'X';
    key += gridPreference.tabID > 0 ? gridPreference.tabID + '' : 'X';
    return key;
  }
}
export interface GridPreference {
  userGridPreferenceID: number;
  name?: string;
  formDetailID: number;
  gridRequest?: string;
  gridState?: string;
  gridStateReduced?: string;
  isDefault?: boolean;
  widgetID?: number;
  tabID: number;
  windowID: number;
  tableID: number;
}
export enum GridPreferenceType {
  REDUCED = 'R',
  EXPANDED = 'E',
}
