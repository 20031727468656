import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { CompiereLoginJSON, OAuth2TokenJSON } from '@compiere-ws/models/compiere-login-json';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { AppConfig } from '@iupics-config/app.config';
import {
  IupicsCookieService,
  LocalStorageIupics,
} from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { ModuleConfig, modulesConfig } from '@iupics-manager/models/modules-config';
import { UserPreference } from '@web-desktop/models/user-preference';
import { environment } from 'environments/environment';
import { merge } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CompiereLoginService {
  private loginInfoUrl: string;
  private usersRoleUrl: string;
  private languageUrl: string;
  private ctxUrl: string;
  private prefUrl: string;
  private resetUrl: string;
  private organisationsUrl: string;
  private warehousesUrl: string;

  constructor(
    private http: ApiService,
    private config: AppConfig,
    private cookieService: IupicsCookieService
  ) {}

  login(login: string, password: string): Observable<OAuth2TokenJSON> {
    const formData = new FormData();
    formData.append('username', login);
    formData.append('password', password);
    formData.append('grant_type', 'api');
    formData.append('client_id', environment.clientId);

    return this.http.post<OAuth2TokenJSON>(environment.authServerUrl, formData, {
      headers: new HttpHeaders({
        Authorization:
          'Basic ' + Buffer.from(environment.clientId + ':' + environment.clientSecret, 'utf-8').toString('base64'),
        Device: this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.device_id),
        'X-Lang': (<CompiereLanguage>(
          JSON.parse(this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.default_language))
        )).iso_code,
      }),
    });
  }

  loadUrl() {
    this.loginInfoUrl = this.config.getBackendResource('sessionInfo');
    this.usersRoleUrl = this.config.getBackendResource('getUserRole');
    this.languageUrl = this.config.getBackendResource('changeLanguage');
    this.ctxUrl = this.config.getBackendResource('ctx');
    this.prefUrl = this.config.getBackendResource('preference');
    this.resetUrl = this.config.getBackendResource('cachereset');
    this.organisationsUrl = this.config.getBackendResource('organisationsCTX');
    this.warehousesUrl = this.config.getBackendResource('warehousesCTX');
  }

  getConfig(): Observable<any> {
    return this.http.get<any>(environment.configUrl);
  }
  refreshLogin(refresh_token: string): Observable<OAuth2TokenJSON> {
    const formData = new FormData();
    formData.append('refresh_token', refresh_token);
    formData.append('grant_type', 'refresh_token');
    formData.append('client_id', environment.clientId);

    return this.http.post<OAuth2TokenJSON>(environment.authServerUrl, formData, {
      headers: new HttpHeaders({
        Authorization:
          'Basic ' + Buffer.from(environment.clientId + ':' + environment.clientSecret, 'utf-8').toString('base64'),
      }),
    });
  }

  getLoginInfo(access_token: string): Observable<CompiereLoginJSON> {
    return this.http.get<any>(this.loginInfoUrl, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + access_token,
      }),
    });
  }

  getCtx(): Observable<any> {
    return this.http.get<any>(this.ctxUrl).pipe(
      map((ctx) => {
        try {
          if (ctx) {
            Object.keys(ctx).forEach((key) => {
              ctx[key] = isNaN(parseInt(ctx[key], 10)) ? ctx[key] : parseInt(ctx[key], 10);
              if (key.startsWith('apiz.modules')) {
                // charge la configuration des modules
                let configKey = key.replace('apiz.modules.', '');
                const moduleName = configKey.substring(0, configKey.indexOf('.'));
                configKey = configKey.replace(moduleName + '.', '');
                const moduleConfig = this.getModuleConfig(configKey, ctx[key]);
                merge(modulesConfig, { [moduleName]: moduleConfig });
                delete ctx[key];
              }
            });
          }
        } catch (e) {
          return ctx;
        }
        return ctx;
      })
    );
  }

  getModuleConfig(configKey: string, value: any): ModuleConfig {
    const keys = configKey.split('.');
    const returnObj: ModuleConfig = {};
    let objToLoop = returnObj; // on transmet la référence de returnObj à objToLoop
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const isLast = i === keys.length - 1;
      objToLoop[key] = isLast ? value : {};
      objToLoop = objToLoop[key];
    }
    return returnObj;
  }

  updateCtx(ctx: any): Observable<any> {
    return this.http.put<any>(this.ctxUrl, ctx).pipe(
      map((ctx_response) => {
        try {
          if (ctx_response) {
            Object.keys(ctx_response).forEach((key) => {
              ctx_response[key] = isNaN(parseInt(ctx_response[key], 10))
                ? ctx_response[key]
                : parseInt(ctx_response[key], 10);
            });
          }
        } catch (e) {
          return ctx_response;
        }
        return ctx_response;
      })
    );
  }

  changeLanguage(language: CompiereLanguage): Observable<any> {
    return this.http.post<CompiereLoginJSON>(this.languageUrl + language.iso_code, {});
  }

  getAllUsers(): Observable<any> {
    return this.http.get<any>(this.usersRoleUrl);
  }

  getPref(): Observable<any> {
    return this.http.get<any>(this.prefUrl);
  }

  savePref(userPref: UserPreference): Observable<any> {
    return this.http.post<CompiereLoginJSON>(this.prefUrl, userPref);
  }

  resetCache(): Observable<any> {
    return this.http.get<any>(this.resetUrl);
  }

  getOrganisations(): Observable<IAutocomplete[]> {
    return this.http.get<IAutocomplete[]>(this.organisationsUrl);
  }

  getWarehouses(): Observable<IAutocomplete[]> {
    return this.http.get<IAutocomplete[]>(this.warehousesUrl);
  }
}
