import { NgClass, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CompiereDataGridType, DataStore, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { DataConflict } from '@iupics-manager/models/data-conflict';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { ButtonModule } from 'primeng/button';
import { Subscription } from 'rxjs';

@Component({
  selector: 'iu-mergetool',
  styleUrls: ['./mergetool.component.scss'],
  templateUrl: './mergetool.component.html',
  standalone: true,
  imports: [NgFor, NgClass, ButtonModule, TranslateModule],
})
export default class MergetoolComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input()
  parentEditView: EditViewUiComponent;

  @Input()
  conflictsResult: DataConflict;

  localData: DataStore;

  @Output()
  mergeEmitter = new EventEmitter<any>();

  dataToMerge = { fields: [], local: {}, remote: {} };
  mergedData = new DataStore();
  mergedInfo = {};
  colNameToLabel = new Map<string, string>();
  isSelected = {};

  constructor(
    private store: DataStoreService,
    private uiCreator: UICreatorService
  ) {}

  ngOnInit() {
    const currentDataStoreKey = this.parentEditView.currentDataStoreKey;
    const request: DataStoreRequest = {
      windowId: currentDataStoreKey.windowId,
      record_id: currentDataStoreKey.recordId,
      parent_constraint: currentDataStoreKey.parentId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: currentDataStoreKey.tabId,
        startRow: 0,
        endRow: 1,
        windowCtx: this.parentEditView.getCurrentContext(),
        validation: this.parentEditView.getTabWhereclause(),
      },
    };
    this.store.getWindowSingleData(request).subscribe((dataStore) => {
      this.localData = dataStore;
    });
    this.checkDataToMerge();
    this.getFieldsToDisplay();
  }

  /**
   * Check the data to merge to prepare the mergetool
   */
  checkDataToMerge() {
    Object.keys(this.conflictsResult.dataConflict).forEach((fieldName) => {
      this.dataToMerge.fields.push(fieldName);
    });
  }

  /**
   * Get the name of the different fields to display
   */
  getFieldsToDisplay() {
    if (this.localData.key) {
      this.subscriptions.push(
        this.uiCreator
          .getActualTab(this.parentEditView.currentDataStoreKey.windowId, this.localData.key.tabId)
          .subscribe((tabUI) => {
            tabUI.firstTab.editView.children.forEach((field) => {
              if (field.children) {
                // Accordion Case
                field.children.forEach((subField) => {
                  if (this.dataToMerge.fields.filter((f) => f === subField.data.columnName).length > 0) {
                    this.colNameToLabel.set(subField.data.columnName, subField.data.label);
                  }
                });
              } else {
                if (this.dataToMerge.fields.filter((f) => f === field.data.columnName).length > 0) {
                  this.colNameToLabel.set(field.data.columnName, field.data.label);
                }
              }
            });
          })
      );
    }
  }

  /**
   *
   * @param event MouseEvent
   * @param field The chosen field to merge
   * @param data The data to merge
   * @param who From who the data come
   */
  onClick(event: MouseEvent, field: string, data: any, who: string): void {
    event.preventDefault();
    event.stopPropagation();
    this.mergedData.data[field] = cloneDeep(data);
    this.isSelected[field] = { who: who };
  }

  /**
   * Keep all the data from one side, local or remote
   * @param event MouseEvent
   * @param who From who the data must be kept
   */
  keepAll(event: MouseEvent, who: string) {
    event.stopPropagation();
    event.preventDefault();
    this.dataToMerge.fields.forEach((field) => {
      this.onClick(
        document.createEvent('MouseEvent'),
        field,
        who === 'local' ? this.localData.data[field] : this.conflictsResult.dataConflict[field],
        who
      );
    });
  }

  /**
   * Save and emit the merged data
   * @param event MouseEvent
   */
  save(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    Object.keys(this.localData.data).forEach((field) => {
      if (!this.mergedData.data[field]) {
        this.mergedData.data[field] = cloneDeep(this.localData.data[field]);
      }
    });
    const dataToSend = {};
    this.dataToMerge.fields.forEach((field) => {
      dataToSend[field] = this.mergedData.data[field];
    });
    this.mergeEmitter.emit({ dataMerged: dataToSend, mergedInfo: this.isSelected });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  getDisplayValue(data: any) {
    return data instanceof Object ? data.displayValue : data;
  }
}
