import { AsyncPipe, DOCUMENT, NgClass, NgFor, NgIf, NgStyle, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DocserverDisplayedTag } from '@compiere-ws/models/docserverDisplayedTag';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { UploadedFile } from '@iupics-components/models/uploaded-file';
import { FilterListOperator, FilterListOptions } from '@iupics-components/pipes/filter-list/filter-list.pipe';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import PreviewDocComponent from '@iupics-components/standard/preview-doc/preview-doc.component';
import { TagsEditorUiComponent } from '@iupics-components/standard/tags-editor-ui/tags-editor-ui.component';
import { OverridedCSS } from '@iupics-manager/models/overrided-css';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { FileUpload } from 'primeng/fileupload';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { DocServerFormatValuePipe } from '../../../iupics-util/pipes/doc-server-format-value/doc-server-format-value.pipe';
import { DocServerUrlPipe } from '../../../iupics-util/pipes/doc-server-url/doc-server-url.pipe';
import { FilterListPipe } from '../../pipes/filter-list/filter-list.pipe';
import PrimeOverlayComponent from '../prime-overlay/prime-overlay.component';
import { GetPercentInPxCssPipe } from './get-percent-in-px-css.pipe';
@Component({
  selector: 'iu-prime-fileupload',
  templateUrl: './prime-fileupload.component.html',
  styleUrls: ['./prime-fileupload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    ProgressSpinnerModule,
    NgFor,
    NgClass,
    NgTemplateOutlet,
    ButtonModule,
    PrimeOverlayComponent,
    SharedModule,
    TableModule,
    NgStyle,
    MessagesModule,
    PreviewDocComponent,
    AsyncPipe,
    SlicePipe,
    TranslateModule,
    DocServerUrlPipe,
    DocServerFormatValuePipe,
    GetPercentInPxCssPipe,
    FilterListPipe,
    TagsEditorUiComponent,
  ],
})
export default class PrimeFileuploadComponent extends FileUpload implements OnInit {
  @ViewChild(PreviewDocComponent)
  private previewDocComponent: PreviewDocComponent;
  @ViewChild('advancedfileinput') advancedfileinput: ElementRef;
  @Output()
  uploadFiles: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  downloadEmitter = new EventEmitter<any>();
  @Output()
  deleteEmitter = new EventEmitter<any>();
  @Output()
  fileSelectEmitter = new EventEmitter<any>();
  @Output()
  openFileOnSideEmitter = new EventEmitter<any>();

  @Input()
  displayedTags: DocserverDisplayedTag[] = [];
  @Input()
  uploadedFiles: UploadedFile[] = [];
  @Input()
  linkedFiles: UploadedFile[] = [];
  @Input()
  uploadingFiles = <any>[];
  @Input()
  displayButtons = true;
  @Input()
  displayFileUploaded = true;
  @Input()
  isLoaderActive = false;
  @Input()
  isReadOnly = false;
  @Input()
  canUpload = true;
  @Input()
  canDelete = true;
  @Input()
  canDownload = true;
  @Input()
  canPreview = false;
  @Input()
  linkedFileList_title = 'fileupload.linked_files_list';
  @Input()
  uploadedFileList_noLinkedFiles = 'fileupload.no_linked_files';
  @Input()
  uploadedFileList_title = 'fileupload.files_list';
  @Input()
  uploadedFileList_noFiles = 'fileupload.no_files';
  @Input()
  fileList_createdDate = 'fileupload.createdDate';
  @Input()
  uploadingFileList_title = 'fileupload.add_files';
  @Input()
  uploadingFileList_drag = 'fileupload.label';
  @Input()
  uploadBtn_confirm = 'fileupload.upload_files';
  @Input()
  uploadedFileList_search = 'fileupload.inputSearch';
  @Input()
  fromDocServer = true;
  @Input()
  uploadBtn_cancel = 'fileupload.clear';
  @Input()
  overridedCSS: OverridedCSS;
  selectedFile: UploadedFile;
  filterListOptions: FilterListOptions;
  filterLinkedListOptions: FilterListOptions;
  tags = [];
  constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) platformId: any,
    renderer: Renderer2,
    el: ElementRef,
    sanitizer: DomSanitizer,
    zone: NgZone,
    httpClient: HttpClient,
    cd: ChangeDetectorRef,
    config: PrimeNGConfig,
    private docServerService: DocServerService,
    private messageService: MessageService,
    private translator: TranslateService
  ) {
    super(document, platformId, renderer, el, sanitizer, zone, httpClient, cd, config);
  }

  ngOnInit() {}

  getLogoFileSrc(file: any) {
    if (this.isImage(file)) {
      return { isUrl: true, url: file.objectURL };
    } else {
      const fileName = file.name ? file.name.toLowerCase() : '';
      if (
        fileName &&
        (fileName.endsWith('.png') ||
          fileName.endsWith('.jpg') ||
          fileName.endsWith('.jpeg') ||
          fileName.endsWith('.gif') ||
          fileName.endsWith('.bmp') ||
          fileName.endsWith('.ico') ||
          fileName.endsWith('.webp') ||
          fileName.endsWith('.svg'))
      ) {
        const urlPreview = this.fromDocServer ? this.docServerService.getUrlPreview(file.src) : file.src;
        return { isUrl: true, url: urlPreview };
      } else {
        let iconClass = 'icon-';
        if (fileName.endsWith('.csv')) {
          iconClass += 'csv';
        } else if (fileName.endsWith('.txt')) {
          iconClass += 'txt';
        } else if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
          iconClass += 'word';
        } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
          iconClass += 'excel';
        } else if (fileName.endsWith('.pdf')) {
          iconClass += 'pdf';
        } else if (fileName.endsWith('.html')) {
          iconClass += 'html';
        } else if (fileName.endsWith('sql')) {
          iconClass += 'sql';
        } else if (fileName.endsWith('zip')) {
          iconClass += 'zip';
        } else {
          iconClass += 'file';
        }

        return { isUrl: false, iconClass };
      }
    }
  }

  addFile(event: Event) {
    event.stopPropagation();
    // simule un click sur le input file.
    this.advancedfileinput.nativeElement.click();
  }

  remove(event: Event, i: number) {
    // retirer un fichier de la liste des fichiers à uploadé.
    event.stopPropagation();
    this.tags.splice(i, 1);
    super.remove(event, i);
  }

  /**
   * Demande au parent l'upload des fichiers de la liste
   */
  onClickUploadFiles() {
    this.uploadFiles.emit({ files: this.files, tags: this.tags });
  }

  /**
   * Appellé par le parent (input-file-ui) pour retirer un fichier de la liste
   * @param {File} file
   */
  removeFile(file: File) {
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.tags.splice(index, 1);
  }

  /**
   * lance l'emitter pour le téléchargement du fichier
   * @param {Event} event
   * @param {string} docId
   * @param {index} index
   */
  downloadFile(event: Event, file: UploadedFile, index: number) {
    event.stopPropagation();
    this.downloadEmitter.emit({ file: file });
  }
  /**
   * Affiche les autres tags disponibles pour un fichier
   * @param {Event} event
   * @param {string} docId
   * @param {index} index
   */
  showMoreTags(event: Event, file: UploadedFile) {
    event.stopPropagation();
    this.downloadEmitter.emit({ file: file });
  }
  /**
   * lance la copie du lien du fichier
   * @param {Event} event
   * @param {string} docId
   * @param {index} index
   */
  copyLinkFile(event: Event, file: UploadedFile, index: number) {
    event.stopPropagation();
    const urlPreview = this.fromDocServer ? this.docServerService.getUrlPreview(file.src as string) : file.src;
    EditViewUtils.copyToClipboard(urlPreview as string);
    this.messageService.add({
      key: 'quick-toast',
      severity: 'success',
      summary: this.translator.instant('generic.success'),
      detail: this.translator.instant('joinFiles.tooltip_copied'),
      life: 1500,
      closable: false,
    });
  }

  /**
   * Lance l'emitter pour la suppression du fichier
   * @param {Event} event
   * @param {string} docId
   * @param {index} index
   */
  deleteFile(event: Event, file: UploadedFile, index: number) {
    event.stopPropagation();
    this.deleteEmitter.emit({ file, index });
  }

  onFileSelect(event) {
    let files: FileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.tags.push([]);
    }
    super.onFileSelect(event);
    this.fileSelectEmitter.emit(event);
  }

  onPreview(event, file: any, index: number) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    if (this.docServerService.hasPreview(file)) {
      const fileName = file.extension
        ? (file.name as string).endsWith(`.${file.extension.replace(/^\./, '')}`)
          ? file.name
          : `${file.name}.${file.extension}`
        : file.name;
      this.previewDocComponent.onPreview(
        event,
        fileName,
        file.src || this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file))
      );
    }
  }
  updateFilterOptions(event: any, inputValue: string, src: string) {
    const taggedProperties = ['name'];
    this.displayedTags.forEach((tag) => {
      taggedProperties.push('tags.META|' + tag.columnName.toUpperCase());
      taggedProperties.push('tags.META|' + tag.columnName.toUpperCase() + '$');
    });
    if (src === 'files') {
      this.filterListOptions = {
        objProperties: taggedProperties,
        operator: FilterListOperator.CONTAINS,
        value: inputValue,
      };
    }
    if (src == 'linkedFiles') {
      this.filterLinkedListOptions = {
        objProperties: taggedProperties,
        operator: FilterListOperator.CONTAINS,
        value: inputValue,
      };
    }
  }

  openFileOnSide(event: Event, file, index: number) {
    event.stopPropagation();
    if (this.docServerService.hasPreview(file)) {
      const fileName = file.extension
        ? (file.name as string).endsWith(`.${file.extension.replace(/^\./, '')}`)
          ? file.name
          : `${file.name}.${file.extension}`
        : file.name;
      this.openFileOnSideEmitter.emit({
        fileName: fileName,
        urlFile: file.src || this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file)),
      });
    }
  }
}
