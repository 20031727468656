import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  CompiereDataFieldType,
  CompiereDataGridFilterType,
  CompiereDataGridType,
  DataStore,
} from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import SpecificWindowUiComponent from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import TextFieldUiComponent from '@iupics-components/standard/fields/text-field-ui/text-field-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { ProcessParams } from '../process-ui/process-ui.component';
import { BadgeButtonData } from './components/badge-button/badge-button-data';
import BadgeButtonComponent from './components/badge-button/badge-button.component';
import { CustomerProfileData } from './components/customer-profile/customer-profile-data';
import CustomerProfileComponent from './components/customer-profile/customer-profile.component';
import { QuickActionData, QuickActionSubData, QuickActionType } from './components/quick-action/quick-action-data';
import QuickActionComponent from './components/quick-action/quick-action.component';
import { StatisticsCardData, StatisticsCardType } from './components/statistics-card/statistics-card-data';
import StatisticsCardComponent from './components/statistics-card/statistics-card.component';

@Component({
  selector: 'iu-customer-360',
  templateUrl: './customer-360.component.html',
  styleUrls: ['./customer-360.component.scss'],
  standalone: true,
  imports: [
    CustomerProfileComponent,
    NgFor,
    BadgeButtonComponent,
    NgIf,
    QuickActionComponent,
    StatisticsCardComponent,
    TextFieldUiComponent,
    NgClass,
    TranslateModule,
  ],
})
export default class Customer360Component extends SpecificWindowUiComponent implements OnInit {
  private BADGES_TABLE_NAMES = ['R_Request', 'C_Order', 'C_Payment', 'M_InOut', 'C_Invoice']; //! Order is important
  private STATS_COLUMN_NAMES = ['RV_YTD_Earnings', 'RV_MTD_Earnings', 'RV_Opportunities', 'RV_Offers'];
  private QUICK_ACTIONS_SUB_DATA_COLUMN_NAMES = ['C_DocType_ID', 'XX_ActionType_ID'];

  /* Data */
  C_BPartner_ID: number;
  customer: CustomerProfileData;
  specificCustomerData: any;
  lastRefreshData: string;
  isSync = true;

  badges: BadgeButtonData[];
  statistics: StatisticsCardData[] = [];
  quickActions: QuickActionData[] = [];
  quickActionsProcess: QuickActionData[] = [];
  quickActionsSubActionsFields: any[] = [];
  textfields: any[] = [];
  calculatedIndicator: any;
  @ViewChild('calculatedIndicatorRef') calculatedIndicatorRef: TextFieldUiComponent;

  adLanguage: string;
  currencyFormatter: Intl.NumberFormat;

  constructor(
    protected windowFactory: WindowFactoryService,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected progressService: ProcessInProgressService,
    protected translateService: TranslateService
  ) {
    super(
      windowFactory,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    // edit view refresh
    this.subscriptions.push((<DataStore>this.parentTab.dataStored).dataChange.subscribe((data) => this.getData(true)));
    this.getData(true);
    this.adLanguage = this.connectorService.getIupicsUserContext()['#AD_Language'].slice(0, 2);
  }

  onSiblingUpdate(event: IupicsEvent): void {
    // on row selection
    this.syncData();
    this.DOMChildrenComponent.forEach((child) => {
      child.onSiblingUpdate(event);
    });
  }

  /* Data */
  getLoadingData() {
    this.customer = {
      fullName: null,
      type: null,
      isForbidden: null,
      rating: {
        value: null,
      },
      profilePicture: null,
      top: null,
    };
    this.badges = [
      {
        label: this.translateService.instant('specificWindow.customer360.badgeActions'),
        value: null,
        rule: null,
      },
      {
        label: this.translateService.instant('specificWindow.customer360.badgeOrders'),
        value: null,
        rule: null,
      },
      {
        label: this.translateService.instant('specificWindow.customer360.badgePayments'),
        value: null,
        rule: null,
      },
      {
        label: this.translateService.instant('specificWindow.customer360.badgeExpeditions'),
        value: null,
        rule: null,
      },
      {
        label: this.translateService.instant('specificWindow.customer360.badgeInvoices'),
        value: null,
        rule: null,
      },
    ];
    this.statistics = [
      {
        type: StatisticsCardType.NORMAL,
        label: null,
        amount: null,
        pct: null,
      },
      {
        type: StatisticsCardType.NORMAL,
        label: null,
        amount: null,
        pct: null,
      },
      {
        type: StatisticsCardType.COUNT,
        label: null,
        amount: null,
        pct: null,
        count: null,
      },
      {
        type: StatisticsCardType.COUNT,
        label: null,
        amount: null,
        pct: null,
        count: null,
      },
    ];
    this.quickActions = [
      {
        // Opportunity
        type: QuickActionType.NEWRECORD,
        label: this.translateService.instant('specificWindow.customer360.quickOpportunity'),
        action: (target: QuickActionSubData) => {
          this.zoomAcross('XX_Opportunity', {
            openNewRecord: true,
            zoomCtx: {
              C_BPartner_ID: this.C_BPartner_ID,
            },
          });
        },
      },
      {
        // Action
        type: QuickActionType.NEWRECORD,
        label: this.translateService.instant('specificWindow.customer360.quickAction'),
        subActionsDataQuery: {
          columnName: 'XX_ActionType_ID',
        },
        action: (target: QuickActionSubData) => {
          this.zoomAcross('R_Request', {
            openNewRecord: true,
            zoomCtx: {
              C_BPartner_ID: this.C_BPartner_ID,
            },
          });
        },
      },
      {
        // Command
        type: QuickActionType.NEWRECORD,
        label: this.translateService.instant('specificWindow.customer360.quickCommand'),
        subActionsDataQuery: {
          columnName: 'C_DocType_ID',
          context: {
            IsSOTrx: 'Y',
            IsReturnTrx: 'N',
            IsReleaseDocument: 'N',
            isQuote: 'N',
          },
        },
        action: (target: QuickActionSubData) => {
          this.zoomAcross('C_Order', {
            openNewRecord: true,
            zoomCtx: {
              C_BPartner_ID: this.C_BPartner_ID,
              C_DocTypeTarget_ID: target.id,
            },
          });
        },
      },
      {
        // Offer
        type: QuickActionType.NEWRECORD,
        label: this.translateService.instant('specificWindow.customer360.quickOffer'),
        action: (target: QuickActionSubData) => {
          this.zoomAcross('C_RfQ', {
            openNewRecord: true,
            zoomCtx: {
              C_BPartner_ID: this.C_BPartner_ID,
            },
          });
        },
      },
    ];
    this.quickActionsProcess = [
      {
        // Refresh
        type: QuickActionType.PROCESS,
        label: this.translateService.instant('specificWindow.customer360.quickRefreshData'),
        readonly: false,
      },
    ];
    this.calculatedIndicator = {
      label: this.translateService.instant('specificWindow.customer360.calculatedIndicator'),
      value: null,
    };
  }

  getData(refresh = false) {
    if (refresh) {
      this.getLoadingData();
    }

    const { data: dataStored } = this.parentTab.dataStored;

    this.setSpecificData();

    this.customer = {
      fullName: dataStored.Name,
      profilePicture: dataStored?.ZCreditMessage ?? '',
      type: 'client',
      isForbidden: dataStored?.SOCreditStatus?.id === 'S' ?? false,
      rating: dataStored?.RatingPct ?? 0,
      top: -1,
    };

    if (!dataStored?.['C_BPartner_ID']) {
      return;
    }

    this.C_BPartner_ID = dataStored['C_BPartner_ID'];

    const sub = this.parentTab.store
      .getDataGrid({
        windowId: undefined,
        parent_constraint: null,
        compiereRequest: {
          windowType: CompiereDataGridType.TABLE,
          tableName: this.specificCustomerData?.data?.columnName,
          filterModel: {
            C_BPartner_ID: {
              filterType: CompiereDataGridFilterType.SET,
              operators: [OperatorFilterType.EQUALS],
              values: [this.C_BPartner_ID],
            },
          },
        },
      })
      .subscribe((res) => {
        const data = res?.data?.[0] ?? null;
        this.customer.top = data?.['top'] ?? -1;
        this.setCalculatedField(data);
        this.setBadgeButtonsData(data);
        this.setCurrencyFormatter(data);
        this.setFields();
        this.setQuickActions();
        this.setLastRefreshData(data?.['LastRefresh'] ?? null);
        sub.unsubscribe();
      });
  }

  setSpecificData() {
    this.specificCustomerData = this.fields[0];
    this.quickActionsProcess[0].processId = this.fields[1].processId;
  }

  setFields() {
    this.textfields = []; // reset textfields

    for (const field of this.fields.slice(2)) {
      if (this.STATS_COLUMN_NAMES.includes(field.data.columnName)) {
        const index = this.STATS_COLUMN_NAMES.indexOf(field.data.columnName);
        this.statistics[index] = {
          ...this.statistics[index],
          ...field,
        };
      } else if (this.QUICK_ACTIONS_SUB_DATA_COLUMN_NAMES.includes(field.data.columnName)) {
        this.quickActionsSubActionsFields.push(field);
      } else {
        this.textfields.push({
          formatter: field.data.columnName === 'FlatDiscount' ? undefined : this.currencyFormatter,
          field,
        });
      }
    }
  }

  setCalculatedField(data: any) {
    const daysFormatter = Intl.NumberFormat(this.adLanguage);
    this.calculatedIndicator = {
      ...this.calculatedIndicator,
      value: data?.paymentaveragedays ?? 0,
      displayValue: `${daysFormatter.format(data?.paymentaveragedays ?? 0)} ${this.translateService.instant(
        'calendar.unit.day'
      )}`,
    };
    this.calculatedIndicatorRef.initTooltip({
      description: this.specificCustomerData.data.description,
      help: this.specificCustomerData.data.help,
    });
  }

  setCurrencyFormatter(data: any) {
    const currency = data?.currency ?? this.connectorService.getIupicsUserContext()['$CurrencyISO'];
    this.currencyFormatter = Intl.NumberFormat(this.adLanguage, { currency, style: 'currency' });
  }

  setBadgeButtonsData(data: any) {
    if (!data || data?.error) {
      this.badges = this.badges.map((badge) => {
        return {
          ...badge,
          rule: 1,
          value: 0,
        };
      });
      return;
    }

    this.badges = [
      {
        // Actions
        ...this.badges[0],
        value: data?.requestnottraited ?? 0,
        rule: data?.requestafterendtime > 0 ? -1 : data?.requestcloseendtime > 0 ? 0 : 1,
      },
      {
        // Orders
        ...this.badges[1],
        value: data?.orderopen ?? 0,
        rule: data?.ordernotbilled > 0 || data?.orderafterdatepromised > 0 ? -1 : 1,
      },
      {
        // Payments
        ...this.badges[2],
        value: data?.paymentnotallocated ?? 0,
        rule: data?.paymentnotallocatedafterduedate > 0 ? -1 : data?.paymentnotallocatedbeforeduedate > 0 ? 0 : 1,
      },
      {
        // Expeditions
        ...this.badges[3],
        value: data?.expeditionopen ?? 0,
        rule: data?.expeditionopenafterdatepromised > 0 ? -1 : 1,
      },
      {
        // Invoices
        ...this.badges[4],
        value: data?.invoicenotpaid ?? 0,
        rule: data?.invoicenotpaidafterduedate > 0 ? -1 : data?.invoicenotpaidbeforeduedate > 0 ? 0 : 1,
      },
    ];
  }

  setQuickActions() {
    this.quickActionsSubActionsFields.forEach((field) => {
      const index = this.quickActions.findIndex(
        (qa: QuickActionData) => qa?.subActionsDataQuery?.columnName === field.data?.columnName
      );
      let validationCode = null;
      if (field.data?.validationCode) {
        const context = this.quickActions?.[index]?.subActionsDataQuery.context ?? {};
        validationCode = LogicEvaluator.replaceVariables(
          field.data.validationCode,
          this.connectorService.getIupicsUserContext(),
          context
        );
      }

      this.parentTab.store
        .getAutocompleteData(CompiereDataFieldType.FORM_ITEM, field.formDetailItemId, true, null, validationCode)
        .subscribe((dataWs) => {
          this.quickActions[index].subActions = dataWs ?? [];
        });
    });
  }

  setLastRefreshData(date: string) {
    if (!date) {
      return;
    }

    this.lastRefreshData = new Intl.DateTimeFormat(this.adLanguage, { timeStyle: 'short', dateStyle: 'long' }).format(
      new Date(date)
    );
  }

  doQuickActionProcess(data: QuickActionData) {
    const ad_process_id = data.processId ?? -1;
    if (ad_process_id === -1) {
      return;
    }

    const params: ProcessParams = {
      ad_process_id,
      className: null,
      record_id: null,
      tableName: null,
      tables: null,
      params: {},
      windowCtx: this.getCurrentContext(),
      ad_tab_id: null,
    };

    const me = this.connectorService.getIupicsUserAccount();
    const qap = this.quickActionsProcess.find((p) => p.processId === ad_process_id);
    if (!qap) {
      return;
    }

    qap.readonly = true;
    const processSub = this.processService.executeProcess(params).subscribe(() => {
      const progressSub = this.progressService.watchProcessInProgress().subscribe((pings) => {
        const ping = pings.find((p) => p.AD_User_ID.id === me.id && p.AD_Process_ID.id === ad_process_id);
        if (ping && ping.Status === 'finish') {
          this.isSync = false;
          qap.readonly = false;
          processSub.unsubscribe();
          progressSub.unsubscribe();
        }
      });
    });
  }

  syncData() {
    this.getData(true);
    this.isSync = true;
  }

  /* Zoom Across */
  zoomAcross(
    tableName: string,
    options: { record_id?: any; dataGridRequest?: any; openNewRecord?: boolean; zoomCtx?: any }
  ) {
    // const isSOTrx = this.parentTab?.dataStored?.data?.IsSOTrx === 'Y' ?? undefined;
    this.subscriptions.push(
      this.uiCreator.zoomAcross(tableName, tableName + '_ID', options?.record_id ?? null, true).subscribe({
        next: (dataWs) => {
          if (dataWs && dataWs.length > 0) {
            this.zoomInfo = {
              // tableName: this.data.detailZoom.tableName,
              windowId: dataWs[0].Window_ID,
              dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
              record_id: options?.record_id ?? null,
              children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null,
              isInCurrentWindow: true,
            };

            const source = {
              id: null,
              menuType: IupicsMenuType.WINDOW,
            };

            if (options?.record_id) {
              source.id = dataWs[0]?.Record_ID ?? null;
            }

            if (options?.openNewRecord) {
              source.id = 'newRecord';
            }

            Global.workspace.urlParams.dataGridRequest = options?.dataGridRequest ?? null;
            Global.workspace.openTargetSearch({
              zoomInfo: this.zoomInfo,
              cat: { id: parseInt(dataWs[0].Window_ID, 10) },
              source,
              ctx: options?.zoomCtx ?? null,
            });
          }
        },
      })
    );
  }

  badgeButtonClick(index: number) {
    const tableName = this.BADGES_TABLE_NAMES[index];
    if (tableName) {
      const dataGridRequest = {
        filterModel: {
          C_BPartner_ID: {
            filterType: CompiereDataGridFilterType.SET,
            operators: [OperatorFilterType.EQUALS],
            values: [this.parentTab.dataStored.data['C_BPartner_ID']],
          },
        },
      };

      switch (index) {
        case 0: // Actions
          dataGridRequest.filterModel['R_Status_ID'] = {
            filterType: CompiereDataGridFilterType.SET,
            operators: [OperatorFilterType.EQUALS],
            values: [1000204],
          };
          break;
        case 1: // Orders
          dataGridRequest.filterModel['DocStatus'] = {
            filterType: CompiereDataGridFilterType.SET,
            operators: [OperatorFilterType.NOT_EQUALS],
            values: [['IN', 'VO', 'CL', 'DR']],
          };
          break;
        case 2: // Payments
          dataGridRequest.filterModel['IsAllocated'] = {
            filterType: CompiereDataGridFilterType.TEXT,
            operators: [OperatorFilterType.NOT_EQUALS],
            values: ['Y'],
          };
          break;
        case 3: // Expeditions
          dataGridRequest.filterModel['DocStatus'] = {
            filterType: CompiereDataGridFilterType.SET,
            operators: [OperatorFilterType.EQUALS],
            values: ['IP'],
          };
          break;
        case 4: // Invoices
          dataGridRequest.filterModel['IsPaid'] = {
            filterType: CompiereDataGridFilterType.TEXT,
            operators: [OperatorFilterType.NOT_EQUALS],
            values: ['Y'],
          };
          break;
        default:
          break;
      }

      this.zoomAcross(tableName, { dataGridRequest });
    }
  }

  goToEditCustomer() {
    this.zoomAcross('C_BPartner', { record_id: this.parentTab.dataStored.data['C_BPartner_ID'] });
  }
}
