import { NgClass, NgIf } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DynamicContainerDirective } from '../../../../iupics-util/directives/dynamic-container.directive';
import ButtonUiComponent from '../../../standard/fields/button-ui/button-ui.component';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
@Component({
  selector: 'iu-loginas-form',
  templateUrl: './loginas-form.component.html',
  styleUrls: ['./loginas-form.component.scss'],
  standalone: true,
  imports: [ScrollPanelModule, DynamicContainerDirective, NgClass, NgIf, ButtonUiComponent, TranslateModule],
})
export default class LoginasFormComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  ID_USER_STD = 'Value';
  identifiant = this.ID_USER_STD;
  constructor(
    windowFactory: WindowFactoryService,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService,
    private config: AppConfig
  ) {
    super(
      windowFactory,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = true;
  }
  ngOnInit() {
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        switch (item.data.columnName) {
          case 'AD_Client_ID':
            this.customDesignArray.push({
              vcr: 'iuDynamicContainer',
              type: CustomDesignItemType.FIELD,
              columnName: 'AD_Client_ID',
              cssClass: Global.isMobileWidth() ? 'p-col-12' : 'p-col-12 p-md-6 p-lg-4',
            });
            break;

          case 'Name':
            this.customDesignArray.push({
              vcr: 'iuDynamicContainer',
              type: CustomDesignItemType.FIELD,
              columnName: 'Name',
              cssClass: Global.isMobileWidth() ? 'p-col-12' : 'p-col-12 p-md-6 p-lg-4',
            });
            break;

          case 'LoginAs':
            this.customDesignArray.push({
              vcr: 'vcrButtons',
              type: CustomDesignItemType.FIELD,
              columnName: 'LoginAs',
              cssClass: Global.isMobileWidth() ? 'p-col-12' : 'p-col-6 p-md-4 p-lg-3',
            });
            break;
          default:
            if (item.component === 'GridViewUiComponent') {
              this.customDesignArray.push({
                vcr: 'iuDynamicContainer',
                type: CustomDesignItemType.GRID,
                tableName: item.name,
                cssClass: 'p-col-12',
                shouldSelectFirst: false,
                frameworkComponents: this.frameworkComponents,
                isFitResize: true,
                rowSelection: 'single',
                suppressRowClickSelection: false,
              });
            }
            break;
        }
      });
      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'p-col-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false,
      };

      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item,
      });
    });
    if (this.config.getConstant('identifiant')) {
      this.identifiant = this.config.getConstant('identifiant');
    }
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  processLoginAs() {
    if (
      this.dataStore &&
      this.dataStore.data &&
      this.dataStore.data['selections'] &&
      this.dataStore.data['selections'][0]['selection'].length > 0
    ) {
      if (this.identifiant !== this.ID_USER_STD) {
        const userRequest: DataStoreRequest = {
          windowId: null,
          parent_constraint: '',
          compiereRequest: {
            startRow: 0,
            endRow: 0,
            tableName: 'AD_USER',
            filterModel: {
              AD_USER_ID: {
                filterType: CompiereDataGridFilterType.SET,
                values: [
                  this.dataStore.data['selections'] &&
                    this.dataStore.data['selections'][0]['selection'][0]['AD_User_ID'],
                ],
                operators: [OperatorFilterType.EQUALS],
              },
            },
          },
        };
        this.store.getDataGrid(userRequest).subscribe((res) => {
          if (res && res.data && res.data.length > 0) {
            this.connect(res.data[0][this.identifiant.toUpperCase()]);
          }
        });
      } else {
        this.connect(
          this.dataStore.data['selections'] && this.dataStore.data['selections'][0]['selection'][0][this.ID_USER_STD]
        );
      }
    }
  }
  connect(login: string) {
    // this.connectorService.loginAs(login).subscribe((resultToken) => {
    //   const baseHref = document.getElementsByTagName('base')[0].href;
    //   window.history.pushState({}, '', `${baseHref}dashboard`);
    //   location.reload();
    // });
  }
}
