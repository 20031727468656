<div *ngIf="displayFileUploaded" class="file_list">
  <div *ngIf="uploadedFiles.length === 0; else uploadedFilesList" [style.font-size]="overridedCSS?.inputFontSize">
    <p-progressSpinner *ngIf="isLoaderActive; else noFileMessage"></p-progressSpinner>
    <ng-template #noFileMessage> {{ uploadedFileList_noFiles | translate }} </ng-template>
  </div>
  <ng-template #uploadedFilesList>
    <table class="uploadedFiles" aria-label="uploaded files table">
      <thead>
        <tr class="file_list_header">
          <th colspan="2" scope="colgroup">
            <span [style.font-size]="overridedCSS?.labelFontSize">{{ uploadedFileList_title | translate }}</span>
          </th>

          <th scope="col">{{ fileList_createdDate | translate }}</th>
          <ng-container *ngIf="displayedTags">
            <th *ngFor="let tag of displayedTags | slice: 0 : 4" scope="col">
              {{ tag.colLabel }}
            </th>
          </ng-container>
          <th scope="col">
            <input
              #filterInput
              type="text"
              class="file_upload_input_search"
              (keyup)="updateFilterOptions($event, filterInput.value, 'files')"
              placeholder="{{ uploadedFileList_search | translate }}"
              value=""
              width="10vw" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let file of uploadedFiles | filterList: filterListOptions; let i = index">
          <td>
            <ng-template #fileLogo let-fileItem="fileItem">
              <img
                *ngIf="fileItem && fileItem.isUrl"
                class="fileUpload-logo"
                [src]="fileItem.url | docServerUrl | async"
                [width]="previewWidth"
                (click)="onPreview($event, file, i)"
                data-cy="previewFile"
                alt="file preview" />
              <i
                *ngIf="fileItem && !fileItem.isUrl"
                [ngClass]="['fileUpload-icon', fileItem.iconClass ?? '']"
                aria-hidden="true"
                data-cy="previewFile"
                alt="file preview"></i>
            </ng-template>
            <ng-container *ngTemplateOutlet="fileLogo; context: { fileItem: getLogoFileSrc(file) }"></ng-container>
          </td>
          <td
            class="fileUpload-name"
            [style.font-size]="overridedCSS?.inputFontSize"
            (click)="onPreview($event, file, i)">
            {{ file.name }}
          </td>

          <td>
            <div *ngIf="file.createdDate" class="taggedValue">
              <span>{{ file.createdDate }}</span>
            </div>
          </td>
          <ng-container *ngIf="displayedTags">
            <td *ngFor="let tag of displayedTags | slice: 0 : 4" class="taggedValue">
              <div *ngIf="file && file.tags && file.tags['META|' + tag.columnName.toUpperCase()]" class="taggedValue">
                <span
                  [title]="
                    (file.tags['META|' + tag.columnName.toUpperCase() + '$']
                      ? file.tags['META|' + tag.columnName.toUpperCase() + '$']
                      : file.tags['META|' + tag.columnName.toUpperCase()]
                    ) | docServerFormatValue: tag.ad_reference_id
                  ">
                  {{
                    (file.tags['META|' + tag.columnName.toUpperCase() + '$']
                      ? file.tags['META|' + tag.columnName.toUpperCase() + '$']
                      : file.tags['META|' + tag.columnName.toUpperCase()]
                    ) | docServerFormatValue: tag.ad_reference_id
                  }}</span
                >
              </div>
            </td>
          </ng-container>
          <td>
            <button
              pButton
              *ngIf="displayedTags && displayedTags.length > 4"
              icon="fas fa-info"
              [title]="'fileupload.moreTags' | translate"
              (click)="selectedFile = file; op.toggle($event)"
              class=""></button>
            <button
              pButton
              *ngIf="canDownload"
              icon="fa fa-download"
              [title]="'generic.download' | translate"
              (click)="downloadFile($event, file, i)"
              class=""></button>
            <button
              pButton
              icon="fa fa-table-columns"
              [title]="'generic.openFileOnSide' | translate"
              (click)="openFileOnSide($event, $any(file), i)"
              data-cy="openFileOnSide"></button>
            <button
              pButton
              class="fileUpload-copyLink"
              icon="fa fa-link"
              [title]="'generic.copyLink' | translate"
              (click)="copyLinkFile($event, file, i)"
              data-cy="copyLinkFile"></button>
            <button
              pButton
              *ngIf="canDelete && (file.isDeletable === undefined || file.isDeletable)"
              icon="fa fa-trash"
              (click)="isReadOnly ? null : deleteFile($event, file, i)"
              [title]="'generic.delete' | translate"
              data-cy="deleteFile"
              class="p-button p-button-alert"></button>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
</div>
<div
  *ngIf="displayFileUploaded && linkedFiles.length > 0"
  class="file_list linked_file"
  data-cy="fileupload-linkedfile-list">
  <div *ngIf="linkedFiles.length === 0; else linkedFilesList" [style.font-size]="overridedCSS?.inputFontSize">
    <p-progressSpinner *ngIf="isLoaderActive; else noFileMessage"></p-progressSpinner>
    <ng-template #noFileMessage> {{ uploadedFileList_noLinkedFiles | translate }} </ng-template>
  </div>
  <ng-template #linkedFilesList>
    <table class="linkedFiles" aria-label="linked files table">
      <thead>
        <tr class="file_list_header">
          <th colspan="2" scope="colgroup">
            <span [style.font-size]="overridedCSS?.labelFontSize">{{ linkedFileList_title | translate }}</span>
          </th>

          <th scope="col">{{ fileList_createdDate | translate }}</th>
          <ng-container *ngIf="displayedTags">
            <th *ngFor="let tag of displayedTags | slice: 0 : 4" scope="col">
              {{ tag.colLabel }}
            </th>
          </ng-container>
          <th scope="col">
            <input
              #filterLinkedInput
              type="text"
              class="file_upload_input_search"
              (keyup)="updateFilterOptions($event, filterLinkedInput.value, 'linkedFiles')"
              value=""
              placeholder="{{ uploadedFileList_search | translate }}"
              width="10vw" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let file of linkedFiles | filterList: filterLinkedListOptions; let i = index"
          [attr.data-cy-index]="i"
          data-cy="fileupload-linkedfile">
          <td>
            <ng-template #fileLogo let-fileItem="fileItem">
              <img
                *ngIf="fileItem && fileItem.isUrl"
                class="fileUpload-logo"
                [src]="fileItem.url | docServerUrl | async"
                [width]="previewWidth"
                (click)="onPreview($event, file, i)"
                data-cy="previewFile"
                alt="file preview" />
              <i
                *ngIf="fileItem && !fileItem.isUrl"
                [ngClass]="['fileUpload-icon', fileItem.iconClass ?? '']"
                aria-hidden="true"
                data-cy="previewFile"
                alt="file preview"></i>
            </ng-template>
            <ng-container *ngTemplateOutlet="fileLogo; context: { fileItem: getLogoFileSrc(file) }"></ng-container>
          </td>
          <td
            class="fileUpload-name"
            [style.font-size]="overridedCSS?.inputFontSize"
            (click)="onPreview($event, file, i)">
            {{ file.name }}
          </td>
          <td>
            <div *ngIf="file.createdDate" class="taggedValue">
              <span>{{ file.createdDate }}</span>
            </div>
          </td>
          <ng-container *ngIf="displayedTags">
            <td *ngFor="let tag of displayedTags | slice: 0 : 4" class="taggedValue">
              <div *ngIf="file && file.tags && file.tags['META|' + tag.columnName.toUpperCase()]" class="taggedValue">
                <span
                  [title]="
                    (file.tags['META|' + tag.columnName.toUpperCase() + '$']
                      ? file.tags['META|' + tag.columnName.toUpperCase() + '$']
                      : file.tags['META|' + tag.columnName.toUpperCase()]
                    ) | docServerFormatValue: tag.ad_reference_id
                  ">
                  {{
                    (file.tags['META|' + tag.columnName.toUpperCase() + '$']
                      ? file.tags['META|' + tag.columnName.toUpperCase() + '$']
                      : file.tags['META|' + tag.columnName.toUpperCase()]
                    ) | docServerFormatValue: tag.ad_reference_id
                  }}</span
                >
              </div>
            </td>
          </ng-container>

          <td>
            <button
              pButton
              *ngIf="displayedTags && displayedTags.length > 4"
              icon="fas fa-info"
              [title]="'fileupload.moreTags' | translate"
              (click)="selectedFile = file; op.toggle($event)"></button>
            <button
              pButton
              *ngIf="canDownload"
              icon="fa fa-download"
              (click)="downloadFile($event, file, i)"
              data-cy="downloadFile"></button>
            <button
              pButton
              icon="fa fa-table-columns"
              [title]="'generic.openFileOnSide' | translate"
              (click)="openFileOnSide($event, file, i)"
              data-cy="openFileOnSide"></button>
            <button
              pButton
              class="fileUpload-copyLink"
              icon="fa fa-link"
              (click)="copyLinkFile($event, file, i)"
              data-cy="copyLinkFile"></button>
            <button
              pButton
              *ngIf="canDelete && (file.isDeletable === undefined || file.isDeletable)"
              icon="fa fa-trash"
              (click)="isReadOnly ? null : deleteFile($event, file, i)"
              data-cy="deleteFile"
              class="p-button p-button-alert"></button>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
</div>
<iu-prime-overlay
  #op
  appendTo="body"
  baseZIndex="2000"
  styleClass="fileUpload-overlayPanel"
  [showCloseIcon]="true"
  [style]="{ width: '450px' }">
  <ng-template pTemplate>
    <p-table [value]="displayedTags | slice: 4" [paginator]="true" [rows]="5">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col">{{ 'fileupload.tagName' | translate }}</th>
          <th scope="col">{{ 'fileupload.tagValue' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-displayedTag>
        <tr [pSelectableRow]="rowData">
          <td>{{ displayedTag.colLabel }}</td>
          <td>
            {{
              (selectedFile.tags['META|' + displayedTag.columnName.toUpperCase() + '$']
                ? selectedFile.tags['META|' + displayedTag.columnName.toUpperCase() + '$']
                : selectedFile.tags['META|' + displayedTag.columnName.toUpperCase()]
              ) | docServerFormatValue: displayedTag.ad_reference_id
            }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</iu-prime-overlay>
<div *ngIf="isReadOnly || canUpload">
  <h3 class="upload-list-title" style="margin-top: 0px">{{ uploadingFileList_title | translate }}</h3>
  <div [ngClass]="['ui-fileupload', styleClass || '']" [ngStyle]="style">
    <div
      #content
      [ngClass]="['ui-fileupload-content ui-corner-bottom']"
      (dragenter)="onDragEnter($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      (click)="addFile($event)"
      data-cy="fileupload-click-or-drop-zone">
      <p-messages [value]="msgs" [enableService]="false"></p-messages>
      <input
        #advancedfileinput
        type="file"
        style="display: none"
        (change)="onFileSelect($event)"
        [multiple]="multiple"
        [readonly]="isReadOnly"
        (focus)="!isReadOnly ? onFocus() : null"
        [tabindex]="isReadOnly ? '-1' : null"
        (blur)="onBlur()"
        data-cy="fileupload-input-file" />
      <div *ngIf="!hasFiles(); else notEmpty" class="ui-fileupload-label">
        <i class="fas fa-cloud-upload-alt fa-4x" aria-hidden="true"></i>
        <p>{{ uploadingFileList_drag | translate }}</p>
      </div>
      <ng-template #notEmpty>
        <div class="ui-fileupload-files">
          <table class="ui-fileupload-table" aria-label="files upload table">
            <tr *ngFor="let file of files; let i = index">
              <td *ngIf="uploadingFiles.indexOf(file) !== -1">
                <p-progressSpinner></p-progressSpinner>
              </td>
              <td>
                <ng-template #fileLogo let-fileItem="fileItem">
                  <img
                    *ngIf="fileItem && fileItem.isUrl"
                    class="fileUpload-logo"
                    [src]="fileItem.url | docServerUrl | async"
                    [width]="previewWidth"
                    (click)="onPreview($event, $any(file), i)"
                    data-cy="previewFile"
                    alt="file preview" />
                  <i
                    *ngIf="fileItem && !fileItem.isUrl"
                    [ngClass]="['fileUpload-icon', fileItem.iconClass ?? '']"
                    aria-hidden="true"
                    data-cy="previewFile"
                    alt="file preview"></i>
                </ng-template>
                <ng-container *ngTemplateOutlet="fileLogo; context: { fileItem: getLogoFileSrc(file) }"></ng-container>
              </td>
              <th scope="row">
                <div
                  [ngStyle]="{
                    width: content | getPercentInPxCss: { of: 'clientWidth', many: 40 }
                  }"
                  [style.font-size]="overridedCSS?.inputFontSize"
                  class="prime-fileupload-filename">
                  {{ file.name }}
                </div>
              </th>
              <td>
                <iu-tags-editor-ui [(tags)]="tags[i]"></iu-tags-editor-ui>
              </td>
              <td>
                <button
                  type="button"
                  icon="fa fa-trash"
                  pButton
                  (click)="remove($event, i)"
                  *ngIf="uploadingFiles.indexOf(file) === -1"></button>
              </td>
            </tr>
          </table>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="ui-fileupload-button-container" *ngIf="!auto">
    <button
      pButton
      *ngIf="displayButtons"
      (click)="onClickUploadFiles()"
      [disabled]="uploadingFiles.length > 0"
      label="{{ uploadBtn_confirm | translate }}"
      class="p-button p-button-secondary"
      data-cy="fileupload-btn-confirm"></button>
    <button
      pButton
      *ngIf="displayButtons"
      (click)="clear()"
      [disabled]="uploadingFiles.length > 0"
      label="{{ uploadBtn_cancel | translate }}"
      class="p-button p-button-alert"
      data-cy="fileupload-btn-cancel"></button>
  </div>
</div>
<iu-preview-doc [modal]="true" [fromDocServer]="fromDocServer"></iu-preview-doc>
