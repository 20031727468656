import { AsyncPipe, NgFor } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'iu-process-logs-ui',
  templateUrl: './process-logs-ui.component.html',
  styleUrls: ['./process-logs-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgFor, AsyncPipe],
})
export default class ProcessLogsUiComponent implements OnInit {
  @ViewChild('logsContainer', { read: ElementRef, static: true }) logsContainer: ElementRef<HTMLDivElement>;

  private _logs = [];
  logs$: Observable<string[]> = of(this._logs);

  constructor() {}

  ngOnInit() {}

  addLog(...logs: string[]) {
    this._logs = [...this._logs, logs];
    this.logs$ = of(this._logs);
    if (this.logsContainer) {
      setTimeout(() => {
        this.gotoBottom();
      }, 50);
    }
  }

  clearLogs() {
    this._logs = [];
    this.logs$ = of(this._logs);
  }

  private gotoBottom() {
    this.logsContainer.nativeElement.scrollTop =
      this.logsContainer.nativeElement.scrollHeight - this.logsContainer.nativeElement.clientHeight;
  }
}
