import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import ProcessInProgressUiComponent from '../process-in-progress-ui/process-in-progress-ui.component';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-process-in-progress-window-ui',
  templateUrl: './process-in-progress-window-ui.component.html',
  styleUrls: ['./process-in-progress-window-ui.component.scss'],
  standalone: true,
  imports: [NgIf, ProgressBarModule, ScrollPanelModule, ProcessInProgressUiComponent],
})
export default class ProcessInProgressWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  constructor(
    protected windowFactory: WindowFactoryService,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    progressService: ProcessInProgressService,
    protected translateService: TranslateService
  ) {
    super(
      windowFactory,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }

  ngOnInit() {}
}
