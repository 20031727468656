import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  CompiereDataGridGroupModel,
  CompiereDataGridRequestJSON,
  CompiereDataGridType,
  DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import { ChartType } from '@iupics-components/models/view-type.enum';
import BladeUiComponent from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@web-desktop/controllers/theme.service';
import { ButtonModule } from 'primeng/button';
import { ChartModule, UIChart } from 'primeng/chart';
import { TooltipModule } from 'primeng/tooltip';
import { zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import AutocompleteUiComponent from '../../fields/autocomplete-ui/autocomplete-ui.component';
import GridViewUiComponent from '../grid-view-ui/grid-view-ui.component';

@Component({
  selector: 'iu-chart-ui',
  templateUrl: './chart-ui.component.html',
  styleUrls: ['./chart-ui.component.scss'],
  standalone: true,
  imports: [ButtonModule, TooltipModule, AutocompleteUiComponent, ChartModule, TranslateModule],
})
export default class ChartUiComponent extends AbstractDynamicComponent implements OnInit, AfterViewInit {
  options;
  chartType: string;
  datas = [];
  dataChart: any;
  @ViewChild('chart', { static: true })
  chart: UIChart;
  operationFieldValue: any = null;
  columns: ColumnFilter[] = [];
  selectedColumns;
  valueCols: CompiereDataGridGroupModel[];
  operation = 'sum';
  defaultColumn: ColumnFilter = null;
  tooltipLabels: any;
  @Input()
  initRequest: CompiereDataGridRequestJSON;
  @Input() filter: CompiereDataGridRequestJSON;
  @Output()
  setFilterEmitter = new EventEmitter<CompiereDataGridRequestJSON>();

  private isGetDatagridInProgress = false;

  constructor(
    private translateService: TranslateService,
    private store: DataStoreService,
    private uiCreator: UICreatorService,
    private themeService: ThemeService
  ) {
    super();
  }

  ngOnInit() {
    this.tooltipLabels = {
      pieChart: this.translateService.instant('chartUi.pieChart'),
      doughnutChart: this.translateService.instant('chartUi.doughnutChart'),
      polarChart: this.translateService.instant('chartUi.polarChart'),
      lineChart: this.translateService.instant('chartUi.lineChart'),
      areaChart: this.translateService.instant('chartUi.areaChart'),
      barChart: this.translateService.instant('chartUi.barChart'),
      columnChart: this.translateService.instant('chartUi.columnChart'),
      radarChart: this.translateService.instant('chartUi.radarChart'),
    };

    if (this.defaultColumn) {
      this.valueCols = [
        {
          id: this.defaultColumn.id,
          aggFunc: this.operation,
          displayName: this.defaultColumn.displayValue,
          field: this.defaultColumn.id,
        },
      ];
    } else {
      this.valueCols = [];
    }
    this.chartType = ChartType.PIE;
  }
  ngAfterViewInit() {
    this.getData();
  }
  getData() {
    if (this.isGetDatagridInProgress) {
      return;
    }
    const dataStoreRequest: DataStoreRequest = {
      windowId: (<BladeUiComponent>this.container).infoComponent
        ? (<BladeUiComponent>this.container).infoComponent.windowId
        : this.container.windowId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.tabId,
        startRow: 0,
        endRow: 25,
        windowCtx: this.getCurrentContext(),
        validation: this.getTabWhereclause(),
      },
    };
    if (this.initRequest) {
      this.filter = {};
      if (this.initRequest.filterModel) {
        this.filter.filterModel = { ...this.initRequest.filterModel };
      }
      if (this.initRequest.sortModel) {
        this.filter.sortModel = [...this.initRequest.sortModel];
      }
      if (this.initRequest.rowGroupCols) {
        this.filter.rowGroupCols = [...this.initRequest.rowGroupCols];
      }
      if (this.initRequest.valueCols) {
        if (this.initRequest.valueCols.length > 0) {
          this.valueCols = [...this.initRequest.valueCols];
          this.operation = this.valueCols[0].aggFunc;
          this.defaultColumn = { displayValue: this.valueCols[0].displayName, id: this.valueCols[0].id, type: 11 };
          this.operationFieldValue =
            this.valueCols[0].aggFunc === 'sum'
              ? { id: 'sum', displayValue: this.translateService.instant('gridTools.sum') }
              : { id: 'count', displayValue: this.translateService.instant('gridTools.count') };
        }
      }
    }
    if (this.filter) {
      dataStoreRequest.compiereRequest.filterModel = this.filter.filterModel;
      dataStoreRequest.compiereRequest.rowGroupCols = this.filter.rowGroupCols;
      dataStoreRequest.compiereRequest.sortModel = this.filter.sortModel;
    }
    dataStoreRequest.compiereRequest.valueCols = this.valueCols;
    if (this.initRequest) {
      this.initRequest = undefined;
      this.setFilterEmitter.emit(dataStoreRequest.compiereRequest);
    } else {
      this.datas = [];
      this.isGetDatagridInProgress = true;
      this.subscriptions.push(
        zip(this.store.getDataGrid(dataStoreRequest), this.uiCreator.getCompiereTab(this.tabId))
          .pipe(tap((_) => (this.isGetDatagridInProgress = false)))
          .subscribe(([dataWS, tab]) => {
            this.columns = tab.fields
              .filter((item) => {
                if (
                  item.field.AD_Reference_ID === 11 ||
                  item.field.AD_Reference_ID === 12 ||
                  item.field.AD_Reference_ID === 22 ||
                  item.field.AD_Reference_ID === 29 ||
                  item.field.AD_Reference_ID === 37
                ) {
                  return item;
                }
              })
              .map((item) => ({
                displayValue: item.field.Name,
                id: item.field.ColumnName,
                type: item.field.AD_Reference_ID,
              }));
            dataWS.data.forEach((row) => {
              this.datas.push(row);
            });
            this.selectedColumns = this.filter ? this.filter.rowGroupCols.map((rgc) => rgc.field) : [];
            this.transformData();
            if (this.container) {
              (<BladeUiComponent>this.container).notifyUrlChange();
            }
          })
      );
    }
  }
  swicthToPieChart() {
    this.chartType = ChartType.PIE;
    this.transformData();
  }

  swicthToLineChart() {
    this.chartType = ChartType.LINE;
    this.transformData();
  }
  swicthToAreaChart() {
    this.chartType = ChartType.AREA;
    this.transformData();
  }
  swicthToPolarChart() {
    this.chartType = ChartType.POLARAREA;
    this.transformData();
  }

  swicthToBarChart() {
    this.chartType = ChartType.BAR;
    this.transformData();
  }
  swicthToColumnChart() {
    this.chartType = ChartType.COLUMN;
    this.transformData();
  }

  swicthToDoughnutChart() {
    this.chartType = ChartType.DOUGHNUT;
    this.transformData();
  }

  swicthToRadarChart() {
    this.chartType = ChartType.RADAR;
    this.transformData();
  }

  changeOperationToCompute(operation: { id: string; displayValue: string }) {
    this.operation = operation.id;
    this.applyFilter(this.filter);
  }
  changeColumnToCompute(columnFilter: ColumnFilter) {
    const valueCol = this.columns.find(
      (column) =>
        column.id === columnFilter.id &&
        (column.type === 11 || column.type === 12 || column.type === 22 || column.type === 29 || column.type === 37)
    );
    if (valueCol) {
      this.defaultColumn = valueCol;
    } else {
      this.defaultColumn = null;
    }
    this.applyFilter(this.filter);
  }

  transformData() {
    const dataset = {};
    if (this.defaultColumn) {
      this.datas.forEach((data) => {
        if (this.selectedColumns && this.selectedColumns.length > 0) {
          let keys = '';
          this.selectedColumns.forEach((columnGroup) => {
            if (keys) {
              keys += '&|';
            }
            keys += data[columnGroup] instanceof Object ? data[columnGroup].displayValue : data[columnGroup];
          });
          if (data[this.defaultColumn.id] !== undefined && data[this.defaultColumn.id] !== null) {
            dataset[keys] = data[this.defaultColumn.id];
          }
        } else {
          const key = this.operation + '(' + this.defaultColumn.displayValue + ')';
          if (this.operation === 'sum') {
            if (!dataset[key]) {
              dataset[key] = data[this.defaultColumn.id];
            } else {
              dataset[key] += data[this.defaultColumn.id];
            }
          } else {
            if (!dataset[key]) {
              dataset[key] = 1;
            } else {
              dataset[key]++;
            }
          }
        }
      });
    }
    this.createDataChart(dataset);
  }

  createDataChart(dataset: {}) {
    const globalLabels = [];
    const globalLabelKeys = {};
    const datasets = [];
    const datasetsKey = {};
    const data = [];
    if (
      this.chartType === ChartType.LINE ||
      this.chartType === ChartType.BAR ||
      this.chartType === ChartType.COLUMN ||
      this.chartType === ChartType.AREA ||
      this.chartType === ChartType.RADAR
    ) {
      Object.keys(dataset).forEach((labels) => {
        const groupLevel = labels.split(/\&\|/g);
        if (groupLevel.length > 0) {
          if (globalLabelKeys[groupLevel[0]] === undefined) {
            const index = globalLabels.push(groupLevel[0]);
            globalLabelKeys[groupLevel[0]] = index - 1;
          }
          if (groupLevel.length > 1) {
            let dataSetLabel = '';
            for (let j = 1; j < groupLevel.length; j++) {
              if (j > 1) {
                dataSetLabel += ' - ';
              }
              dataSetLabel += groupLevel[j];
            }
            if (datasetsKey[dataSetLabel] === undefined) {
              const globalLabelsPresent = {};
              globalLabelsPresent[groupLevel[0]] = dataset[labels];
              const index = datasets.push({
                label: dataSetLabel,
                globalLabelsPresent: globalLabelsPresent,
                data: [],
                fill: this.chartType === ChartType.LINE ? false : true,
              });
              datasetsKey[dataSetLabel] = index - 1;
            } else {
              datasets[datasetsKey[dataSetLabel]].globalLabelsPresent[groupLevel[0]] = dataset[labels];
            }
          } else {
            if (datasets.length > 0) {
              // datasets[0].data.push(dataset[groupLevel[0]]);
              datasets[0].globalLabelsPresent[groupLevel[0]] = dataset[groupLevel[0]];
            } else {
              const globalLabelsPresent = {};
              globalLabelsPresent[groupLevel[0]] = dataset[groupLevel[0]];
              datasets.push({
                label: this.operation + '(' + this.defaultColumn.displayValue + ')',
                globalLabelsPresent: globalLabelsPresent,
                data: [],
                fill: this.chartType === ChartType.LINE ? false : true,
              });
            }
          }
        }
      });

      datasets.forEach((ds) => {
        globalLabels.forEach((label) => {
          if (ds.globalLabelsPresent[label] === undefined) {
            ds.data.push(undefined);
          } else {
            const value = ds.globalLabelsPresent[label];
            ds.data.push(value);
          }
        });
      });

      this.dataChart = {
        labels: globalLabels,
        datasets: datasets,
      };

      const palette = this.themeService.getThemeProperty('colorsPalette');
      this.dataChart.datasets.forEach((ds: any, i: number) => {
        const color = i < palette.length ? palette[i] : this.getRandomColor();
        const _opacity = Math.round(Math.min(Math.max(0.7 || 1, 0), 1) * 255);
        const backGroundColor = color + _opacity.toString(16).toUpperCase();
        if (this.chartType === ChartType.LINE) {
          ds.borderColor = color;
        } else if (this.chartType === ChartType.BAR || this.chartType === ChartType.COLUMN) {
          ds.backgroundColor = backGroundColor;
        } else if (this.chartType === ChartType.RADAR || this.chartType === ChartType.AREA) {
          ds.backgroundColor = backGroundColor;
          ds.borderColor = color;
          ds.pointBackgroundColor = color;
          ds.pointBorderColor = '#fff';
        }
      });
    } else {
      Object.keys(dataset).forEach((labels) => {
        const groupLevel = labels.replace(/\&\|/g, ' - ');
        globalLabels.push(groupLevel);
        data.push(dataset[labels]);
      });

      this.dataChart = {
        labels: globalLabels,
        datasets: [
          {
            data: data,
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      };
      const palette = this.themeService.getThemeProperty('colorsPalette');
      this.dataChart.labels.forEach((label: any, i: number) => {
        const color = i < palette.length ? palette[i] : this.getRandomColor();
        const _opacity = Math.round(Math.min(Math.max(0.7 || 1, 0), 1) * 255);
        const backGroundColor = color + _opacity.toString(16).toUpperCase();
        this.dataChart.datasets.forEach((ds) => {
          ds.backgroundColor.push(backGroundColor);
          ds.hoverBackgroundColor.push(color);
        });
      });
    }
    this.options = {
      tooltips: {
        callbacks: {
          title: (item, data) => {
            const label = data.labels[item.index];
            return label;
          },
          label: (item, data) => {
            const dataSetValue = parseFloat(data.datasets[item.datasetIndex].data[item.index]);
            const total = data.datasets[item.datasetIndex].data.reduce((a, b) => {
              return a + parseFloat(b);
            }, 0);
            const pourcentage = ((dataSetValue / total) * 100).toFixed(2) + '%';
            return dataSetValue.toFixed(2) + (total !== 0 ? ` (${pourcentage})` : '');
          },
        },
      },
    };
    if (this.chartType === ChartType.BAR) {
      this.options.indexAxis = 'y';
    }
    setTimeout(() => this.chart.reinit(), 0);
  }

  private getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  applyFilter(filter: CompiereDataGridRequestJSON) {
    if (this.defaultColumn) {
      this.valueCols = [
        {
          id: this.defaultColumn.id,
          aggFunc: this.operation,
          displayName: this.defaultColumn.displayValue,
          field: this.defaultColumn.id,
        },
      ];
    } else {
      this.valueCols = [];
    }
    this.filter = filter;
    if (this.container) {
      (<BladeUiComponent>this.container).notifyUrlChange();
    }
    const dataStoreRequest: DataStoreRequest = {
      windowId: (<BladeUiComponent>this.container).infoComponent
        ? (<BladeUiComponent>this.container).infoComponent.windowId
        : this.container.windowId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.tabId,
        startRow: 0,
        endRow: 25,
        windowCtx: this.getCurrentContext(),
        validation: this.getTabWhereclause(),
      },
    };
    if (this.filter) {
      dataStoreRequest.compiereRequest.filterModel = this.filter.filterModel;
      dataStoreRequest.compiereRequest.rowGroupCols = this.filter.rowGroupCols;
      dataStoreRequest.compiereRequest.sortModel = this.filter.sortModel;
    }
    dataStoreRequest.compiereRequest.valueCols = this.valueCols;
    this.datas = [];
    this.subscriptions.push(
      zip(this.store.getDataGrid(dataStoreRequest), this.uiCreator.getCompiereTab(this.tabId)).subscribe(
        ([dataWS, tab]) => {
          this.columns = tab.fields
            .filter((item) => {
              if (
                item.field.AD_Reference_ID === 11 ||
                item.field.AD_Reference_ID === 12 ||
                item.field.AD_Reference_ID === 22 ||
                item.field.AD_Reference_ID === 29 ||
                item.field.AD_Reference_ID === 37
              ) {
                return item;
              }
            })
            .map((item) => ({
              displayValue: item.field.Name,
              id: item.field.ColumnName,
              type: item.field.AD_Reference_ID,
            }));
          dataWS.data.forEach((row) => {
            this.datas.push(row);
          });
          this.selectedColumns = this.filter
            ? this.filter.rowGroupCols.map((rgc) => rgc.field.replace(new RegExp('"', 'g'), ''))
            : [];
          this.transformData();
        }
      )
    );
  }
  getCurrentContext() {
    if (this.DOMParentComponent) {
      return (<GridViewUiComponent>this.DOMParentComponent).getCurrentContext();
    } else return null;
  }
  getTabWhereclause() {
    if (this.DOMParentComponent) {
      return (<GridViewUiComponent>this.DOMParentComponent).getTabWhereclause();
    } else return null;
  }
  onChildUpdate(event): void {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}
}
export interface ColumnFilter {
  displayValue: string;
  id: any;
  type: any;
}
