import { DOCUMENT, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PoService } from '@compiere-ws/services/po/po.service';
import PrimeOverlayComponent from '@iupics-components/overrided/prime-overlay/prime-overlay.component';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsDataField } from '@iupics-manager/models/iupics-data';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

import { AppConfig } from '@iupics-config/app.config';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { IupicsJsonField } from '@iupics-manager/models/iupics_json_field';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { Nullable } from 'primeng/ts-helpers';
import InputJsonLineUiComponent from '../input-json-line-ui/input-json-line-ui.component';
import EditTabUiComponent from '../layouts/edit-tab-ui/edit-tab-ui.component';
import ValuePreferencePanelComponent from '../value-preference-panel/value-preference-panel.component';

@Component({
  selector: 'iu-input-json-ui',
  templateUrl: './input-json-ui.component.html',
  styleUrls: ['./input-json-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TooltipModule,
    NgIf,
    NgFor,
    NgClass,
    FormsModule,
    PrimeOverlayComponent,
    forwardRef(() => ValuePreferencePanelComponent),
    TextLimitPipe,
    ButtonModule,
    DynamicContainerDirective,
    NgStyle,
  ],
})
export default class InputJsonUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  hasMultiValues = false;
  fields: IupicsJsonField[] = [];
  focused: Nullable<boolean>;
  isNotAuthorized: Nullable<boolean>;
  @Input() addOnBlur = false;
  @Input() addOnEnter = true;
  @Input() addOnTab = true;
  @Input() addOnSpace = false;
  @Input() editOnBackspace = true;
  @Input() matchPattern: RegExp = /.*/;
  @Input() inputStyle: { [key: string]: string };
  @Input() placeholder: string;
  private globalClickListener: Function;
  onModelChange: Function;
  @Input()
  chips = [];
  @Output() chipsChange = new EventEmitter<any>();
  @Output() onChipAdded = new EventEmitter<any>();
  @Output() onChipRemoved = new EventEmitter<any>();
  @ViewChild('opConflict', { static: true }) opConflict: OverlayPanel;
  @ViewChild('opCreation', { static: true }) opCreation: OverlayPanel;
  @ViewChild('vcrCreation', { read: ViewContainerRef, static: true })
  vcrCreation: ViewContainerRef;
  @ViewChild('jsonFieldsContainer', { read: ElementRef, static: true }) jsonFieldsContainer: ElementRef<HTMLDivElement>;
  @ViewChild('inputtext', { read: ElementRef, static: true })
  inputViewChild: ElementRef<HTMLInputElement>;
  @Input() data: IupicsDataField;
  @Input()
  columnName: string;

  @Input() placeHolder: string;
  fieldKeys: IupicsJsonField[] = [];
  fieldDisplayeds: IupicsJsonField[] = [];
  uniqueProp: boolean = false;
  currentChip;
  isFree = true;
  @Input()
  set fieldValue(value: any) {
    this._fieldValue = value;
    if (value != null && value != undefined && !(value instanceof Object)) {
      let fieldValueJson = this.parseJson(value);
      if (fieldValueJson != null && fieldValueJson != undefined && !Array.isArray(fieldValueJson)) {
        fieldValueJson = [fieldValueJson];
      }
      this.chips = fieldValueJson;
    } else this.chips = [];
  }

  get fieldValue() {
    return this._fieldValue;
  }
  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public cmService: ContextMenuService,
    public uiCreatorService: UICreatorService,
    private config: AppConfig,
    renderer: Renderer2,
    protected po: PoService,
    private translateService: TranslateService,
    protected cacheService: CacheManagerService,
    private messageManager: MessageManagerService,
    @Inject(DOCUMENT) document: Document
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po, cacheService);
  }
  ngOnInit() {
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();
    this.initJsonStructureData();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
  }
  showConflictPanel(ev) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }

  dataChange(value) {
    super.dataChange(value);
  }

  parseJson(value: string) {
    return JSON.parse(value);
  }

  buildPanel() {
    this.vcrCreation.clear();
    this.componentRefs = [];
    if (this.fields.length > 0) {
      this.fields.forEach((f, idx) => {
        this.buildLine(f);
      });
    } else {
      Object.keys(this.currentChip).forEach((k) => {
        this.buildLine({
          componentName: 'InputTextUiComponent',
          description: '',
          name: k,
          nameToShow: k,
          numberType: null,
          seqNo: -1,
          jsonFieldId: -1,
          isKey: false,
          isDisplayed: false,
        });
      });
    }
  }
  initJsonStructureData() {
    if (this?.data?.jsonDefId > 0) {
      const jsonDef = this.store.getJsonDef(this?.data?.jsonDefId);
      this.hasMultiValues = jsonDef.hasMultiValues;
      this.fields = jsonDef.fields;
      this.isFree = this.fields.length == 0;
      this.uniqueProp = this.fields.length == 1;
      this.fieldKeys = this.fields.filter((f) => f.isKey);
      if (this.fieldKeys.length == 0 || this.isFree) {
        this.fields.unshift({
          componentName: 'InputTextUiComponent',
          description: '',
          name: '_ID',
          nameToShow: '_ID',
          numberType: null,
          seqNo: -1,
          jsonFieldId: -1,
          isKey: true,
          isDisplayed: true,
        });
        this.fieldKeys.push({
          componentName: 'InputTextUiComponent',
          description: '',
          name: '_ID',
          nameToShow: '_ID',
          numberType: null,
          seqNo: -1,
          jsonFieldId: -1,
          isKey: true,
          isDisplayed: true,
        });
      }
      this.fieldDisplayeds = this.fields.filter((f) => f.isDisplayed);
      if (this.fieldDisplayeds.length == 0) {
        this.fieldDisplayeds.push(...this.fieldKeys);
      }
    }
  }
  buildLine(jsonField: IupicsJsonField) {
    const componentRef = this.vcrCreation.createComponent(InputJsonLineUiComponent);
    componentRef.instance.dataChangeEmitter.subscribe((value) => this.lineChange(value));
    componentRef.instance.jsonField = jsonField;
    componentRef.instance.value = this.currentChip[jsonField.name];
    componentRef.instance.key = jsonField.name;
    componentRef.instance.nameToShow = jsonField.nameToShow;
    componentRef.instance.isFree = this.isFree;
    this.componentRefs.push(componentRef);
  }
  lineChange(value: any) {
    let oldId = this.currentChip['_ID'];
    Object.keys(value).forEach((k) => {
      if (value[k] === undefined || value[k] === null) {
        delete this.currentChip[k];
      } else {
        this.currentChip[k] = value[k];
      }
    });
    let newId = '';
    this.fieldKeys.forEach((f) => {
      if (
        this.currentChip[f.name] === null ||
        this.currentChip[f.name] === undefined ||
        this.currentChip[f.name]?.length == 0
      ) {
        newId = '';
        return;
      }
      newId += this.currentChip[f.name];
    });
    if (!this.addChip(this.currentChip, oldId, newId)) {
      this.currentChip['_ID'] = oldId;
    }
  }
  addChip(chip: any, oldId: string, newId: string): boolean {
    this.isNotAuthorized = false;
    if (chip) {
      let foundIndex = this.chips.findIndex((t) => t._ID === oldId);
      if (newId != oldId && this.chips.find((t) => t._ID === newId)) {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translateService.instant('generic.error'),
            this.translateService.instant('inputJson.duplicateKey'),
            'error'
          )
        );
        return false;
      }

      if (newId?.length > 0) {
        chip._ID = newId;
        if (foundIndex == -1) {
          this.chips = [...this.chips, chip];
        } else {
          this.chips.splice(foundIndex, 1, chip);
        }
      } else if (foundIndex > -1) {
        this.chips.splice(foundIndex, 1);
      } else return false;
    }
    this.updateModel();
    this.chipsChange.emit(this.chips);
    this.onChipAdded.emit();
    return true;
  }

  removeJsonChip(event, index: number): void {
    if (event) {
      event.stopPropagation();
    }
    if (!this.isReadOnly) {
      this.chips.splice(index, 1);
      this.updateModel();
      this.chipsChange.emit(this.chips);
      this.onChipRemoved.emit();
    }
  }
  removeChipById(id: string): void {
    const idx = this.chips.findIndex((c) => c._ID === id);
    if (idx > -1) {
      this.removeJsonChip(null, idx);
    }
  }
  showCreationPanel(event, chip = {}) {
    if (event) {
      event.stopPropagation();
    }
    if (!this.uniqueProp && !this.isReadOnly) {
      this.currentChip = chip;
      this.buildPanel();
      this.opCreation.show(event);
      setTimeout(() => {
        if (this.componentRefs[0]?.instance?.valueComponentRef?.instance?.inputRef) {
          (<InputJsonLineUiComponent>(
            this.componentRefs[0].instance
          )).valueComponentRef.instance.inputRef.nativeElement.focus();
        }
      }, 200);
    }
  }
  updateModel() {
    let value;
    if (!this.hasMultiValues) {
      value = this.chips[0];
    } else {
      value = this.chips;
    }
    if (value != null && value != undefined) {
      value = JSON.stringify(value);
    }
    this.dataChange(value);
  }

  addLineToPanel(event: Event) {
    if (event?.stopPropagation) {
      event.stopPropagation();
    }
    if (this.isFree) {
      this.buildLine({
        componentName: 'InputTextUiComponent',
        description: '',
        name: '',
        nameToShow: '',
        numberType: null,
        seqNo: -1,
        jsonFieldId: -1,
        isKey: false,
        isDisplayed: false,
      });
      if (this.jsonFieldsContainer) {
        setTimeout(() => {
          this.gotoBottom();
        }, 50);
      }
    }
  }
  private gotoBottom() {
    this.jsonFieldsContainer.nativeElement.scrollTop =
      this.jsonFieldsContainer.nativeElement.scrollHeight - this.jsonFieldsContainer.nativeElement.clientHeight;
  }

  onInputFocus(event: FocusEvent) {
    if (this.globalClickListener === undefined) {
      this.globalClickListener = this.renderer.listen(document, 'click', (e: any) => {
        const path = e.path ? e.path : e.composedPath();
        if (path.indexOf(this.elementRef.nativeElement) < 0) {
          this.globalClickListener();
          this.globalClickListener = undefined;
        }
      });
    }
  }
  onInputBlur(event) {
    this.focused = false;
    if (this.addOnBlur && this.uniqueProp && this.inputViewChild.nativeElement.value) {
      const values: any = {};
      values[this.fields[0].name] = this.inputViewChild.nativeElement.value;
      this.addChip(values, null, this.inputViewChild.nativeElement.value);
      this.inputViewChild.nativeElement.value = '';
    }
  }

  onKeydown(event: KeyboardEvent): void {
    switch (event.key) {
      // Backspace
      case 'Backspace':
        if (this.inputViewChild.nativeElement.value.length === 0 && this.chips && this.chips.length > 0) {
          this.chips = [...this.chips];
          const removedItem = this.chips.pop();
          this.updateModel();
          this.chipsChange.emit(this.chips);
          if (this.editOnBackspace) {
            this.inputViewChild.nativeElement.value = removedItem[this.fields[0].name];
            event.preventDefault();
          }
        }
        break;

      // Enter
      case 'Enter':
        if (this.addOnEnter && this.inputViewChild.nativeElement.value !== '') {
          const values: any = {};
          values[this.fields[0].name] = this.inputViewChild.nativeElement.value;
          if (!this.addChip(values, null, this.inputViewChild.nativeElement.value)) {
            this.isNotAuthorized = true;
            break;
          }
          this.inputViewChild.nativeElement.value = '';
          event.preventDefault();
          this.inputViewChild.nativeElement.blur();
          this.onInputBlur(event);
        }
        break;

      // Tab
      case 'Tab':
        if (this.addOnTab && this.inputViewChild.nativeElement.value !== '') {
          const values: any = {};
          values[this.fields[0].name] = this.inputViewChild.nativeElement.value;
          if (!this.addChip(values, null, this.inputViewChild.nativeElement.value)) {
            this.isNotAuthorized = true;
            break;
          }
          this.inputViewChild.nativeElement.value = '';

          event.preventDefault();
        }
        break;

      // Space
      case ' ':
        if (this.addOnSpace && this.inputViewChild.nativeElement.value !== '') {
          const values: any = {};
          values[this.fields[0].name] = this.inputViewChild.nativeElement.value;
          if (!this.addChip(values, null, this.inputViewChild.nativeElement.value)) {
            this.isNotAuthorized = true;
            break;
          }
          this.inputViewChild.nativeElement.value = '';
          event.preventDefault();
        }
        break;

      default:
        break;
    }
  }
}
