import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  CompiereDataGridRequestJSON,
  DataStore,
  DataStoreKey,
  DataStoreName,
} from '@compiere-ws/models/compiere-data-json';
import { CallInProgressService } from '@compiere-ws/services/call-in-progress/call-in-progress.service';
import { CompiereExportDataService } from '@compiere-ws/services/compiere-export-data/compiere-export-data.service';
import { IAutocomplete, PrintFormatAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { PrintParams, PrintReportManager } from '@iupics-manager/managers/print-report/print-report-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'iu-export-data-ui',
  templateUrl: './export-data-ui.component.html',
  styleUrls: ['./export-data-ui.component.scss'],
  standalone: true,
  imports: [NgIf, ProgressBarModule, AutocompleteUiComponent, ButtonModule, AsyncPipe, TranslateModule],
})
export default class ExportDataUiComponent implements OnInit, OnDestroy {
  @ViewChild('printFormatsAutocomplete') printFormatsAutocomplete: AutocompleteUiComponent;
  @ViewChild('fileTypesAutocomplete') fileTypesAutocomplete: AutocompleteUiComponent;

  @Input()
  ids: any[];
  @Input()
  datagridRequest: CompiereDataGridRequestJSON;
  @Input()
  tableId: number;
  @Input()
  tabId: number;
  @Input()
  dsKey: DataStoreKey;
  @Input()
  whereClause: string;
  @Input()
  sourceModal: ModalUiComponent;

  @Output() cancelExportDataEmitter = new EventEmitter<any>();
  @Output() afterExecute = new EventEmitter<any>();

  style = {};
  subscriptions: any[] = [];
  dataStored: DataStore;
  recordIds: string[] = [];

  printFormat: PrintFormatAutocomplete;
  fileType: IAutocomplete;
  private fileFormats: IAutocomplete[];

  exportDataOptions$: Observable<any>;
  isLoading = false;
  constructor(
    private exportDataService: CompiereExportDataService,
    private messageManager: MessageManagerService,
    private printReportManager: PrintReportManager,
    private connectorService: SecurityManagerService,
    private translate: TranslateService,
    private callInProgressService: CallInProgressService,
    private store: DataStoreService
  ) {}

  ngOnInit() {
    this.exportDataOptions$ = this.exportDataService.getExportTableDataFormats(this.tableId, 3).pipe(
      map((exportDataOptions) => {
        const obj: any = {};
        obj['printFormats'] = {
          items: exportDataOptions.printFormats || [],
          isParam: false,
        };
        this.fileFormats = exportDataOptions.fileFormats;
        obj['fileTypes'] = {
          items: this.fileFormats || [],
          isParam: false,
        };
        obj['recordIds'] = this.ids;
        return obj;
      })
    );
  }

  /**
   * Export des données sélectionnées sous un format choisi
   * @param {Event}event
   */
  public exportData(event: Event, recordIds: any[]) {
    event.stopPropagation();
    const fileType: IAutocomplete = this.fileTypesAutocomplete.fieldValue;
    const printFormat: PrintFormatAutocomplete = this.printFormatsAutocomplete.fieldValue;
    if (fileType != null && printFormat != null) {
      const ids = [];
      const columnsKeys = [];
      let ctx = {};
      if (this.dsKey) {
        const recordid =
          this.dsKey.recordId.split(',').length > 1
            ? this.dsKey.recordId.split(',').length > 1
              ? this.dsKey.recordId.split(',')[1]
              : null
            : null;
        ids.push(parseInt(recordid, 10));

        if (this.datagridRequest) {
          const dsSplit = this.dsKey.recordId.split(',');
          if (dsSplit.length > 1) {
            const [columnName, record_id, _] = dsSplit;

            this.datagridRequest.exportSelections = [{ [columnName]: record_id }];
          }
        } else {
          const record_id = (this.dsKey.recordId as string).split(',');
          if (record_id.length > 1) {
            let obj = {};
            for (let i = 0; i < record_id.length; i++) {
              obj[record_id[i]] = record_id[i + 1];
              i++;
            }
            columnsKeys.push(obj);
          }
        }
        ctx = this.store.getStore(this.dsKey, DataStoreName.CURRENT).data;
      }
      if (recordIds) {
        if (this.datagridRequest) {
          this.datagridRequest.exportSelections = recordIds;
        } else {
          recordIds.forEach((recordId) => {
            const recordIdSplit = recordId.split(',');
            ids.push(recordIdSplit[1]);
          });
        }
        ctx = this.connectorService.getIupicsUserContext();
      }
      if (this.callInProgressService.isSubscriptionDone(this.tabId + '', 'export-data.exportData')) {
        if (this.sourceModal) {
          this.sourceModal.isLoading = true;
        } else {
          this.isLoading = true;
        }
        const printParams: PrintParams = {
          ids: ids,
          sessionId: this.connectorService.getIupicsUserAccount().session_id,
          tabId: this.tabId,
          printFormatId: printFormat ? printFormat.id : -1,
          fileTypeId: fileType.id,
          whereclause: this.whereClause,
          datagridRequest: this.datagridRequest,
          ctx,
        };
        this.callInProgressService.setSubscription(
          this.tabId + '',
          'export-data.exportData',
          this.printReportManager
            .printReportWithChoice(printParams, () => {
              if (this.afterExecute) {
                this.afterExecute.emit();
              }
            })
            .subscribe({
              next: (response) => {
                if (response) {
                  this.messageManager.newMessage(
                    new IupicsMessage(
                      this.translate.instant('exportData.messageTitle'),
                      this.translate.instant('exportData.exportDataStarted'),
                      'success'
                    )
                  );
                } else {
                  this.messageManager.newMessage(
                    new IupicsMessage(
                      this.translate.instant('exportData.messageTitle'),
                      this.translate.instant('exportData.exportDataFail'),
                      'error'
                    )
                  );
                }
                if (this.sourceModal) {
                  this.sourceModal.isLoading = false;
                } else {
                  this.isLoading = false;
                }
                this.callInProgressService.completeSubscription(this.tabId + '', 'export-data.exportData');
              },
              error: (err) => {
                this.messageManager.newMessage(
                  new IupicsMessage(
                    this.translate.instant('exportData.messageTitle'),
                    this.translate.instant('exportData.exportDataFail'),
                    'error'
                  )
                );
                this.callInProgressService.completeSubscription(this.tabId + '', 'export-data.exportData');
                if (this.sourceModal) {
                  this.sourceModal.isLoading = false;
                } else {
                  this.isLoading = false;
                }
              },
            })
        );
      }
    }
  }

  setPrintFormat(printFormat: PrintFormatAutocomplete) {
    this.printFormat = printFormat;
    if (printFormat.fileFormats?.length) {
      this.fileTypesAutocomplete.data.items = printFormat.fileFormats;
    } else {
      this.fileTypesAutocomplete.data.items = this.fileFormats;
    }
    if (!this.fileTypesAutocomplete.data.items.find((ft) => ft.id === this.fileType?.id)) {
      this.fileTypesAutocomplete.fieldValue = null;
      this.fileType = null;
    }
  }

  setFileType(fileType: IAutocomplete) {
    this.fileType = fileType;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
