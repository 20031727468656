import { Injectable } from '@angular/core';
import {
  CompiereDataFieldType,
  CompiereDataGridRequestJSON,
  CompiereDataGridResponseJSON,
  CompiereDataJSON,
  CompiereDataJSON2,
  CompiereDeleteDataJson,
} from '@compiere-ws/models/compiere-data-json';
import { CalloutDataJSON, TreeCompiereJSON } from '@compiere-ws/models/compiere-tree-json';
import { InfoWindowDV } from '@compiere-ws/models/info-window-default-value-json';
import { AppConfig } from '@iupics-config/app.config';
import { Utils } from '@iupics-util/tools/util';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CompiereDataService {
  private urlSave: string;
  private urlDelete: string;
  private urlList: string;
  private urlTree: string;
  private urlChangeLog: string;
  private urlDataGrid: string;
  private urlInfoWindowDV: string;
  private urlNewRecord: string;

  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {
    this.urlNewRecord = this.config.getBackendResource('newRecord');
  }

  saveData(dataToSave: CompiereDataJSON2): Observable<CompiereDataJSON2> {
    this.urlSave = this.config.getBackendResource('saveData');
    return this.http.post<CompiereDataJSON2>(`${this.urlSave}`, dataToSave);
  }

  deleteData(tab_id: number, data_UUID_list: any[]): Observable<CompiereDeleteDataJson[]> {
    this.urlDelete = this.config.getBackendResource('Deletedata');
    const tab_id_string = '/' + tab_id;
    return this.http.request<CompiereDeleteDataJson[]>('delete', `${this.urlDelete}${tab_id_string}`, {
      body: data_UUID_list,
    });
  }

  getDataForAutocomplete(
    fieldType: CompiereDataFieldType,
    entityId: number,
    isSearch: boolean,
    query?: string,
    validation = ''
  ): Observable<CompiereDataJSON> {
    const urlWs = this.config.getBackendResource('dataField');
    let queryLimitOffset = '';
    if (isSearch) {
      queryLimitOffset = '?limit=25&offset=0';
    }
    return this.http.post<CompiereDataJSON>(Utils.formatUrl(`${urlWs}${queryLimitOffset}`), {
      entityId: entityId,
      validation: validation,
      windowType: fieldType,
      query: query,
    });
  }

  getDataForAutocompleteById(
    fieldType: CompiereDataFieldType,
    entityId: number,
    id: any,
    validation?: string
  ): Observable<CompiereDataJSON> {
    const urlWs = this.config.getBackendResource('dataField');
    return this.http.post<CompiereDataJSON>(Utils.formatUrl(`${urlWs}`), {
      entityId: entityId,
      validation: validation,
      windowType: fieldType,
      recordId: id,
    });
  }

  getDataTree(id: number, isTreeId = false): Observable<TreeCompiereJSON> {
    this.urlTree = this.config.getBackendResource('tree');
    return this.http.get<TreeCompiereJSON>(`${this.urlTree}${id}?isTree=${isTreeId}`);
  }

  calloutData(urlWs: string, data: CalloutDataJSON): Observable<any> {
    this.urlList = environment.config.backend.ws.url;
    return this.http.post<any>(`${this.urlList}${urlWs}`, data);
  }

  saveDataTree(data: TreeCompiereJSON): Observable<any> {
    this.urlTree = this.config.getBackendResource('tree');
    return this.http.post<any>(this.urlTree, data);
  }

  getChangeLog(table_id: number, map: any): Observable<any> {
    this.urlChangeLog = this.config.getBackendResource('changeLog');
    const query = '?table_id=' + table_id;
    return this.http.post<any>(this.urlChangeLog + query, map);
  }

  getDataGrid(request: CompiereDataGridRequestJSON, type: string = 'data'): Observable<CompiereDataGridResponseJSON> {
    this.urlDataGrid = this.config.getBackendResource('dataGrid');
    return this.http.post<CompiereDataGridResponseJSON>(this.urlDataGrid, request, {
      headers: { 'x-cypress-datagrid': type },
    });
  }
  getFieldsData(url: string): Observable<any> {
    this.urlList = environment.config.backend.ws.url;
    return this.http.get<any>(`${this.urlList}${url}`);
  }

  getInfoWindowDefaultValues(AD_InfoWindow_ID: number, ctx: any): Observable<InfoWindowDV[]> {
    this.urlInfoWindowDV = this.urlInfoWindowDV ? this.urlInfoWindowDV : this.config.getBackendResource('infoWindowDV');
    return this.http.post<InfoWindowDV[]>(`${this.urlInfoWindowDV}/${AD_InfoWindow_ID}`, ctx);
  }

  getNewRecord(tab_id: number, ctx: any): Observable<{ [key: string]: any }> {
    return this.http.post<{ [key: string]: any }>(`${this.urlNewRecord}/${tab_id}`, ctx);
  }
}
