<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window']"
  [attr.data-cy-specific-window-name]="name">
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="widget-editor">
      <div class="widget-editor-preview">
        <div class="systemButtons" [ngStyle]="{ display: !isModal ? 'flex' : 'none' }">
          <ng-template #vcrWidgetChoice></ng-template>
        </div>
        <ng-template #previewWidget></ng-template>
      </div>
      <div class="widget-editor-settings">
        <p-tabView [(activeIndex)]="activeTabIndex">
          <p-tabPanel header="{{ 'widgetEditor.step1' | translate }}">
            <div class="widget-editor-params">
              <iu-prime-fieldset [toggleable]="true" [collapsed]="false" [legend]="'widgetEditor.step1Fs1' | translate">
                <div class="params">
                  <ng-template iuDynamicContainer></ng-template>
                </div>
              </iu-prime-fieldset>
              <iu-prime-fieldset [toggleable]="true" [collapsed]="false" [legend]="'widgetEditor.step1Fs2' | translate">
                <div class="params">
                  <ng-template #vcrDataSource></ng-template>
                </div>
                <div [ngStyle]="{ display: showUF && searchColumns ? 'flex' : 'none' }" class="params widgetEditorUF">
                  <span class="p-autocomplete">
                    <label [title]="'widgetEditor.filters' | translate">
                      {{ 'widgetEditor.filters' | translate }}</label
                    >
                    <iu-universal-filter-ui
                      *ngIf="searchColumns"
                      [isStandalone]="true"
                      [columns]="searchColumns"
                      [tabId]="-1"
                      [setFilterEmitter]="updateFieldUFEmitter"
                      [widthChipsContainer]="null"
                      [hasScroll]="false"
                      (filterChange)="onFilterChange($event)"
                      [showFavorite]="false"
                      [canFilter]="true"
                      [canGroup]="false"
                      [canSort]="true"></iu-universal-filter-ui
                  ></span>
                </div>
                <div class="params">
                  <ng-template #vcrCols></ng-template>
                </div>
              </iu-prime-fieldset>
            </div>
          </p-tabPanel>
          <p-tabPanel header="{{ 'widgetEditor.step2' | translate }}">
            <div class="widget-editor-params">
              <iu-prime-fieldset [toggleable]="true" [collapsed]="false" [legend]="'widgetEditor.step2Fs1' | translate">
                <div class="params">
                  <ng-template #vcrParams></ng-template>
                </div>
              </iu-prime-fieldset>
            </div>
          </p-tabPanel>
          <p-tabPanel [disabled]="!isAdmin" header="{{ 'widgetEditor.step3' | translate }}">
            <div class="widget-editor-params">
              <iu-prime-fieldset [toggleable]="true" [collapsed]="false" [legend]="'widgetEditor.step3Fs1' | translate">
                <div class="params">
                  <ng-template #vcrAccess></ng-template>
                  <iu-autocomplete-ui
                    #rolesField
                    [label]="'widgetEditor.roleAccess' | translate"
                    [isStandalone]="true"
                    columnName="widgetEditor-roles"
                    (fieldValueModified)="widgetRolesChange($event)"
                    [(fieldValue)]="widgetRoles"
                    [data]="roleNames"
                    cssGrid="p-col-12"
                    cssClass="p-col-12"
                    [multiple]="true"></iu-autocomplete-ui></div
              ></iu-prime-fieldset>
            </div>
          </p-tabPanel>
        </p-tabView>

        <div class="widget-editor-buttons">
          <button
            *ngIf="widget?.id"
            class="p-button-default p-button-alert close-btn"
            pButton
            (click)="deleteWidget($event)">
            {{ 'widgetEditor.deleteWidget' | translate }}
          </button>
          <button class="p-button-default p-button-primary close-btn" pButton (click)="saveWidget($event)">
            {{ (widget?.id ? 'widgetEditor.saveWidget' : 'widgetEditor.addWidget') | translate }}
          </button>
        </div>
      </div>
    </div></p-scrollPanel
  >
</div>
