import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injectable, enableProdMode, importProvidersFrom } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
  bootstrapApplication,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpInterceptorService } from '@compiere-ws/http-interceptor.service';
import { IuErrorHandler } from '@compiere-ws/iu-error-handler.service';
import { AppConfig } from '@iupics-config/app.config';
import { KeybindConfigService } from '@iupics-config/keybind.config.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LicenseManager } from 'ag-grid-enterprise';
import { environment } from 'environments/environment';
import 'hammerjs';
import * as Hammer from 'hammerjs';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MessageService } from 'primeng/api';
import { AppComponent } from './app/app.component';
import routes from './app/app.route';

function initializeKeycloak(config: AppConfig, keycloak: KeycloakService) {
  return () =>
    new Promise(async (resolve, reject) => {
      await config.loadEnvVariable();
      const result = await keycloak.init({
        config: environment.config.backend.auth,
        initOptions: {
          onLoad: 'login-required',
        },
      });
      resolve(result);
    });
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pinch: {
      enable: false,
    },
    rotate: {
      enable: false,
    },
    press: {
      time: 500,
    },
  };
  options = <any>{
    behavior: {
      userSelect: 'auto',
    },
  };
}

export function initializeKeybinding(keybindConfig: KeybindConfigService) {
  return () => keybindConfig.load();
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      // Angular
      BrowserModule,
      HammerModule,
      KeycloakAngularModule,
      // Projects Modules
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : []
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes),
    AppConfig,
    KeybindConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [AppConfig, KeycloakService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeybinding,
      deps: [KeybindConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: IuErrorHandler,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    MessageService,
  ],
}).catch((err) => console.log(err));

LicenseManager.setLicenseKey(
  `Using_this_AG_Grid_Enterprise_key_( AG-041745 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( AUDAXIS S.A. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Audaxis )_only_for_( 4 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Audaxis )_need_to_be_licensed___( Audaxis )_has_been_granted_a_Deployment_License_Add-on_for_( 3 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 27 November 2024 )____[v2]_MTczMjY2NTYwMDAwMA==2edabad85bd3159c4a9895d498ea1171`
);
