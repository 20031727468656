<div class="tags-input" (click)="$event.stopPropagation()">
  <div *ngFor="let tag of tags; let i = index" class="tag">
    {{ tag.name + ' = ' + tag.value }}
    <span class="remove-tag" (click)="removeTag($event, i)">x</span>
  </div>
  <iu-autocomplete-ui
    #autocomplete
    [cssClass]="'p-col-12 p-md-6 p-lg-4'"
    label=""
    [data]="suggestions"
    [(fieldValue)]="tagInput"
    (fieldValueModified)="addTag($any($event))"
    placeholder="{{ 'tagsEditor.addTag' | translate }}"
    [isStandalone]="true"></iu-autocomplete-ui>
</div>
