import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  CompiereDataGridFilterType,
  CompiereDataGridType,
  DataStore,
  DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import { Email, EmailModel, EmailResponse } from '@compiere-ws/models/compiere-mail-json';
import { CompiereTab } from '@compiere-ws/models/window-json';
import { EmailService } from '@compiere-ws/services/compiere-email/email.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { NotificationManagerService } from '@iupics-manager/managers/notification-manager/notification-manager.service';
import { PrintReportManager } from '@iupics-manager/managers/print-report/print-report-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import Quill from 'quill';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import AutocompleteUiComponent from '../../../standard/fields/autocomplete-ui/autocomplete-ui.component';
import InputChipsUiComponent from '../../../standard/fields/input-chips-ui/input-chips-ui.component';
import InputFileUiComponent from '../../../standard/fields/input-file-ui/input-file-ui.component';
import InputTextUiComponent from '../../../standard/fields/input-text-ui/input-text-ui.component';

@Component({
  selector: 'iu-email-editor-ui',
  templateUrl: './email-editor-ui.component.html',
  styleUrls: ['./email-editor-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    InputChipsUiComponent,
    NgIf,
    InputTextUiComponent,
    NgStyle,
    FormsModule,
    NgClass,
    InputFileUiComponent,
    AutocompleteUiComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export default class EmailEditorUiComponent implements OnInit, AfterViewInit, OnDestroy {
  /* ViewChild */
  @ViewChild('quillHeader', { read: ElementRef, static: true })
  header: ElementRef;
  @ViewChild('quillEditor', { read: ElementRef, static: true })
  editor: ElementRef;

  /* Input */
  @Input()
  style: any;
  @Input()
  parentEditView: EditViewUiComponent;
  @Input()
  emailModels: EmailModel[];

  /* Output */
  @Output()
  cancelEmailEmitter = new EventEmitter<any>();

  /* Observables */
  templates$: Observable<any>;
  subscriptions: Subscription[] = [];

  /* Variables utilisées pour Quill */
  quill: Quill;

  /* Variables nécessaires pour la construction de l'email */
  to: string[] = [];
  cc: string[] = [];
  cci: string[] = [];
  emailBody = '';
  subject = '';
  uploadedFiles: any[] = [];

  /* Variables nécessaire pour l'initialisation d'un modèle d'email */
  dataStored: DataStore;
  selectedTemplate: EmailModel;
  defaultEmailModel = {
    id: 0,
    displayValue: '',
    model: null,
  };
  defaultEmailModelID: number;

  /* Boolean utilisés pour l'affichage */
  displayMobileMenu = false;
  isMobile = false;
  displayCC = false;
  displayCCI = false;
  isLoaderActive = false;

  /* regex mail */
  // regexMail: RegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; // RFC 5322 Official Standard
  regexMail: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private connectorService: SecurityManagerService,
    private store: DataStoreService,
    private emailService: EmailService,
    private printReportManager: PrintReportManager,
    private messageManager: MessageManagerService,
    private translateService: TranslateService,
    private notificationService: NotificationManagerService
  ) {}

  ngOnInit() {
    this.isMobile = document.documentElement.clientWidth <= 720;
    if (!this.style) {
      this.style = { height: '240px', overflow: 'auto' };
    }
    const dsKey = this.parentEditView.currentDataStoreKey;
    if (dsKey) {
      const request: DataStoreRequest = {
        windowId: dsKey.windowId,
        record_id: dsKey.recordId,
        parent_constraint: dsKey.parentId,
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: dsKey.tabId,
          startRow: 0,
          endRow: 1,
          windowCtx: this.parentEditView.getCurrentContext(),
          validation: this.parentEditView.getTabWhereclause(),
        },
      };
      this.subscriptions.push(
        this.store.getWindowSingleData(request).subscribe((data) => {
          this.dataStored = data;
          if (this.dataStored.data['AD_User_ID'] != null && this.dataStored.data['AD_User_ID'] != undefined) {
            const EmailTo_request: DataStoreRequest = {
              windowId: null,
              parent_constraint: null,
              compiereRequest: {
                startRow: 0,
                tableName: 'AD_User',
                filterModel: {
                  AD_User_ID: {
                    filterType: CompiereDataGridFilterType.SET,
                    values: [this.dataStored.data['AD_User_ID'].id],
                    operators: [OperatorFilterType.EQUALS],
                  },
                },
              },
            };

            this.store.getDataGrid(EmailTo_request).subscribe((res) => {
              if (
                res &&
                res.data &&
                res.data.length > 0 &&
                res.data[0]['EMAIL'] != null &&
                res.data[0]['EMAIL'] != undefined
              ) {
                this.to.push(res.data[0]['EMAIL']);
              }
            });
          }
        })
      );
      this.subscriptions.push(
        this.printReportManager.getCompiereTab(this.dataStored.key.tabId).subscribe((compiereTab: CompiereTab) => {
          this.defaultEmailModelID = compiereTab.tab.R_MailText_ID;
          this.templates$.subscribe((res) => {
            res.items.forEach((emailModel) => {
              if (emailModel.id === this.defaultEmailModelID) {
                this.defaultEmailModel = emailModel;
                this.selectTemplate(emailModel.model);
              }
            });
          });
        })
      );
    }
    this.handleEmailModels();
  }

  ngAfterViewInit() {
    let Font = Quill.import('formats/font');
    Font.whitelist = ['serif', 'monospace', 'calibri', 'times-new-roman', 'arial'];
    Quill.register(Font, true);
    this.quill = new Quill(this.editor.nativeElement, {
      modules: {
        toolbar: this.header.nativeElement,
      },
      theme: 'snow',
    });
  }

  /**
   * Récupère les modèles et les formattent correctement afin d'être utilisés
   */
  private handleEmailModels() {
    this.templates$ = this.emailService.getEmailModels().pipe(
      map((emailModels) => ({
        items: emailModels.map((emailModel) => ({
          id: emailModel.R_MailText_ID,
          displayValue: emailModel.Name,
          model: emailModel,
        })),
        isParam: false,
      }))
    );
  }

  /**
   * Envoie l'email
   * @param {Event}event
   */
  public sendEmail(event: Event) {
    event.stopPropagation();
    this.emailBody = this.editor.nativeElement.children[0].innerHTML;

    if (this.to.length > 0) {
      const email: Email = {
        body: this.emailBody,
        subject: this.subject,
        to: this.to,
        cc: this.cc,
        cci: this.cci,
        files: this.uploadedFiles.map((file) => file.src),
      };

      this.subscriptions.push(
        this.emailService.sendEmail(email).subscribe({
          next: (response: EmailResponse) => {
            if (response.send === 'OK' && response.Errors === 0) {
              this.cancelEmailEmitter.emit();
              this.messageManager.newMessage(
                new IupicsMessage(
                  this.translateService.instant('email.messageTitle'),
                  this.translateService.instant('email.sendEmailSuccess'),
                  'success'
                )
              );
            } else {
              this.messageManager.newMessage(
                new IupicsMessage(
                  this.translateService.instant('email.messageTitle'),
                  this.translateService.instant('email.sendEmailError'),
                  'error'
                )
              );
            }
          },
          error: (err) => {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('email.messageTitle'),
                this.translateService.instant('email.sendEmailError'),
                'error',
                err
              )
            );
          },
        })
      );
    }
  }
  /**
   * Sélectionne le template et parse les variables présentes dedans pour ensuite l'afficher correctement
   * @param {EmailAutocomplete}input
   */
  public selectTemplate(inputModel: EmailModel) {
    this.selectedTemplate = inputModel;

    const textToParse = [
      '',
      this.selectedTemplate.MailText || '',
      '<br>',
      this.selectedTemplate.MailText2 || '',
      '<br>',
      this.selectedTemplate.MailText3 || '',
      '<br>',
    ]
      .join('')
      .split('\n')
      .join('<br>');

    this.subject = LogicEvaluator.replaceVariables(
      this.selectedTemplate.MailHeader,
      this.connectorService.getIupicsUserContext(),
      this.dataStored.data
    );

    const html = LogicEvaluator.replaceVariables(
      textToParse,
      this.connectorService.getIupicsUserContext(),
      this.dataStored.data,
      true
    );

    const delta = this.quill.clipboard.convert(html);
    this.quill.setContents(delta);

    this.uploadedFiles = [];

    /*
     * Si le rapport doit être automatiquement attaché au mail alors on le génère et on l'attache automatiquement au mail
     */
    if (this.selectedTemplate.XX_IsAutoAttachedReport === true) {
      this.isLoaderActive = true;
      this.subscriptions.push(
        this.printReportManager
          .printReport(this.dataStored.key, this.connectorService.getIupicsUserAccount().session_id, {
            ...EditViewUtils.mergeCurrentDataDeepCopy(cloneDeep(this.dataStored.data), {
              ...this.connectorService.getIupicsUserContext(),
            }),
            fromEmail: true,
          })
          .subscribe({
            next: (channelId) => {
              const notifSub$ = this.notificationService.syncWithNotification(channelId).subscribe((notification) => {
                if (notification.fileLinks) {
                  notification.fileLinks.forEach((file) => {
                    const src = file.path.replace(/\/download\?url=/, '');
                    this.uploadedFiles.push({
                      src: src,
                      name: file.fileName,
                      id: src + new Date().getTime(),
                      docId: src,
                      extension: file.extension,
                    });
                  });
                } else {
                  this.messageManager.newMessage(
                    new IupicsMessage(
                      this.translateService.instant('email.messageTitle'),
                      this.translateService.instant('email.cannotAttach'),
                      'error'
                    )
                  );
                }
                this.isLoaderActive = false;
                notifSub$.unsubscribe();
              });
            },
            error: (err) => {
              console.error(err);
              this.isLoaderActive = false;
              this.messageManager.newMessage(
                new IupicsMessage(
                  this.translateService.instant('email.messageTitle'),
                  this.translateService.instant('email.cannotAttach'),
                  'error',
                  err
                )
              );
            },
          })
      );
    }
  }

  /**
   * Met le boolean a true ou false selon la largeur de l'écran
   * @param {Event}event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    event.stopPropagation();
    this.isMobile = document.documentElement.clientWidth <= 480;
    if (!this.isMobile) {
      this.displayMobileMenu = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
