import { NgFor } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { TagItem } from '@compiere-ws/models/tag-json';
import { TagService } from '@compiere-ws/services/tag/tag-service';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import AutocompleteUiComponent from '../fields/autocomplete-ui/autocomplete-ui.component';

@Component({
  selector: 'iu-tags-editor-ui',
  templateUrl: './tags-editor-ui.component.html',
  styleUrls: ['./tags-editor-ui.component.scss'],
  standalone: true,
  imports: [forwardRef(() => AutocompleteUiComponent), TranslateModule, NgFor],
  encapsulation: ViewEncapsulation.None,
})
export class TagsEditorUiComponent implements OnInit, OnDestroy {
  sub: Subscription;
  @ViewChild('autocomplete', { static: true })
  autoComplete: AutocompleteUiComponent;
  tagInput = '';
  @Input()
  tags: TagItem[] = [];
  @Output() tagsChange = new EventEmitter<TagItem[]>();
  @Output() onTagAdded = new EventEmitter<any>();
  @Output() onTagRemoved = new EventEmitter<any>();
  suggestions = { items: [] };

  constructor(private tagService: TagService) {}

  ngOnInit(): void {
    if (this.tags == null) {
      this.tags = [];
    }
    this.sub = this.tagService.getTags().subscribe({
      next: (s) => {
        s.forEach((t) =>
          t.values.forEach((v) => {
            const value = t.name + ' = ' + v;
            this.suggestions.items.push({ id: value, displayValue: value, name: t.name, value: v });
          })
        );
      },
    });
  }

  addTag(tag: TagItem): void {
    if (tag) {
      const foundTag = this.tags.find((t) => t.name === tag.name);
      if (!foundTag) {
        this.tags = [...this.tags, tag];
      } else {
        foundTag.value = tag.value;
      }
      this.autoComplete.fieldValue = null;
    }
    this.tagsChange.emit(this.tags);
    this.onTagAdded.emit();
  }

  removeTag(event, index: number): void {
    if (event) {
      event.stopPropagation();
    }
    this.tags.splice(index, 1);
    this.tagsChange.emit(this.tags);
    this.onTagRemoved.emit();
  }

  removeLastTagOnBackspace(event): void {
    event.stopPropagation();
    if (!this.tagInput) {
      this.removeTag(event, this.tags.length - 1);
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
