import { NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataStore, DataStoreKey } from '@compiere-ws/models/compiere-data-json';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { EditViewTab } from '@iupics-components/models/edit-view-tab';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import GridUiComponent from '@iupics-components/standard/layouts/grid-ui/grid-ui.component';
import MenuBarDetailUiComponent from '@iupics-components/standard/menu/menu-bar-detail-ui/menu-bar-detail-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { WindowFactoryUtils } from '@iupics-manager/managers/ui-creator/window-factory/window-factory-utils';
import { AbstractDynamicView } from '@iupics-manager/models/abstract-dynamic-view';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { environment } from 'environments/environment';
import { DynamicContainerDirective } from '../../../../iupics-util/directives/dynamic-container.directive';
import BannerComponent from '../../../message/banner/banner.component';
import BladeUiComponent from '../blade-ui/blade-ui.component';
import EditViewUiComponent from '../edit-view-ui/edit-view-ui.component';
@Component({
  selector: 'iu-additional-info-ui',
  templateUrl: './additional-info-ui.component.html',
  styleUrls: ['./additional-info-ui.component.scss'],
  standalone: true,
  imports: [NgIf, BannerComponent, DynamicContainerDirective],
})
export default class AdditionalInfoUiComponent extends AbstractDynamicView implements OnInit, AfterViewInit, OnDestroy {
  submitted: boolean;
  editTabs: EditTabUiComponent[] = [];
  noData = false;
  scrollPanelId: string;
  widthExpanded: string;
  mustRefresh = false;
  hasConflicts = false;
  wantToMergeConflicts = false;
  localData: DataStore;
  remoteData: DataStore;
  oldDataStoreKey: DataStoreKey;
  displayJoinFilesPanel = false;
  isZoomEditView = false;

  @ViewChild(MenuBarDetailUiComponent, { static: true })
  menuBarDetailComponent: MenuBarDetailUiComponent;

  @ViewChild(GridUiComponent, { static: true })
  editTabComponent: GridUiComponent;
  @ViewChild('article', { static: true })
  articleElement: ElementRef;

  height: number;

  currentDataStoreKey: DataStoreKey;

  currentTabActive: EditViewTab;
  constructor(
    private socketService: SocketService,
    private config: AppConfig
  ) {
    super();
    this.height = this.config.getConstant('AdditionalInfoUiComponent#height');
  }

  ngOnInit() {
    this.scrollPanelId = 'scrollPanelEditView' + this.tabId;
  }

  getCurrentDataStoreKey() {
    return this.currentDataStoreKey;
  }

  ngAfterViewInit() {
    this.children.forEach((child) =>
      WindowFactoryUtils.addContainerComponent({
        parent: this,
        item: child,
        isCssOnComponent: child.container ? child.isCssOnComponent : false,
      })
    );
    (<EditViewUiComponent>this.DOMParentComponent).retrieveData(
      (<EditViewUiComponent>this.DOMParentComponent).currentDataStoreKey
    );
    (<BladeUiComponent>this.container).scrollToScrollingPosition(
      (<EditViewUiComponent>this.DOMParentComponent).editViewElement.nativeElement
    );
    const event: IupicsEvent = (<EditViewUiComponent>this.DOMParentComponent).lastEvent;
    if (event) {
      event.item = { ...event.item, DOMParentComponent: null };
      event.item.DOMParentComponent = this;
      (<EditViewUiComponent>this.DOMParentComponent).onSiblingUpdate(event);
    }
    if (this.DOMParentComponent === this.container) {
      (<BladeUiComponent>this.DOMParentComponent).scrollToRight();
    }
  }

  addTabToEditView(editTabUi: EditTabUiComponent) {
    editTabUi.data.isAdditionalInfo = true;
    this.editTabs.push(editTabUi);
  }

  onRemoveComponent(event: IupicsEvent) {
    if (event.type === IupicsTypeEvent.removeChildEvent) {
      event.type = IupicsTypeEvent.removeBreadcrumbItem;
      this.DOMParentComponent.onChildUpdate(event);
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.expandEvent,
        item: undefined,
      });
    }
  }

  onChildUpdate(event): void {
    if (event.type === IupicsTypeEvent.showAdditionalInfo) {
      this.currentDataStoreKey = event.item.dataStoreKey;
    }
  }

  onSiblingUpdate(event: IupicsEvent) {}

  isTabDisplay(displayLogic: string, dataMap: Map<string, any>): boolean {
    if (displayLogic) {
      return LogicEvaluator.evaluateLogic(dataMap, displayLogic);
    }
    return true;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (environment.constant.mergeLevel !== 0) {
      this.socketService.closeDataChannel(this.currentDataStoreKey.windowId + '-' + this.currentDataStoreKey.recordId);
    }
  }
}
