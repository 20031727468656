<div #container [ngClass]="containerClass()" [class]="className" [ngStyle]="style" [attr.data-pc-name]="'speeddial'" [attr.data-pc-section]="'root'">
  <button
      pRipple
      pButton
      class="p-button-icon-only"
      [style]="buttonStyle"
      [icon]="buttonIconClass"
      [ngClass]="buttonClass()"
      [disabled]="disabled"
      [attr.aria-expanded]="visible"
      [attr.aria-haspopup]="true"
      [attr.aria-controls]="id + '_list'"
      [attr.aria-label]="ariaLabel"
      [attr.aria-labelledby]="ariaLabelledBy"
      (click)="onButtonClick($event)"
      (keydown)="onTogglerKeydown($event)"
      [attr.data-pc-name]="'button'"
  >
      <PlusIcon *ngIf="!showIcon && !buttonTemplate" />
      <ng-container *ngIf="buttonTemplate">
          <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
      </ng-container>
  </button>
  <ul
      #list
      class="p-speeddial-list"
      role="menu"
      [id]="id + '_list'"
      (focus)="onFocus($event)"
      (focusout)="onBlur($event)"
      (keydown)="onKeyDown($event)"
      [attr.aria-activedescendant]="focused ? focusedOptionId : undefined"
      [tabindex]="-1"
      [attr.data-pc-section]="'menu'"
  >
      <li
          *ngFor="let item of model; let i = index"
          [ngStyle]="getItemStyle(i)"
          class="p-speeddial-item"
          pTooltip
          [tooltipOptions]="item.tooltipOptions"
          [ngClass]="{ 'p-hidden': item.visible === false, 'p-focus': focusedOptionId == id + '_' + i }"
          [id]="id + '_' + i"
          [attr.aria-controls]="id + '_item'"
          role="menuitem"
          [attr.data-pc-section]="'menuitem'"
      >
          <a
              *ngIf="_visible && isClickableRouterLink(item); else elseBlock"
              pRipple
              [routerLink]="item.routerLink"
              [queryParams]="item.queryParams"
              class="p-speeddial-action"
              [ngClass]="{ 'p-disabled': item.disabled }"
              role="menuitem"
              [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
              (click)="onItemClick($event, item)"
              (keydown.enter)="onItemClick($event, item, i)"
              [attr.target]="item.target"
              [attr.tabindex]="item.disabled || readonly || !visible ? null : item.tabindex ? item.tabindex : '0'"
              [fragment]="item.fragment"
              [queryParamsHandling]="item.queryParamsHandling"
              [preserveFragment]="item.preserveFragment"
              [skipLocationChange]="item.skipLocationChange"
              [replaceUrl]="item.replaceUrl"
              [state]="item.state"
              [attr.aria-label]="item.label"
              [attr.data-pc-section]="'action'"
          >
              <span class="p-speeddial-action-icon" *ngIf="item.icon" [ngClass]="item.icon"></span>
          </a>
          <ng-template #elseBlock>
              <a
                  *ngIf="_visible"
                  [attr.href]="item.url || null"
                  class="p-speeddial-action"
                  role="menuitem"
                  pRipple
                  (click)="onItemClick($event, item)"
                  [ngClass]="{ 'p-disabled': item.disabled }"
                  (keydown.enter)="onItemClick($event, item, i)"
                  [attr.target]="item.target"
                  [attr.data-pc-section]="'action'"
                  [attr.aria-label]="item.label"
                  [attr.tabindex]="item.disabled || (i !== activeIndex && readonly) || !visible ? null : item.tabindex ? item.tabindex : '0'"
              >
                  <span class="p-speeddial-action-icon" *ngIf="item.icon" [ngClass]="item.icon"></span>
              </a>
          </ng-template>
      </li>
  </ul>
</div>
<div *ngIf="mask && visible" [ngClass]="{ 'p-speeddial-mask': true, 'p-speeddial-mask-visible': visible }" [class]="maskClassName" [ngStyle]="maskStyle"></div>