<iu-grid-tab-infinity-scroll-ui
  *ngIf="data"
  class="gridWidgetContent"
  [tabId]="tabId"
  [isTabTopLevel]="isTabTopLevel"
  [data]="data"
  [filterModel]="filterModel"
  [sortModel]="sortModel"
  [gridTabFilter]="gridTabFilter"
  [container]="container"
  [DOMParentComponent]="DOMParentComponent"
  [overridedTableHeight]="true"
  [tableHeight]="'100%'"
  (multiSelectEmitter)="onRowSelectedOnGridTab($event)"
  [gridPaginator]="gridPaginator"
  [gridState]="gridState"
  (columnStateChanged)="onColumnStateChanged($event)"></iu-grid-tab-infinity-scroll-ui>
