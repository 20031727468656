import { NgClass, NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, inject } from '@angular/core';
import { AvatarService } from '@iupics-components/standard/user/avatar-ui/avatar-service/avatar.service';
import { AvatarInfos } from '@iupics-manager/models/user-account';
import { Subscription } from 'rxjs';

@Component({
  selector: 'iu-avatar-ui',
  templateUrl: './avatar-ui.component.html',
  styleUrls: ['./avatar-ui.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf],
})
export default class AvatarUiComponent implements AfterViewInit, OnDestroy {
  #elRef = inject(ElementRef<HTMLElement>);
  #avatar = inject(AvatarService);

  subs: Subscription[] = [];
  @Input() avatarInfos: AvatarInfos;
  displayDefaultAvatar = false;

  ngAfterViewInit(): void {
    this.getAvatarData();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  onError(event) {
    this.displayDefaultAvatar = true;
  }

  getAvatarData() {
    this.avatarInfos = this.#avatar.getAvatarInfos(this.avatarInfos);
    this.#elRef.nativeElement.style.setProperty('--_avatar-ui-bg-color', this.avatarInfos.backgroundColor);
    this.#elRef.nativeElement.style.setProperty('--_avatar-ui-ft-color', this.avatarInfos.foregroundColor);
  }
}
