import { Component, HostListener, Injector, OnDestroy } from '@angular/core';

import { NgIf } from '@angular/common';
import { createCustomElement } from '@angular/elements';
import { RouterOutlet } from '@angular/router';
import TagContentUiComponent from '@iupics-components/standard/fields/input-mention-ui/components/tag-content-ui/tag-content-ui.component';
import TagListItemUiComponent from '@iupics-components/standard/fields/input-mention-ui/components/tag-list-item-ui/tag-list-item-ui.component';
import { IupicsCookieService } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { loadComponentFactory } from '@iupics-util/tools/component-cache-loader';
import { TranslateService } from '@ngx-translate/core';
import { DomHandler } from 'primeng/dom';

@Component({
  selector: 'iu-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgIf, RouterOutlet],
})
export class AppComponent implements OnDestroy {
  private subscriptions = [];
  globalEnv = Global;
  successMessage: IupicsMessage;
  clearMessageDelay: NodeJS.Timeout;

  constructor(
    private cookieService: IupicsCookieService,
    private translateService: TranslateService,
    private injector: Injector
  ) {
    this.createCustomElements();
    loadComponentFactory();

    if (document.querySelector('.indexLoaderMsg')) {
      (document.querySelector('.indexLoaderMsg') as HTMLElement).style.display = 'none';
    }
    if (document.querySelector('.indexLoader')) {
      (document.querySelector('.indexLoader') as HTMLElement).style.display = 'none';
    }
    this.overridePrimengDomHandler();
  }

  private createCustomElements() {
    [
      {
        name: 'iu-tag-list-item-element',
        component: TagListItemUiComponent,
      },
      {
        name: 'iu-tag-content-element',
        component: TagContentUiComponent,
      },
    ].forEach(({ name, component }) => {
      const element = createCustomElement(component, { injector: this.injector });
      if (!customElements.get(name)) {
        // Register the custom element with the browser
        customElements.define(name, element);
      }
    });
  }

  private overridePrimengDomHandler() {
    DomHandler.addClass = (element: any, className: string): void => {
      if (className.trim()) {
        if (element.classList) {
          element.classList.add(className);
        } else {
          element.className += ' ' + className;
        }
      }
    };

    DomHandler.addMultipleClasses = (element: any, className: string): void => {
      if (element.classList) {
        const styles: string[] = className
          .trim()
          .split(' ')
          .filter((cn) => cn.trim());
        for (let i = 0; i < styles.length; i++) {
          element.classList.add(styles[i]);
        }
      } else {
        const styles: string[] = className
          .trim()
          .split(' ')
          .filter((cn) => cn.trim());
        for (let i = 0; i < styles.length; i++) {
          element.className += ' ' + styles[i];
        }
      }
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  @HostListener('document:keydown.shift.alt.delete', ['$event'])
  handleKeyPressed(event: KeyboardEvent) {
    if (event.shiftKey && event.altKey && event.key === 'Delete') {
      this.cookieService.clearLsCookies();
      this.successMessage = new IupicsMessage(
        null,
        this.translateService.instant('homepage.clearLocalStorageCookies'),
        'success'
      );
      this.clearMessageDelay = setTimeout(() => {
        this.closeSuccessMessage();
      }, 1500);
      event.preventDefault();
    }
  }

  closeSuccessMessage() {
    if (this.clearMessageDelay) {
      clearTimeout(this.clearMessageDelay);
    }

    location.reload(); // charger du server et non pas du cache
    this.successMessage = null;
  }
}
