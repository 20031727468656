<div
  [ngClass]="{
    'vendor-profile-container': true,
    'with-profile-picture': true
  }">
  <div class="picture" iuLoading [dataToWatch]="data?.profilePicture" (loadingEnded)="changeLoadingState()">
    <img
      *ngIf="data?.profilePicture"
      [src]="data?.profilePicture ?? defaultProfilePicture | docServerUrl | async"
      alt="profile picture" />
    <i *ngIf="!loadingState && !data?.profilePicture" class="icon-user-circle" aria-hidden="true"></i>
  </div>
  <div class="name" iuLoading [dataToWatch]="data?.fullName">
    <h1 *ngIf="data?.fullName">{{ data?.fullName }}</h1>
    <i
      *ngIf="data?.fullName"
      class="edit-button-icon icon-edit-pencil"
      aria-hidden="true"
      role="button"
      (click)="editBtnClick($event)"></i>
  </div>
  <div class="type" iuLoading [dataToWatch]="data?.type">
    <h2 *ngIf="data?.type">{{ data?.type }}</h2>
    <i *ngIf="data?.isForbidden" class="icon-blocked" aria-hidden="true"></i>
  </div>
  <div class="rating" iuLoading [dataToWatch]="data?.rating">
    <iu-input-stars-ui [data]="data?.rating"></iu-input-stars-ui>
  </div>
  <div class="top" iuLoading [dataToWatch]="data?.top">
    <span *ngIf="data?.top > 0" class="top-label">{{ 'vendorProfile.topLabel' | translate }}</span>
    <span *ngIf="data?.top > 0" class="top-value">{{ data?.top }}</span>
  </div>
</div>
