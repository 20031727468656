<div class="fav-grid-buttons">
  <div class="fav-grid-buttons-container">
    <span [ngClass]="['fav-grid-button', activeView === 'CVT' ? 'active' : '']" (click)="switchTo('CVT')">{{
      'favGrid.count' | translate
    }}</span>
    <span [ngClass]="['fav-grid-button', activeView === 'LVT' ? 'active' : '']" (click)="switchTo('LVT')">{{
      'favGrid.list' | translate
    }}</span>
  </div>
</div>
<div #fgiListElt class="fav-grid-items" [ngStyle]="{ display: activeView === 'LVT' ? 'flex' : 'none' }">
  <ng-container *ngIf="activeView === 'LVT'">
    <cdk-virtual-scroll-viewport itemSize="40" [minBufferPx]="240" [maxBufferPx]="240" class="fav-grid-virtualScroll">
      <div
        *cdkVirtualFor="let item of items$ | async; trackBy: trackByFn"
        class="fav-grid-item"
        (click)="openTab($event, item)">
        {{ item.displayedName }}
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-container>
</div>
<ng-container *ngIf="activeView === 'CVT'">
  <div
    #widgetCircularProgress
    class="circular-progress"
    [life]="3000"
    pTooltip="{{ widget.alertLevel || widget.normalLevel ? percent + ' %' : (result | number: numberFormat) }}"
    aria-hidden="true">
    <div class="progress-value">
      <span
        (click)="openTab($event)"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'favGrid.openWindow' | translate }}"
        data-cy="openInWindow"
        [style.color]="bgColor"
        >{{ result | number: numberFormat : lang }}
        <i [attr.disabled]="disabled" *ngIf="this.currency" class="progress-value-currency" [style.color]="bgColor">{{
          this.currency
        }}</i></span
      >
    </div>
  </div>
</ng-container>
