import { CompiereProcessInfo } from '@compiere-ws/models/compiere-process-json';
import { TreeNodeCompiere } from '@compiere-ws/models/compiere-tree-json';
import { CompiereWorkFlow } from '@compiere-ws/models/compiere-workflow-json';
import { SpecificWindowDetailItemFieldCompiereWS } from '@compiere-ws/models/specific-window-json';
import { IAutocomplete, PrintFormatAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { ColDef, EditableCallback, ITooltipParams, ValueFormatterFunc, ValueGetterFunc } from 'ag-grid-community';

export interface IupicsData {
  AD_ColumnSortOrder_ID?: number;
  AD_ColumnSortYesNo_ID?: number;
  AD_Window_ID?: number;
  AD_Form_ID?: number;
  AD_FormDetail_ID?: number;
  AD_Tab_ID?: number;
  AD_Table_ID?: number;
  calloutLabel?: string;
  callouts?: IupicsCallout;
  colspan?: number;
  columnId?: number;
  columnName?: string;
  columnsTableHeader?: IupicsTableDataHeader[];
  columnsDisplayLogicMap?: Map<string, string>;
  columnsDetails?: Map<string, SpecificWindowDetailItemFieldCompiereWS>;
  componentName?: string;
  ctxArea?: {};
  data?: any;
  defaultValue?: string;
  fromRange?: boolean;
  defaultValue2?: string;
  description?: string;
  help?: string;
  detailZoom?: IupicsZoomInfo;
  details?: IupicsDetails;
  displayLogic?: string;
  fieldId?: number;
  fileOpening?: boolean;
  fileOpeningFilters?: string;
  fieldType?: string;
  hasTree?: boolean;
  hasUniversalFilter?: boolean;
  hasExtendedLogic?: boolean;
  cssClass?: string;
  hasCheckbox?: boolean;
  id?: number;
  isAccount?: boolean;
  isAlwaysUpdatable?: boolean;
  isUpdateable?: boolean;
  isAdditionalInfo?: boolean;
  isCollapsable?: boolean;
  isCollapsedDefault?: boolean;
  isCopy?: boolean;
  isDocumentTab?: boolean;
  isDeleteable?: boolean;
  isFilterSearch?: boolean;
  isLaunchSearchGrid?: boolean;
  isOptional?: boolean;
  isMandatory?: boolean;
  isReadOnly?: boolean;
  isInsertRecord?: boolean;
  isDisplayed?: boolean;
  isParam?: boolean;
  isRange?: boolean;
  isSameLine?: boolean;
  tableName?: string;
  isSearch?: boolean;
  isSelected?: boolean;
  isSingleRow?: boolean;
  isTabTopLevel?: boolean; // pour les keybind des grid view
  isView?: boolean;
  isDeletableLogic?: string;
  docFilters?: string;
  taggedColumns?: string;
  items?: any[];
  label?: string;
  poLabel?: string;
  marginTop?: number;
  nbCol?: number;
  nbrAddressSlot?: number;
  numberType?: NumberType;
  positionEditTab?: number;
  query?: {};
  readOnlyLogic?: string;
  recordID?: any;
  searchColumns?: any[];
  tabId?: number;
  tabLevel?: number;
  tabType?: string;
  urlCallouts?: string;
  urlList?: string;
  urlSearch?: string;
  urlTree?: string;
  validationCode?: string;
  autoCompletionFromClause?: string;
  workflow?: CompiereWorkFlow;
  columnsDisplayAD?: IupicsColumnKanban[];
  kanbanImageColumn?: string;
  columnsReduceGrid?: string[];
  shouldSelectFirst?: boolean;
  hiddenColumns?: string[];
  editorColumns?: string[];
  frameworkComponents?: any;
  isFitResize?: boolean;
  needTime?: boolean;
  isLabelDisplay?: boolean;
  mandatoryLogic?: string;
  fieldTypeForm?: boolean;
  min?;
  max?;
  stepperData?: StepperData;
  vFormat?: string;
}
export interface StepperData {
  docStatusField: IupicsField;
  docActionField: IupicsField;
}

export enum NumberType {
  AMOUNT = 'amount',
  INTEGER = 'integer',
  FLOAT = 'float',
  QUANTITY = 'quantity',
}

export interface IupicsZoomInfo {
  tableName?: string;
  parentId?: string;
  columnKey?: string;
  dataUUID?: string;
  record_id?: number;
  children?: any;
  windowId?: number;
  isInCurrentWindow?: boolean;
}

export interface IupicsZoomTarget {
  buttonId?: number;
  record_id?: string;
}

export interface IupicsCallout {
  callouts: any[];
  tabId: number;
  windowId: number;
  fieldId: number;
}

export interface InfoComponent {
  name: string;
  windowId: number;
  domWinId?: string;
}

export interface IupicsWindow {
  windowId: number;
  children: IupicsTab[];
}

export interface IupicsTree {
  label: string;
  data: TreeNodeCompiere;
  expandedIcon?: string;
  collapsedIcon?: string;
  children?: IupicsTree[];
  icon?: string;
}

export interface IupicsTab {
  dataStructure?: any;
  tabId: number;
  tabLevel: number;
  isSingleRow: boolean;
  editView: IupicsTabView;
  gridView: IupicsTabView;
  adFormID?: number;
}
export interface IupicsTabUI {
  key: string;
  tabId: number;
  tabs: IupicsTab[];
  tabsJSON: string;
  editTabs: IupicsTabView[];
  additionalTabs: IupicsTabView[];
  firstTab: IupicsTab;
  bladeSize: number;
  bladeSizeAdditional: number;
  dataStructures: Map<number, Map<string, Object>>;
}

export function instanceOfIupicsTab(object: any): object is IupicsTab {
  return 'editView' in object;
}

export interface IupicsTabView {
  children: IupicsField[];
  component: string;
  data: IupicsDataTab;
  tabId?: number;
  gridPaginator?: boolean;
  gridTabFilter?: string[];
  gridTabValidator?: string;
}

export class IupicsDataTab implements IupicsData {
  AD_ColumnSortOrder_ID?: number;
  AD_ColumnSortYesNo_ID?: number;
  AD_Window_ID?: number;
  AD_Form_ID?: number;
  AD_Tab_ID?: number;
  AD_Table_ID?: number;
  colspan?: number;
  columnsTableHeader?: IupicsTableDataHeader[];
  ctxArea?: any;
  displayLogic?: string;
  hasTree?: boolean;
  isCollapsable?: boolean;
  isCollapsedDefault?: boolean;
  isDeleteable?: boolean;
  tableName?: string;
  isDocumentTab?: boolean;
  isSingleRow?: boolean;
  items?: any[];
  label?: string;
  nbCol?: number;
  positionEditTab?: number;
  recordID?: any;
  tabLevel?: number;
  maxNbLines?: number;
  urlTree?: string;
  workflow?: CompiereWorkFlow;
  isReadOnly?: boolean;
  isInsertRecord?: boolean;
  readOnlyLogic?: string;
  isDeletableLogic?: string | null;
  docFilters?: string | null;
  taggedColumns?: string | null;
  isView?: boolean;
  columnsDisplayAD?: IupicsColumnKanban[];
  kanbanImageColumn?: string;
  columnsReduceGrid?: string[];
  stepperData?: StepperData;
  tabType?: string;
}

export interface IupicsColumnKanban {
  dataAlignLeft: string[];
  dataAlignright: string[];
}

export interface IupicsField {
  children?: IupicsField[];
  columnId?: number;
  component: string;
  cssClass?: string;
  data: IupicsDataField;
  displayLength?: number;
  fieldGroup?: string;
  fieldId?: number;
  formDetailId?: number;
  formDetailItemId?: number;
  formId?: number;
  isCallout?: boolean;
  isRange?: boolean;
  isReadOnly?: boolean;
  processId: number;
  referenceId: number;
  seqNo: number;
  tableId?: number;
}

export interface IupicsDataField extends IupicsData {
  formId?: number;
  fieldGroupExpanded?: boolean;
  columnId?: number;
  fieldId?: number;
  jsonDefId?: number;
  calloutLabel?: string;
  callouts?: IupicsCallout;
  colspan?: number;
  columnName?: string;
  componentName?: string;
  defaultValue?: string;
  fromRange?: boolean;
  defaultValue2?: string;
  description?: string;
  help?: string;
  detailZoom?: IupicsZoomInfo;
  displayLogic?: string;
  isDisplayed?: boolean;
  fieldType?: string;
  hasExtendedLogic?: boolean;
  cssClass?: string;
  isAccount?: boolean;
  isUpdateable?: boolean;
  isAlwaysUpdatable?: boolean;
  isCopy?: boolean;
  isFilterSearch?: boolean;
  isLaunchSearchGrid?: boolean;
  isOptional?: boolean;
  isMandatory?: boolean;
  isParam?: boolean;
  isRange?: boolean;
  isSameLine?: boolean;
  isSearch?: boolean;
  isSelected?: boolean;
  items?: any[];
  label?: string;
  poLabel?: string;
  marginTop?: number;
  nbCol?: number;
  nbrAddressSlot?: number;
  numberType?: NumberType;
  readOnlyLogic?: string;
  searchColumns?: any[];
  tabId?: number;
  tableName?: string;
  urlCallouts?: string;
  urlList?: string;
  urlSearch?: string;
  validationCode?: string;
  details?: IupicsDetails;
  displayKanban?: string;
  needTime?: boolean;
  autoCompletionFromClause?: string;
  mandatoryLogic?: string;
  isFieldOnly?: boolean;
  isButtonReadOnly?: boolean;
  vFormat?: string;
}

export interface IupicsDetails {
  keyColumn: string;
  tableName: string;
  autoCompletionFromClause?: string;
  sqlDisplayData?: string;
  entityID?: number;
  entityType?: number;
}
export interface DetailZoom {
  tableName: string;
  columnKey: string;
}

export interface IupicsProcess {
  isReport: boolean;
  hasJasper: boolean;
  hasFormat: boolean;
  fields: IupicsField[];
  process: CompiereProcessInfo;
  fileFormats: IAutocomplete[];
  printFormats: PrintFormatAutocomplete[];
}
export interface IupicsTableDataHeader extends ColDef {
  headerName?: string;
  field?: string;
  rowGroup?: boolean;
  enableRowGroup?: boolean;
  enablePivot?: boolean;
  enableValue?: boolean;
  filter?: string | boolean;
  filterParams?: any;
  cellRenderer?: any;
  valueFormatter?: string | ValueFormatterFunc<any>;
  hide?: boolean;
  editable?: boolean | EditableCallback<any>;
  enableCellChangeFlash?: boolean;
  cellRendererParams?: any;
  cellEditor?: any;
  cellEditorParams?: any;
  width?: number;
  resizable?: boolean;
  checkboxSelection?: any;
  headerComponentParams?: any;
  headerComponent?: any;
  suppressMenu?: boolean;
  sortable?: boolean;
  valueGetter?: string | ValueGetterFunc<any>;
  maxWidth?: number;
  tooltipValueGetter?: (params: ITooltipParams) => string;
  AD_Reference_ID?: number;
  cssColors?: Map<string, string>;
}

export interface IupicsSpecificWindow {
  AD_FormDetail_ID: number;
  AD_Form_ID: number;
  AD_InfoWindow_ID?: number;
  angularClass: string;
  description: string;
  entityType: string;
  help: string;
  name: string;
  title?: string;
  items?: any[];
}

export enum CellType {
  Numeric = 'Numeric',
  List = 'List',
}
