import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import InputStarsUiComponent from '@iupics-components/standard/fields/input-stars-ui/input-stars-ui.component';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { TranslateModule } from '@ngx-translate/core';
import { DocServerUrlPipe } from '../../../../../../iupics-util/pipes/doc-server-url/doc-server-url.pipe';
import { LoadingDirective } from '../../../../../directives/loading.directive';

@Component({
  selector: 'iu-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss'],
  standalone: true,
  imports: [NgClass, LoadingDirective, NgIf, InputStarsUiComponent, AsyncPipe, TranslateModule, DocServerUrlPipe],
})
export default class CustomerProfileComponent implements OnInit {
  @Input() data: any;

  @Output() editButtonEmitter = new EventEmitter<MouseEvent>();

  loadingState: boolean;

  constructor(protected uiCreator: UICreatorService) {}

  ngOnInit(): void {}

  editBtnClick(event: MouseEvent) {
    this.editButtonEmitter.emit(event);
  }

  changeLoadingState() {
    this.loadingState = !this.loadingState;
  }
}
