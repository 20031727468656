import { RowSelectionType } from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import { OverridedCSS } from '@iupics-manager/models/overrided-css';
import { ButtonLevel } from './custom-types';

export interface CustomDesignItem {
  /**
   * Le nom de l'attribut vcr à utiliser
   */
  vcr: string;
  /**
   * La classe css souhaitée
   */
  cssClass?: string;
  /**
   * Le type d'item
   */
  type: CustomDesignItemType;
  /**
   * Si c'est field, spécifiez le nom de la colonne
   */
  columnName?: string;
  /**
   * Si c'est grid, spécifiez le nom de la table
   */
  tableName?: string;
  /**
   * Si on a une query à l'initialisation
   */
  shouldCreateQuery?: boolean;
  /**
   * Si on doit selectionner la premiere ligne
   */
  shouldSelectFirst?: boolean;
  /**
   * Si on doit selectionner la premiere ligne
   */
  rowSelection?: RowSelectionType;
  /**
   * Si on définir des columns non-affichées
   */
  hiddenColumns?: string[];
  /**
   * Si on définir des columns éditables
   */
  editorColumns?: string[];

  /**
   * affiche le label
   */
  isLabelDisplay?: boolean;

  /**
   * écrase le label du container
   */
  label?: string;

  /**
   * css style pour le label des autocomplete
   */
  overridedCSS?: OverridedCSS;
  /**
   * n'utilise pas le datastore
   */
  isStandalone?: boolean;

  /**
   * définit le framework des colonnes-custo
   */
  frameworkComponents?: any;
  /**
   * définit si le tableau doit être resize
   */
  isFitResize?: boolean;
  /**
   * définit un nouveau comportement pour un event
   * il faut définir une nouvelle fonction dans la classe angular custo qui prend en parametre un parametre any
   * lorsqu'on veut utiliser executeProcess il faut lui passer item.processId
   * En clé, il faut mettre un nom d'event js :("click","keyPress","keyUp", etc...)
   * exemple :  events: {
          click:this.cliqueSurBouton.bind(this)
        }
   */
  events?: any;

  /**
   * définit si une ligne doit être sélectionnée par la case à cocher ou non
   */
  suppressRowClickSelection?: boolean;

  /**
   * - *InputFileUiComponent*: Peut accepter plusieurs fichiers
   * - *AutocompleteUiComponent*: ***TODO***
   */
  multiple?: boolean;

  /**
   *  - *ButtonUiComponent*: Type de bouton
   */
  btnType?: ButtonLevel;
}

export enum CustomDesignItemType {
  GRID = 'grid',
  FIELD = 'field',
}
