import { ApplicationRef, ComponentRef, EmbeddedViewRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import DraggableWindowComponent from '@iupics-components/standard/draggable-window/draggable-window.component';

@Injectable({
  providedIn: 'root',
})
export class DynamicComponentManagerService {
  constructor(private appRef: ApplicationRef) {}
  createComponent(vcr: ViewContainerRef, componentInfo: { type: Type<any>; options: any }): ComponentRef<any> {
    const contentRef = vcr.createComponent(componentInfo.type);
    Object.keys(componentInfo.options).forEach((k) => {
      contentRef.instance[k] = componentInfo.options[k];
    });
    return contentRef;
  }
  clearAndCreateComponent(vcr: ViewContainerRef, componentInfo: { type: Type<any>; options: any }): ComponentRef<any> {
    vcr.clear();
    return this.createComponent(vcr, componentInfo);
  }
  moveIntoDraggableWindow(
    options: {
      iconClass?: string;
      title?: string;
      top?: string;
      right?: string;
      left?: string;
      width?: string;
      height?: string;
    },
    component: ComponentRef<any>
  ): void {
    const contentContainer = this.createDraggableWindow(options);
    contentContainer.instance.contentComponent = component;
    if (contentContainer && component) {
      contentContainer.instance.contentVcr.insert(component.hostView);
    }

    // Add the component to the DOM
  }
  createDraggableWindow(options: {
    iconClass?: string;
    title?: string;
    top?: string;
    right?: string;
    left?: string;
    width?: string;
    height?: string;
  }): ComponentRef<DraggableWindowComponent> {
    const rootViewContainerRef = this.appRef.components[0].injector.get(ViewContainerRef);

    // Create window
    const componentRef = rootViewContainerRef.createComponent(DraggableWindowComponent);

    // Get the DOM element of the window
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // Set window properties
    if (options.iconClass) componentRef.instance.iconClass = options.iconClass;
    if (options.title) componentRef.instance.title = options.title;
    if (options.right) componentRef.instance.right = options.right;
    if (options.top) componentRef.instance.top = options.top;
    if (options.left) componentRef.instance.left = options.left;
    if (options.width) componentRef.instance.width = options.width;
    if (options.height) componentRef.instance.height = options.height;
    document.body.appendChild(domElem);
    // Set window content
    return componentRef;
  }
}
