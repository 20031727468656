import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ProcessPingInfo } from '@compiere-ws/models/process-ping-info';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DynamicContainerDirective } from '../../../../iupics-util/directives/dynamic-container.directive';
import ButtonUiComponent from '../../../standard/fields/button-ui/button-ui.component';
import GridTabInfinityScrollUiComponent from '../../../standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import ModalUiComponent from '../../../standard/layouts/modal-ui/modal-ui.component';
import UniversalFilterUiComponent from '../../../standard/menu/universal-filter-ui/universal-filter-ui.component';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-create-from-open-invoice',
  templateUrl: './create-from-open-invoice.component.html',
  styleUrls: ['./create-from-open-invoice.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ButtonUiComponent,
    ModalUiComponent,
    UniversalFilterUiComponent,
    GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export default class CreateFromOpenInvoiceComponent extends SpecificWindowUiComponent implements OnInit {
  private nbLinesField: AbstractDataContainer;
  private totalField: AbstractDataContainer;
  private soldeField: AbstractDataContainer;
  constructor(
    windowFactory: WindowFactoryService,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
  }
  ngOnInit() {
    this.customDesignArray.push(
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_Client_ID',
        cssClass: 'p-col-6 p-md-4 p-lg-2',
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_ID',
        cssClass: 'p-col-6 p-md-4 p-lg-2',
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_Payment_ID',
        cssClass: 'p-col-6 p-md-4 p-lg-2',
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_DocType_ID',
        cssClass: 'p-col-6 p-md-4 p-lg-2',
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Total',
        cssClass: 'p-col-6 p-md-4 p-lg-2',
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'COUNT',
        cssClass: 'p-col-6 p-md-4 p-lg-2',
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'SOLDE',
        cssClass: 'p-col-6 p-md-4 p-lg-2',
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.GRID,
        tableName: 'Create From Open Invoice Table',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Processing',
        cssClass: 'p-col-6 p-md-4 p-lg-2',
      }
    );
    super.ngOnInit();
    this.showSpecificWindow();
  }
  notifyFromDatacontainerInit(dataContainer: AbstractDataContainer) {
    if (dataContainer.data.columnName === 'COUNT') {
      this.nbLinesField = dataContainer;
    }
    if (dataContainer.data.columnName === 'Total') {
      this.totalField = dataContainer;
    }
    if (dataContainer.data.columnName === 'SOLDE') {
      this.soldeField = dataContainer;
    }
  }
  notifyFromRowSelected(rowSelected: any) {
    this.refreshFields();
  }
  refreshFields() {
    let total = 0;
    let nbLines = 0;
    let solde = 0;
    const lineSelecteds = this.getGridSelection('Create From Open Invoice Table');
    if (lineSelecteds && lineSelecteds.length > 0) {
      solde = this.getFormStoreData('PayAmt');
      lineSelecteds.forEach((rowData) => {
        if (rowData['Open'] !== undefined && rowData['Open'] !== null) {
          total += rowData['Open'];
          solde -= rowData['Open'];
        }
        nbLines++;
      });
    }
    this.totalField.updateStore(Number(total.toFixed(2)));
    this.nbLinesField.updateStore(nbLines);
    this.soldeField.updateStore(Number(solde.toFixed(2)));
  }
  onEndProcess(ping: ProcessPingInfo) {
    this.clearGridSelection('Create From Open Invoice Table');
    super.onEndProcess(ping);
    this.totalField.updateStore(0);
    this.nbLinesField.updateStore(0);
    this.soldeField.updateStore(0);
  }
}
